<div class="modal-container">
  <div class="text-right mb-4">
    <a><img (click)="close()" class="pointer" src="assets/icons/clear-icon.svg" /></a>
  </div>
  <div class="h-100 d-flex flex-column justify-content-start">
    <div class="story-header">{{ 'SHARED.COMPONENTS.IDLE.TITLE' | translate }}</div>
    <p class="story-body break-word pb-4" [innerHTML]="'SHARED.COMPONENTS.IDLE.INFO' | translate | translateCut: 0"></p>
    <span class="story-body break-word pb-4">
      <a class="link-color pointer" (click)="close()" [innerHTML]="'SHARED.COMPONENTS.IDLE.INFO' | translate | translateCut: 1"></a>
      <span [innerHTML]="'SHARED.COMPONENTS.IDLE.INFO' | translate | translateCut: 2"></span>
      <a class="link-color pointer" (click)="contactUs()">{{ 'SHARED.COMPONENTS.IDLE.INFO' | translate | translateCut: 3 }}</a>
      <span [innerHTML]="'SHARED.COMPONENTS.IDLE.INFO' | translate | translateCut: 4"></span>
    </span>
  </div>
</div>
