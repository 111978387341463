import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, map, of, startWith } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-entity-multi-select',
  templateUrl: './search-entity-multi-select.component.html',
  styleUrls: ['./search-entity-multi-select.component.scss'],
})
export class SearchEntityMultiSelectComponent implements OnInit {
  @Input() list: any[] = [];
  @Input() set addToListItem(value: any) {
    if (value) {
      // add to main list
      this.list.push(value);
      this.filteredList = of(this.list);

      // remove from the selected items
      const index = this.selectedItems.indexOf(value);
      this.selectedItems.splice(index, 1);
    }
  }
  @Output() onSelectedItemChanged = new EventEmitter<any>();

  searchCtrl = new FormControl();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredList: Observable<any[]>;
  selectedItems: any[] = [];

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor() {
    this.filteredList = this.searchCtrl.valueChanges.pipe(
      startWith(null),
      map((item: any | null) => (item ? this._filter(item) : this.list.slice()))
    );
  }

  ngOnInit(): void {}

  remove(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.list.push(item);
      this.filteredList = of(this.list);
      this.selectedItems.splice(index, 1);
      this.onSelectedItemChanged.emit(this.selectedItems);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.searchInput.nativeElement.value = '';
    this.list = this.list.filter((item) => item.uuid !== event.option.value.uuid);
    this.searchCtrl.setValue(null);
    this.onSelectedItemChanged.emit(this.selectedItems);
  }

  private _filter(value: any): string[] {
    const filterValue = typeof value === 'string' ? value?.toLowerCase() : value?.name?.toLowerCase();
    return this.list.filter((item) => item?.name?.toLowerCase().indexOf(filterValue) === 0);
  }
}
