import { NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ExternalLinks } from '../models/ExternalLinks';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private _showAppSwitcher = false;

  constructor(private oauthService: OAuthService, private userService: UserService) {
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      if (!this.oauthService.hasValidAccessToken()) {
        this.logout();
      }
    });

    this.oauthService.events.pipe(first(({ type }) => type.includes('session_terminated'))).subscribe(() => this.logout());
    this.oauthService.setupAutomaticSilentRefresh();
  }

  init(): Observable<boolean> {
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      mergeMap((success) => {
        const authenticated = success && this.oauthService.hasValidAccessToken();
        return of(authenticated);
      }),
      mergeMap((authenticated) => {
        if (!authenticated) {
          const params = new URLSearchParams(window.location.search);
          const newParams = new URLSearchParams([...params].map(([key, value]) => [key.toLowerCase(), value]));
          const isRedirectUrl = newParams.get('isredirecturl');
          if (isRedirectUrl) {
            this.login();
          } else {
            this.trySilentRefresh();
          }
        }
        return of(true);
      }),
      mergeMap(() => this.userService.getUserInfo())
    );
  }

  login(): void {
    this.oauthService.initLoginFlow();
  }

  trySilentRefresh(): Observable<boolean | OAuthEvent> {
    return from(this.oauthService.silentRefresh()).pipe(
      tap(() => of(true)),
      catchError((error) => {
        const errorResponsesRequiringUserInteraction = ['interaction_required', 'login_required', 'account_selection_required', 'consent_required'];

        if (error && error.reason && errorResponsesRequiringUserInteraction.indexOf(error.reason.error) >= 0) {
          // 3. ASK FOR LOGIN:
          // At this point we know for sure that we have to ask the
          // user to log in, so we redirect them to the IdServer to
          // enter credentials.
          //
          // ALWAYS force a user to login.
          this.login();
          this.isAuthenticatedSubject$.next(true);
          return of(true);
        }

        return of(true);
      })
    );
  }

  logout(): void {
    this.oauthService.logOut({ client_id: this.oauthService.clientId });
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIsLoggedIn(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  get accessToken() {
    return this.oauthService.getAccessToken();
  }
  get isLoggedIn(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }
  get decodedAccessToken() {
    return this.accessToken ? JSON.parse(atob(this.accessToken.split('.')[1])) : '';
  }
  get externalApplicationLinks() {
    const externalLinks: ExternalLinks[] = [];
    const tokenLinks = this.decodedAccessToken.links;
    if (tokenLinks) {
      for (let index = 0; index < tokenLinks.length; index++) {
        const link = tokenLinks[index];
        const parsedLinkData = JSON.parse(link);
        const origin = environment.url;

        Object.keys(parsedLinkData).forEach((key) => {
          const splitKey = key.split('_');
          const isGSUrl = splitKey[1].indexOf('gs') > -1;
          const appTranslationKey = isGSUrl ? 'GENERAL.EXTERNAL_LINKS.GIVING_SPACE' : 'GENERAL.EXTERNAL_LINKS.PORTAL';
          const exists = externalLinks.find((el) => el.tenantName === `GENERAL.EXTERNAL_LINKS.${splitKey[0].toUpperCase()}`);
          const url = isGSUrl ? parsedLinkData[key] + '?isRedirectUrl=true' : parsedLinkData[key];

          if (exists) {
            const existingApplication = exists.applications.find((a) => a.name === appTranslationKey);
            if (!existingApplication) {
              exists.applications.push({
                name: appTranslationKey,
                url,
                selected: url.indexOf(origin) > -1,
              });
            }

            if (!this._showAppSwitcher) {
              this._showAppSwitcher = exists.applications.length > 1;
            }
          } else {
            externalLinks.push({
              tenantName: `GENERAL.EXTERNAL_LINKS.${splitKey[0].toUpperCase()}`,
              applications: [
                {
                  name: appTranslationKey,
                  url,
                  selected: url.indexOf(origin) > -1,
                },
              ],
            });
          }
        });
      }
    }
    return externalLinks;
  }
  get showAppSwitcher() {
    return this._showAppSwitcher;
  }
}
