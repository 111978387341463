import { CustomAddress } from '../CustomAddress';
import { DocumentMedia, LanguageResponseFields } from '../FormControls';
import { Media } from '../FormControls';
import { CatalogEntityBadge, EntityDocument, EntityImage, EntityMainInfo, NavigationData, UUIDName } from '../GenericObject';
import { AdminOrganizationProjectCard, ProjectPartner } from '../project/Project';
import { Language } from '../global-settings/Language';
import { AdminOrganisationCardDto, AdminOrganisationDto, OrganisationProfileFieldsDto, OrganisationSetupDto } from './OrganisationDto';
import Utils from '../../helpers/Utils';
import { AdminOrganizationProjectCardDto, CatalogProjectCardDto } from '../project/ProjectDto';
import Urls from '../../constants/Urls';
import { EntityProfile } from '../EntityProfile';
import { Champion } from '../../../internal-app/details/champion/models/Champion';
import { EntityChampionDto } from '../Details';
import { ENTITY_TYPE, EntityStatus } from '../../constants/CompanyOrFirm';

export class AdminOrganisationCard extends EntityMainInfo {
  coverImageUrl: string;
  entityRouteLink: NavigationData;
  previewLink: NavigationData;
  previewToolTip: string;
  deleteToolTip: string;
  entityType: ENTITY_TYPE;

  constructor(orgData: AdminOrganisationCardDto) {
    super(orgData.uuid, orgData.name, orgData.entityStatus, orgData.changeStatusReason);
    this.coverImageUrl = orgData.entityImageUrl;
    this.entityRouteLink = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.ORGANIZATIONS}/` + this.uuid);
    this.previewLink = new NavigationData(`/${Urls.APP}/${Urls.DETAILS}/${Urls.ORGANIZATION}/${this.uuid}`);
    this.previewToolTip = 'ADMIN.ORGANIZATION_LIST.PREVIEW_ORGANISATION';
    this.deleteToolTip = 'ADMIN.ORGANIZATION_LIST.DELETE_ORGANISATION';
    this.entityType = ENTITY_TYPE.ORGANISATION;
    this.setSelectedLanguage(orgData.language);
  }
}

export class Organisation extends EntityMainInfo {
  address: CustomAddress;
  boardMembers: OrganizationTeamMember[];
  financialStatements: FinancialStatement[];
  categories: UUIDName[];
  cause: UUIDName;
  champions: Champion[];
  executiveSummary: string;
  executiveTeams: OrganizationTeamMember[];
  givingLink: string;
  history: string;
  isPrivate: boolean;
  mandatoryProfile: UUIDName;
  translationsData: LanguageResponseFields;
  missionStatement: string;
  numberOfEmployees: number;
  numberOfVolunteers: number;
  documents: EntityDocument[];
  images: EntityImage[];
  influencedCountries: UUIDName[];
  leader: OrganizationTeamMember;
  logo: Media;
  teamMediaVideoDto: Media;
  teamMediaVideoThumbnailDto: Media;
  teamMediaPictureDto: Media;
  values: string;
  video: Media;
  website: string;
  partnerOrganisations: ProjectPartner[];
  programAndActivities: string;
  socialMediaLinkedin: string;
  socialMediaTwitter: string;
  socialMediaFacebook: string;
  socialMediaInstagram: string;
  targetPopulation: string;
  visionStatement: string;
  yearFounded: number;

  constructor(orgData: OrganisationSetupDto) {
    super(orgData.organisationUuid, orgData.organisationName, orgData.organisationStatus);
    this.address = orgData.address;
    this.boardMembers = orgData.boardMembers;
    this.financialStatements = orgData.budgetOrganisations;
    this.categories = orgData.categories;
    this.cause = orgData.cause;
    this.champions = orgData.champions.map((champion) => new Champion(champion));
    this.executiveSummary = orgData.executiveSummary;
    this.executiveTeams = orgData.executiveTeams;
    this.givingLink = orgData.givingLink;
    this.history = orgData.history;
    this.isPrivate = orgData.isPrivate;
    this.mandatoryProfile = orgData.mandatoryProfile;
    this.translationsData = orgData.languages;
    this.missionStatement = orgData.missionStatement;
    this.numberOfEmployees = orgData.numberOfEmployees;
    this.numberOfVolunteers = orgData.numberOfVolunteers;
    this.documents = orgData.organisationDocuments;
    this.images = orgData.organisationImages;
    this.influencedCountries = orgData.organisationInfluencedCountries;
    this.leader = orgData.organisationLeader;
    this.logo = orgData.organisationLogo;
    this.teamMediaVideoDto = orgData.organisationTeamMediaVideoDto;
    this.teamMediaVideoThumbnailDto = orgData.organisationTeamMediaVideoThumbnailDto;
    this.teamMediaPictureDto = orgData.organisationTeamMediaPictureDto;
    this.values = orgData.organisationValues;
    this.video = orgData.organisationVideo;
    this.website = orgData.organisationWebsite;
    this.partnerOrganisations = orgData.partnerOrganisations;
    this.programAndActivities = orgData.programAndActivities;
    this.socialMediaLinkedin = orgData.socialMediaLinkedin;
    this.socialMediaTwitter = orgData.socialMediaTwitter;
    this.socialMediaFacebook = orgData.socialMediaFacebook;
    this.socialMediaInstagram = orgData.socialMediaInstagram;
    this.targetPopulation = orgData.targetPopulation;
    this.visionStatement = orgData.visionStatement;
    this.yearFounded = orgData.yearFounded;
  }
}

export class OrganisationProfileFields {
  address: EntityProfile;
  boardMembers: EntityProfile;
  financialStatements: EntityProfile;
  categories: EntityProfile;
  cause: EntityProfile;
  executiveSummary: EntityProfile;
  executiveTeams: EntityProfile;
  givingLink: EntityProfile;
  history: EntityProfile;
  isPrivate: EntityProfile;
  missionStatement: EntityProfile;
  numberOfEmployees: EntityProfile;
  numberOfVolunteers: EntityProfile;
  documents: EntityProfile;
  images: EntityProfile;
  influencedCountries: EntityProfile;
  leader: EntityProfile;
  logo: EntityProfile;
  name: EntityProfile;
  teamMediaVideoDto: EntityProfile;
  teamMediaVideoThumbnailDto: EntityProfile;
  teamMediaPictureDto: EntityProfile;
  values: EntityProfile;
  video: EntityProfile;
  website: EntityProfile;
  partnerOrganisations: EntityProfile;
  programAndActivities: EntityProfile;
  socialMediaLinkedin: EntityProfile;
  socialMediaTwitter: EntityProfile;
  socialMediaFacebook: EntityProfile;
  socialMediaInstagram: EntityProfile;
  targetPopulation: EntityProfile;
  visionStatement: EntityProfile;
  yearFounded: EntityProfile;

  constructor(profileFieldsData: OrganisationProfileFieldsDto) {
    this.address = profileFieldsData.address;
    this.boardMembers = profileFieldsData.boardMembers;
    this.financialStatements = profileFieldsData.budgetOrganisations;
    this.categories = profileFieldsData.categories;
    this.cause = profileFieldsData.cause;
    this.executiveSummary = profileFieldsData.executiveSummary;
    this.executiveTeams = profileFieldsData.executiveTeams;
    this.givingLink = profileFieldsData.givingLink;
    this.history = profileFieldsData.history;
    this.isPrivate = profileFieldsData.isPrivate;
    this.missionStatement = profileFieldsData.missionStatement;
    this.numberOfEmployees = profileFieldsData.numberOfEmployees;
    this.numberOfVolunteers = profileFieldsData.numberOfVolunteers;
    this.documents = profileFieldsData.organisationDocuments;
    this.images = profileFieldsData.organisationImages;
    this.influencedCountries = profileFieldsData.organisationInfluencedCountries;
    this.leader = profileFieldsData.organisationLeader;
    this.logo = profileFieldsData.organisationLogo;
    this.name = profileFieldsData.organisationName;
    this.teamMediaVideoDto = profileFieldsData.organisationTeamMediaVideoDto;
    this.teamMediaVideoThumbnailDto = profileFieldsData.organisationTeamMediaVideoThumbnailDto;
    this.teamMediaPictureDto = profileFieldsData.organisationTeamMediaPictureDto;
    this.values = profileFieldsData.organisationValues;
    this.video = profileFieldsData.organisationVideo;
    this.website = profileFieldsData.organisationWebsite;
    this.partnerOrganisations = profileFieldsData.partnerOrganisations;
    this.programAndActivities = profileFieldsData.programAndActivities;
    this.socialMediaLinkedin = profileFieldsData.socialMediaLinkedin;
    this.socialMediaTwitter = profileFieldsData.socialMediaTwitter;
    this.socialMediaFacebook = profileFieldsData.socialMediaFacebook;
    this.socialMediaInstagram = profileFieldsData.socialMediaInstagram;
    this.targetPopulation = profileFieldsData.targetPopulation;
    this.visionStatement = profileFieldsData.visionStatement;
    this.yearFounded = profileFieldsData.yearFounded;
  }
}

export interface OrganizationDetails {
  address: CustomAddress;
  badges: CatalogEntityBadge[];
  boardMembers: OrganizationTeamMember[];
  budgetOrganisations: FinancialStatement[];
  organisationCoverImageDto: Media;
  executiveSummary: string;
  executiveTeams: OrganizationTeamMember[];
  history: string;
  missionStatement: string;
  numberOfEmployees: number;
  numberOfVolunteers: number;
  organisationCategoriesNames: string[];
  organisationCauseName: string;
  organisationDiligenceReport: Media;
  organisationDocuments: DocumentMedia[];
  organisationGivingLink: string;
  organisationLogoDto: Media;
  organisationImagesDto: Media[];
  organisationInfluencedCountriesNames: string[];
  organisationLanguages: Language[];
  organisationLeader: OrganizationTeamMember;
  organisationLeaderThumbnailVideoDto: Media;
  organisationLeaderVideoDto: Media;
  organisationName: string;
  organisationSocialMediaFacebook: string;
  organisationSocialMediaInstagram: string;
  organisationSocialMediaLinkedin: string;
  organisationSocialMediaTwitter: string;
  organisationTargetPopulation: string;
  organisationTeamMediaPictureDto: Media;
  organisationTeamMediaVideoDto: Media;
  organisationTeamMediaVideoThumbnailDto: Media;
  organisationThumbnailChampionVideo: Media;
  organisationUuid: string;
  organisationValues: string;
  organisationVideoDto: Media;
  organisationWebsite: string;
  partnerOrganisations: ProjectPartner[];
  programAndActivities: string;
  projectPreviewDetails: CatalogProjectCardDto[];
  totalOperatingBudget: number;
  totalOperatingBudgetSymbol: string;
  visionStatement: string;
  yearFounded: number;
  addedToWatchlist: boolean;
  champions: EntityChampionDto[];
  championed: boolean;
}

export class AdminOrganisation extends EntityMainInfo {
  languages: Language[];
  availableNewLanguages: Language[];
  projects: AdminOrganizationProjectCard[];
  uuidNameValue: UUIDName;
  routes: OrganisationRoutes;
  rank: number;
  maximumNumberOfLiveProjects: number;
  profile: UUIDName;

  constructor(organisationInfo: AdminOrganisationDto, orgUuid: string) {
    super(orgUuid, organisationInfo.organisationName, organisationInfo.organisationStatus, organisationInfo.changeStatusReason);
    this.languages = Utils.sortArray(organisationInfo.languages, 'primaryLanguage', false);
    this.projects = this.setProjects(organisationInfo.projects);
    this.uuidNameValue = new UUIDName(this.uuid, this.name);
    this.rank = organisationInfo.organisationRank;
    this.maximumNumberOfLiveProjects = organisationInfo.maximumNumberOfLiveProjects;
    this.profile = organisationInfo.organisationProfile;
    this.setSelectedLanguage(this.languages.find((lang) => lang.primaryLanguage));
    this.routes = new OrganisationRoutes(this);
  }

  setProjects(projectsDto: AdminOrganizationProjectCardDto[]): AdminOrganizationProjectCard[] {
    return projectsDto.map((projectDto: AdminOrganizationProjectCardDto) => {
      const projectCard = new AdminOrganizationProjectCard(projectDto);
      projectCard.setAvailableLanguages(this.languages);
      if (!projectCard.status) {
        projectCard.setEntityStatus(projectDto.languages?.find((item) => item.primaryLanguage)?.entityStatus as EntityStatus);
      }
      return projectCard;
    });
  }

  setAvailableLanguages(allLanguages: Language[]): void {
    this.availableNewLanguages = allLanguages.filter((language) => !this.languages.find((usedLanguage) => usedLanguage.uuid === language.uuid));
  }

  removeProject(project: AdminOrganizationProjectCard): void {
    this.projects = this.projects.filter((proj) => proj.uuid !== project.uuid);
  }
}

export interface OrganizationTeamMember {
  bio: string;
  creationDate: number;
  fullName: string;
  linkedinProfile: string;
  uuid: string;
  profilePicture: Media;
  profilePictureUrl?: string;
  title?: string;
  position?: number;
}

export interface OrganizationDropdowns {
  budgetYears: UUIDName[];
  categories: UUIDName[];
  champions: UUIDName[];
  currencies: UUIDName[];
  countries: UUIDName[];
  regions: UUIDName[];
  projectManagers: UUIDName[];
  causes: UUIDName[];
}

export class FinancialStatement {
  uuid: string;
  fiscalYearEnd: string;
  budgetYear: UUIDName;
  hideBudget: boolean;
  hideActuals: boolean;
  budgetYearUUID: string;
  totalBudgetRevenues: number;
  totalBudgetExpenses: number;
  totalActualsRevenues: number;
  totalActualsExpenses: number;
  netGainLossBudget: number;
  position: number;
  netGainLossActuals: number;
  budgetRevenues: BudgetItem[] = [];
  budgetExpenses: BudgetItem[] = [];
  budgetCurrencyUuid: string;
  budgetCurrency: UUIDName;

  constructor() {}
}

export class BudgetItem {
  budget: number;
  actuals: number;
  itemName: string;
  position: number;
  uuid: string;

  constructor() {}
}

export class OrganisationRoutes {
  organisationUuid: string;
  preview: NavigationData;
  listOfUsers: NavigationData;
  organisationsList: NavigationData;
  updateOrganisation: NavigationData;
  updateProject: NavigationData;
  newProject: NavigationData;

  constructor(organisation: EntityMainInfo) {
    this.organisationUuid = organisation.uuid;
    this.preview = new NavigationData(`/${Urls.APP}/${Urls.DETAILS}/${Urls.ORGANIZATION}/${this.organisationUuid}`, {
      preview: true,
    });
    this.listOfUsers = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.USERS_PERMISSIONS}`, {
      entityUuid: this.organisationUuid,
      entityName: organisation.name,
    });
    this.organisationsList = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.ORGANIZATIONS}`);
    this.updateOrganisation = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.ORGANIZATION}/${Urls.UPDATE_ORGANIZATION}/${this.organisationUuid}`,
      {
        language: organisation.language.code,
        primary: organisation.language.primaryLanguage,
      }
    );
    this.newProject = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${this.organisationUuid}/${Urls.NEW_PROJECT}`, {
      language: organisation.language.code,
      primary: true,
    });
  }

  setUpdateOrganisation(language: Language): void {
    this.updateOrganisation = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.ORGANIZATION}/${Urls.UPDATE_ORGANIZATION}/${this.organisationUuid}`,
      {
        language: language.code,
        primary: language.primaryLanguage,
      }
    );
  }

  setUpdateProject(language: Language, projectUuid: string): void {
    this.updateProject = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${this.organisationUuid}/${Urls.UPDATE_PROJECT}/${projectUuid}`,
      {
        language: language.code,
        primary: language.primaryLanguage,
      }
    );
  }

  setNewProject(language: Language): void {
    this.newProject = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${this.organisationUuid}/${Urls.NEW_PROJECT}`, {
      language: language.code,
      primary: true,
    });
  }
}
