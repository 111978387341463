<div *ngIf="partOfForm ? !document?.value?.pendingUpload : true">
  <div class="d-flex justify-content-between doc-card mb-2">
    <div class="m-1 d-flex align-items-center">
      <img class="ml-3 pointer" src="assets/icons/Download_Icon.svg" (click)="previewDocument(document)" />

      <div class="d-flex flex-column justify-content-between ml-2 ml-sm-4 my-sm-3">
        <div class="font-weight-bold truncated-single-line doc-name pointer" (click)="previewDocument(document)">
          {{
            partOfForm
              ? document.value.documentName || document.value.fileName
              : document.documentName || document.fileName || document.filename.split('/')[2]
          }}
        </div>
        <div class="d-flex" *ngIf="partOfForm">
          <img
            class="author-image mr-2"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="partOfForm ? document.value.ownerProfilePictureUrl : document.ownerProfilePictureUrl"
          />
          <div class="mr-2">
            {{ 'SHARED.COMPONENTS.DOCUMENT_CARD.BY' | translate: { userName: partOfForm ? document.value.ownerName : document.ownerName } }},
          </div>
          <div>
            {{ 'SHARED.COMPONENTS.DOCUMENT_CARD.ON' | translate: { uploadDate: partOfForm ? document.value.uploadDate : document.uploadDate } }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="partOfForm">
      <button
        class="doc-options mt-2 mr-2 p-0 d-flex align-items-center justify-content-center"
        mat-flat-button
        disableRipple
        [ngClass]="{ active: trigger.menuOpen }"
        [matMenuTriggerFor]="memberOptions"
        #trigger="matMenuTrigger"
        *ngIf="isAdmin"
      >
        <img class="" [src]="trigger.menuOpen ? 'assets/icons/dots-white.svg' : 'assets/icons/dots-black.svg'" />
      </button>
    </div>
  </div>
  <mat-menu #memberOptions="matMenu" xPosition="before">
    <button mat-menu-item (click)="deleteDocument(document, entityUuid)">
      <img class="mb-1 mr-2" src="assets/icons/Delete_Icon.svg" />
      {{ 'SHARED.COMPONENTS.DOCUMENT_CARD.DELETE_DOCUMENT' | translate }}
    </button>
  </mat-menu>
</div>
