export enum LOADER_TYPE {
  GLOBAL,
  LOCAL,
}

export const NoLoaderRequests = ['group/notifications', '/check-name'];

// prettier ignore
export const GlobalLoaderRequests = [
  '/get-user-info',
  'project/details',
  'delete',
  'organisation/details',
  '/save-global-settings',
  '/create-update',
  '/toggle-live',
  '/toggle-visible',
  '/account-update',
  '/archive-user',
  '/delete-user',
  '/unarchive-user',
];
