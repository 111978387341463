import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOrFind',
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], field: string, valueToSearch: any, find: boolean): unknown {
    const predicate = field ? (item) => item[field] === valueToSearch : (item) => item === valueToSearch;
    return value.length ? (find ? value.find(predicate) : value.filter(predicate)) : [];
  }
}
