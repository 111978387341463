import { Pipe, PipeTransform } from '@angular/core';
import { CustomAddress } from 'src/app/_core/models/CustomAddress';
import { GlobalConfigService } from 'src/app/_core/services/global-config.service';

@Pipe({
  name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
  constructor(private globalConfigSvc: GlobalConfigService) {}

  transform(address: CustomAddress): string {
    let formattedAddress = '';
    const addressFormat = this.globalConfigSvc.globalConfig.addressFormat as unknown as string[];

    for (let index = 0; index < addressFormat.length; index++) {
      const selectedAddressFormat = addressFormat[index];
      const cleanSelectedAddressFormat = selectedAddressFormat.split('_').join('');
      const addressKeys = Object.keys(address);

      for (let index = 0; index < addressKeys.length; index++) {
        const selectedAddressKey = addressKeys[index];
        if (cleanSelectedAddressFormat.indexOf(selectedAddressKey.toUpperCase()) > -1) {
          const keyValue = address[selectedAddressKey];
          if (keyValue) {
            formattedAddress += keyValue + ' ';
          }
          break;
        }
      }
    }

    return formattedAddress;
  }
}
