import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { Modals } from '../../../_core/constants/Modals';
import { ToastrMessages } from '../../../_core/constants/ToastrMessages';
import { DOC_FORMATS, FileType, IMG_FORMATS, VIDEO_FORMATS } from '../../../_core/constants/FileType';
import Utils from '../../../_core/helpers/Utils';
import { Media } from 'src/app/_core/models/FormControls';
import { ToastService } from 'src/app/_core/services/toast.service';

@Component({
  selector: 'app-media-info',
  templateUrl: './media-info.component.html',
  styleUrls: ['./media-info.component.scss'],
})
export class MediaInfoComponent implements OnInit {
  @Input() media: Media;
  @Input() canPreview = true;
  @Input() shouldDisable = false;
  @Input() showPreview = false;
  @Output() deleteFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() setCover: EventEmitter<any> = new EventEmitter<any>();

  video: boolean;
  displaySetCoverButton: boolean;
  shortName: string;
  fileType = FileType;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.shortName = Utils.getFileName(this.media.fileName);
    this.checkFileTypes();
  }

  checkFileTypes(): void {
    switch (this.media.fileType) {
      case FileType.VIDEO:
        this.video = true;
        break;
      case FileType.PROJECT_IMAGE:
      case FileType.ORGANISATION_IMAGE:
        this.displaySetCoverButton = true;
        break;
      default:
        break;
    }
  }

  setAsCover(): void {
    this.setCover.emit();
  }

  delete(): void {
    this.deleteFile.emit();
  }

  previewDownload(): void {
    this.preview();
  }

  preview(): void {
    const extension: string = this.extractExtension();
    let fileType: FileType;
    if (extension) {
      if (IMG_FORMATS.indexOf(extension.toLowerCase()) > -1) {
        fileType = FileType.IMAGE;
      } else if (VIDEO_FORMATS.indexOf(extension.toLowerCase()) > -1) {
        fileType = FileType.VIDEO;
      } else if (DOC_FORMATS.indexOf(extension.toLowerCase()) > -1) {
        fileType = FileType.DOC;
      } else {
        this.toastService.error(ToastrMessages.NO_PREVIEW_FORMAT);
        return;
      }
      if (fileType === FileType.DOC) {
        Utils.navigate(this.media.url);
      } else {
        this.modalsService.openModal(Modals.MEDIA, { urls: [this.media.url], fileType });
      }
    }
  }

  extractExtension(): string {
    return this.media ? Utils.extractFileExtension(this.media.fileName) : null;
  }
}
