import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import StorageHelper from '../helpers/Storage.helper';
import Utils from '../helpers/Utils';
import { Currency } from '../models/global-settings/Currency';
import { GlobalDropdowns } from '../models/global-settings/Dropdowns';
import { UserDetails } from '../models/User';
import { DropdownsService } from './dropdowns.service';
import { UserService } from './user.service';
import { GlobalConfigService } from './global-config.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public selectedCurrency$ = new ReplaySubject<Currency>(1);
  public currencies$ = new ReplaySubject<Currency[]>(1);
  public saveGlobalCurrency$ = new Subject<string>();
  public selectedCurrency: Currency;
  private currencies: Currency[];
  private user: UserDetails;
  private readonly tenantCurrency: string;

  // prettier-ignore
  constructor(
    private userService: UserService,
    private dropdownService: DropdownsService,
    private globalConfigService: GlobalConfigService
  ) {
    this.getCurrencies();
    this.tenantCurrency = this.globalConfigService.globalConfig.defaultCurrency.name;
  }

  private getCurrencies(): void {
    this.dropdownService.dropdowns$.pipe(take(1)).subscribe((dropdowns: GlobalDropdowns) => {
      this.currencies = dropdowns.currencies;
      this.currencies$.next(this.currencies);
      this.handleUser();
    });
  }

  private handleUser(): void {
    this.userService.currentUser$.subscribe((user: UserDetails) => {
      this.user = user;
      const loggedIn = !!this.user && !!StorageHelper.getKeycloakToken();
      const loggedOut = !this.user && !StorageHelper.getKeycloakToken();
      if (loggedIn || loggedOut) {
        this.handleCurrency();
      }
    });
  }

  private handleCurrency(): void {
    if (!!this.user) {
      this.pushNewCurrency(this.user?.globalCurrency || null);
    } else {
      this.pushNewCurrency(StorageHelper.getCurrency() ? StorageHelper.getCurrency() : this.tenantCurrency);
    }
  }

  public setCurrency(currency: Currency): void {
    this.pushNewCurrency(currency?.name || null);
  }

  private pushNewCurrency(currencySymbol): void {
    this.selectedCurrency = this.currencies.find((currency: Currency) => currency?.name === currencySymbol) || null;
    this.selectedCurrency$.next(this.selectedCurrency);
    StorageHelper.saveCurrency(this.selectedCurrency?.name || null);
  }

  public convert(amount: number, currencySymbol: string, showSymbol = true): [amount: number, currencySymbol: string] {
    if (this.selectedCurrency) {
      if (this.tenantCurrency === currencySymbol) {
        amount = amount * this.selectedCurrency.exchangeRate;
      } else {
        const baseCurrency = this.currencies.find((item) => item.name === currencySymbol);
        amount = (amount * this.selectedCurrency.exchangeRate) / baseCurrency.exchangeRate;
      }
      currencySymbol = showSymbol ? Utils.setCurrencySymbol(this.selectedCurrency.name) : ` ${this.selectedCurrency.name} `;
    } else {
      currencySymbol = showSymbol ? Utils.setCurrencySymbol(currencySymbol) : ` ${currencySymbol} `;
    }
    return [amount, currencySymbol];
  }
}
