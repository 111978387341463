import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectIteration',
})
export class ObjectIterationPipe implements PipeTransform {
  transform(value: {}): string[] {
    return !value ? [] : Object.keys(value);
  }
}
