<div class="text-right mb-4" *ngIf="isModal">
  <a><img (click)="close()" class="close-icon pointer" src="/assets/icons/clear-icon.svg" /></a>
</div>
<div class="center">
  <div class="code-of-conduct my-4">
    <h1 class="text-center mb-5 h1-legal">{{ 'HOME.LEGAL.' + term + '.TITLE' | translate }}</h1>
    <div class="section-legal" style="">
      <div [innerHTML]="'HOME.LEGAL.' + term + '.CONTENT' | translate | sanitizeHtml"></div>
    </div>
  </div>
</div>
<div class="py-4 action-btns d-flex justify-content-end buttons-container sticky-container-legal" *ngIf="isModal">
  <button class="mat-outline-button gray-border mr-2" mat-stroked-button color="ternary" (click)="close()">
    {{ 'GENERAL.GENERIC_BUTTONS.CLOSE' | translate }}
  </button>
</div>
