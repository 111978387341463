import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeNumber',
})
export class NegativeNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    const cleanValue = typeof value === 'number' ? value.toString() : value;
    return cleanValue && cleanValue.charAt(0) === '-'
      ? cleanValue.charAt(1) === ' '
        ? `(${cleanValue.substring(2, cleanValue.length)})`
        : `(${cleanValue.substring(1, cleanValue.length)})`
      : cleanValue;
  }
}
