import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserTerm } from '../../../_core/constants/User';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LegalComponent implements OnInit {
  term: UserTerm;
  isModal: boolean;

  // prettier-ignore
  constructor(
      private modalsService: ModalsService
      ) {}

  ngOnInit(): void {
    this.isModal = this.modalsService.params?.modal;
    this.term = this.modalsService.params?.term || UserTerm.CODE_OF_CONDUCT;
  }

  close(): void {
    this.modalsService.closeModal();
  }
}
