<div class="mr-sm-3 mr-0">
  <ng-container *ngIf="isLink; else button">
    <a
      [id]="buttonId"
      class="my-sm-3 my-2 mat-main-button uppercase w-100"
      mat-flat-button
      color="primary"
      disableRipple
      [routerLink]="navigationData?.url || url"
      [queryParams]="navigationData?.queryParams"
    >
      <img *ngIf="addNewIcon" class="mr-3" src="assets/icons/add.svg" alt="tab-icon" />
      {{ text | translate }}
    </a>
  </ng-container>
  <ng-template #button>
    <button
      [id]="buttonId"
      class="my-sm-3 my-2 mat-main-button uppercase w-100"
      mat-flat-button
      color="primary"
      disableRipple
      (click)="clicked.emit()"
      [disabled]="isDisabled"
    >
      <img *ngIf="addNewIcon" class="mr-3" src="assets/icons/add.svg" alt="tab-icon" />
      {{ text | translate }}
    </button>
  </ng-template>
</div>
