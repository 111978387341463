<div class="row mt-6 mx-0 text-white bg-black py-5" *ngIf="showSecondaryFooter">
  <div class="col-12 col-lg-6 px-5">
    <h1>{{ 'SHARED.COMPONENTS.FOOTER.SECONDARY_FOOTER_TITLE' | translate }}</h1>
  </div>
  <div class="col-12 col-lg-6 px-5">
    <h3 class="mb-3 font-weight-bold">
      {{ 'SHARED.COMPONENTS.FOOTER.SECONDARY_FOOTER_CONTENT' | translate }}
    </h3>
    <a
      id="faqButton"
      class="mat-main-button uppercase sign-button py-1 px-4 bg-white text-black"
      mat-flat-button
      color="primary"
      *ngIf="contactLink"
      [href]="contactLink"
      target="_blank"
    >
      <span class="font-weight-bold font-18">{{ 'SHARED.COMPONENTS.TOP_BAR.CONTACT' | translate }}</span>
    </a>
  </div>
</div>
<div class="d-flex flex-column align-items-center footer-container w-100" [ngStyle]="{ 'background-color': footerColor.rgba }">
  <ng-container *ngIf="isChampionPage">
    <p class="text-white font-16 font-weight-bold mb-1">{{ 'HOME.LEGAL.CHAMPION_DISCLOSURE.TITLE' | translate }}</p>
    <p class="text-white mb-4" [innerHtml]="'HOME.LEGAL.CHAMPION_DISCLOSURE.CONTENT' | translate | sanitizeHtml"></p>
  </ng-container>
  <div class="text-center text-white mb-3">
    <a class="text-white" [href]="termsOfUse" target="_blank"
      ><u>{{ 'SHARED.COMPONENTS.FOOTER.FOOTER_TERMS_OF_USE' | translate }}</u></a
    >
    -
    <a class="text-white" [href]="privacyPolicy" target="_blank"
      ><u>{{ 'SHARED.COMPONENTS.FOOTER.FOOTER_PRIVACY_POLICY' | translate }}</u></a
    >
    <ng-container *ngIf="hasCodeOfConduct">
      -
      <a class="text-white" href="app/code-of-conduct" target="_blank"
        ><u>{{ 'SHARED.COMPONENTS.FOOTER.FOOTER_CODE_OF_CONDUCT' | translate }}</u></a
      >
    </ng-container>
  </div>
  <div class="text-center text-white font-weight-bold">
    {{ 'SHARED.COMPONENTS.FOOTER.FOOTER_COPYRIGHT_V2' | translate: { year: year, companyName: companyName } | translateCut: 0 }}
    <ng-container *ngIf="footerParentWebsiteLink; else noFooterLink">
      <a class="text-white" [href]="footerParentWebsiteLink" target="_blank">
        <u>{{ 'SHARED.COMPONENTS.FOOTER.FOOTER_COPYRIGHT_V2' | translate: { year: year, companyName: companyName } | translateCut: 1 }}</u>
      </a>
    </ng-container>
    <ng-template #noFooterLink>
      {{ 'SHARED.COMPONENTS.FOOTER.FOOTER_COPYRIGHT_V2' | translate: { year: year, companyName: companyName } | translateCut: 1 }}
    </ng-template>
    {{ 'SHARED.COMPONENTS.FOOTER.FOOTER_COPYRIGHT_V2' | translate: { year: year, companyName: companyName } | translateCut: 2 }}
  </div>
</div>
