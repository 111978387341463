import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../../_core/helpers/Utils';

@Pipe({
  name: 'variableToString',
})
export class VariableToStringPipe implements PipeTransform {
  transform(value: string, linkingWord: string = ''): string {
    return Utils.variableToString(value, linkingWord);
  }
}
