import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { LOADER_TYPE } from 'src/app/_core/constants/Global/Loader';
import { LoaderData } from 'src/app/_core/models/Loader';
import { LoaderService } from 'src/app/_core/services/loader.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() forceLoading: boolean;
  @Input() small: boolean;

  loading: boolean;
  loadingSub: Subscription;

  // prettier-ignore
  constructor(
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.loadingSub = this.loaderService.loading$.subscribe((loaderData: LoaderData) => {
      if (loaderData.type === LOADER_TYPE.LOCAL) {
        this.loading = loaderData.visible || this.forceLoading;
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forceLoading) {
      this.loading = this.forceLoading;
    }
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
