import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AccountService } from '../api/account.service';
import { ToastrMessages } from '../constants/ToastrMessages';
import StorageHelper from '../helpers/Storage.helper';
import { ResponseObject } from '../models/GenericObject';
import { Privileges } from '../models/Privileges';
import { UserDetails } from '../models/User';
import { BehaviorSubject, Observable, of } from 'rxjs';
import Urls from '../constants/Urls';
import { Router } from '@angular/router';
import { MEMBER_STATE } from '../constants/Groups/Group';
import { PrivilegesService } from './privileges.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: UserDetails = null;
  private currentUserSource = new BehaviorSubject<UserDetails>(null);
  currentUser$ = this.currentUserSource.asObservable();

  // prettier-ignore
  constructor(
    private router: Router,
    private accountService: AccountService,
    private privilegesService: PrivilegesService,
    private toastService: ToastService
  ) {
  }

  getUserInfo(): Observable<any> {
    return this.accountService.getUserInfo().pipe(
      tap((res: ResponseObject<UserDetails & Privileges>) => {
        this.privilegesService.setPrivileges(res?.response);
        if (res.response?.email) {
          this.setCurrentUser(res?.response);
        } else {
          this.setCurrentUser(null);
        }
      }),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        StorageHelper.killSession();
        return of(null);
      })
    );
  }

  get currentUser(): UserDetails {
    return this._currentUser;
  }

  setCurrentUser(currentUser: UserDetails) {
    this.currentUserSource.next(currentUser);
    this._currentUser = currentUser;
  }

  isLoggedIn() {
    return this._currentUser !== null;
  }

  handleGroupsNotifications(notificationType: MEMBER_STATE): void {
    if (notificationType === MEMBER_STATE.REQUESTED_TO_JOIN && this.currentUser.requestsToJoinGroupsCounter > 0) {
      this.currentUser.requestsToJoinGroupsCounter -= 1;
    } else if (notificationType === MEMBER_STATE.PENDING_INVITATION && this.currentUser.invitedToJoinGroupCounter > 0) {
      this.currentUser.invitedToJoinGroupCounter -= 1;
    }
    this.setCurrentUser(this.currentUser);
  }

  logout(redirectUrl?: string): Observable<any> {
    return this.accountService.logout().pipe(
      tap(() => {
        this.killSession(redirectUrl);
      })
    );
  }

  killSession(redirectUrl?: string) {
    StorageHelper.killSession();
    this.getUserInfo().subscribe(() => {
      const url = this.router.parseUrl(redirectUrl ? redirectUrl : Urls.BASE);
      this.router.navigateByUrl(url);
    });
  }
}
