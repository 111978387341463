import Utils from '../helpers/Utils';
import { DOC_FORMATS, FileType, IMG_FORMATS, VIDEO_FORMATS } from '../constants/FileType';

export class File {
  uuid: string;
  name: string;
  path: string;
  fileType: FileType;
  cover: boolean;

  constructor(uuid: string, name: string, path: string, fileType: FileType, cover: boolean = false) {
    this.uuid = uuid;
    this.name = name;
    this.path = path;
    this.fileType = fileType;
    this.cover = cover;
  }
}

export class FileDetails extends File {
  pendingUpload: boolean = false;
  isFormatValid: boolean = true;
  progress: number = 0;

  constructor(uuid: string, name: string, path: string, fileType: FileType) {
    super(uuid, name, path, fileType);
  }
}

export class Files {
  files: File[];
  pendingUpload: boolean;
  isFormatValid: boolean;
  progress: number;

  constructor(files: File[], pendingUpload: boolean = false, isFormatValid = true, progress = 0) {
    this.files = files;
    this.pendingUpload = pendingUpload;
    this.isFormatValid = isFormatValid;
    this.progress = progress;
  }
}

export class UserDetailsFiles {
  profileImage: FileDetails;
  investorPhoto: FileDetails;
  partnerPhoto: FileDetails;
  partnerLogo: FileDetails;
  introVideo: FileDetails;
  thumbnailPartnerVideo: FileDetails;

  constructor() {
    this.profileImage = new FileDetails(null, null, null, null);
    this.investorPhoto = new FileDetails(null, null, null, null);
    this.partnerPhoto = new FileDetails(null, null, null, null);
    this.partnerLogo = new FileDetails(null, null, null, null);
    this.introVideo = new FileDetails(null, null, null, null);
    this.thumbnailPartnerVideo = new FileDetails(null, null, null, null);
  }
}

export class AllowedFileTypesMessage {
  imageValidFormats: string;
  videoValidFormats: string;
  docValidFormats: string;
  imageValidFormatsMessage: string;
  videoValidFormatsMessage: string;
  docValidFormatsMessage: string;
  videoOrImageValidFormats: string;
  videoOrImageValidFormatsMessage: string;

  constructor() {
    this.imageValidFormats = Utils.arrayToFormattedStr(IMG_FORMATS);
    this.videoValidFormats = Utils.arrayToFormattedStr(VIDEO_FORMATS);
    this.docValidFormats = Utils.arrayToFormattedStr(DOC_FORMATS);
    this.imageValidFormatsMessage = this.computeValidFormatsMessage(this.imageValidFormats);
    this.videoValidFormatsMessage = this.computeValidFormatsMessage(this.videoValidFormats);
    this.docValidFormatsMessage = this.computeValidFormatsMessage(this.docValidFormats);
    this.videoOrImageValidFormats = Utils.arrayToFormattedStr(VIDEO_FORMATS.concat(IMG_FORMATS));
    this.videoOrImageValidFormatsMessage = this.computeValidFormatsMessage(this.videoValidFormats.concat(this.imageValidFormats));
  }

  computeValidFormatsMessage(formats: string) {
    return `Accepted format(s): ${formats}.`;
  }
}
