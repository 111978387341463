import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { InputNumberFormatterOptions } from 'src/app/_core/models/FormControls';

@Directive({
  selector: 'input[formatNumberInput]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormatNumberInput },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormatNumberInput),
      multi: true,
    },
  ],
})
export class FormatNumberInput implements ControlValueAccessor, OnInit, OnChanges {
  @Input() options: InputNumberFormatterOptions = {
    allowNegative: true,
    min: -999999999999999,
    max: 999999999999999,
    allowDecimals: true,
    maxDecimals: 2,
  };
  @Output() onStateChange = new EventEmitter();

  decimalMarker: string = '.';
  thousandsSeparator: string = ',';
  outOfBounds: boolean = false;

  constructor(private element: ElementRef<HTMLInputElement>) {}

  @Input('value')
  set value(value: string | null) {
    this.formatValue(value);
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    this.formatValue(value);
  }

  ngOnInit() {
    this.updateOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = { ...changes.options?.currentValue };
    this.updateOptions();
    this.formatValue(this.element.nativeElement.value, false);
  }

  updateOptions(): void {
    this.options.allowNegative = this.options.allowNegative !== undefined ? this.options.allowNegative : true;
    this.options.allowDecimals = this.options.allowDecimals !== undefined ? this.options.allowDecimals : true;
    this.options.max = this.options.max !== undefined ? this.options.max : 999999999999999;
    this.options.min = this.options.min !== undefined ? this.options.min : -999999999999999;
    this.options.maxDecimals = this.options.maxDecimals !== undefined ? this.options.maxDecimals : 2;
  }

  writeValue(value: any) {
    this.formatValue(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  // set the value of the formControl
  _onChange(_value: any): void {}

  //trigger the validators
  _onTouched(): void {
    this.onStateChange.emit();
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  filterValue(value: string): string {
    const filteredStringValue = value
      .split(this.thousandsSeparator)
      .join('')
      .split('')
      .filter((char, index, arr) => {
        return this.options.allowNegative
          ? index === 0 || arr[index - 1] === ' '
            ? /\d/.test(char) || char === '-'
            : /\d/.test(char) || char === this.decimalMarker
          : /\d/.test(char) || char === this.decimalMarker;
      })
      .join('');

    return ((parseInt(filteredStringValue) >= this.options.max || parseInt(filteredStringValue) <= this.options.min) &&
      filteredStringValue[filteredStringValue.length - 1] === this.decimalMarker) ||
      (filteredStringValue[0] === '-' && filteredStringValue[1] === this.decimalMarker)
      ? filteredStringValue.substring(0, filteredStringValue.length - 1)
      : filteredStringValue;
  }

  checkOutOfBounds(value: number): string {
    if (value > this.options.max) {
      this.outOfBounds = true;
      return this.options.max.toString();
    } else if (value < this.options.min) {
      this.outOfBounds = true;
      return `${this.options.min.toString()}`;
    } else {
      return value.toString();
    }
  }

  private formatValue(value: string | number | null, touch: boolean = true) {
    this.outOfBounds = false;
    if (value === null || value === undefined || Number.isNaN(value)) {
      this.element.nativeElement.value = null;
      if (touch) {
        this._onChange(null);
        this._onTouched();
      }
      return;
    }
    let inputValue: string = this.filterValue(value.toString());
    let [integer, decimal] = inputValue.split(this.decimalMarker);
    inputValue = isNaN(parseInt(integer))
      ? integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator)
      : this.checkOutOfBounds(parseInt(integer)).replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);

    if (this.options.allowDecimals && decimal?.length >= 0 && !this.outOfBounds) {
      inputValue = inputValue.concat(this.decimalMarker, decimal.substring(0, this.options.maxDecimals));
    }

    this.element.nativeElement.value = this.options.prefix ? `${this.options.prefix} ${inputValue}` : inputValue;

    if (touch) {
      this._onChange(parseFloat(inputValue.split(',').join('')));
      this._onTouched();
    }
  }
}
