import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrencyService } from '../../../_core/services/currency.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() amount: number;
  @Input() currency: string;
  @Input() showSymbol = true;
  @Input() hideCurrency = false;

  subscription: Subscription;

  parsedCurrency: string;
  exchangedMoney: number;

  // prettier-ignore
  constructor(
    private currencyService: CurrencyService
  ) {
  }

  ngOnInit() {
    this.subscription = this.currencyService.selectedCurrency$.subscribe(() => this.convert());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.amount?.currentValue === 'number' && changes.amount.currentValue !== changes.amount?.previousValue) {
      this.convert();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  convert(): void {
    [this.exchangedMoney, this.parsedCurrency] = this.currencyService.convert(this.amount, this.currency, this.showSymbol);
  }
}
