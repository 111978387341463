import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { FileType } from '../../../_core/constants/FileType';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  urls: Array<string>;
  fileType: FileType;
  fileTypes = FileType;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: '<i class="fa fa-chevron-circle-right"></i>',
    prevArrow: '<i class="fa fa-chevron-circle-left"></i>',
    variableWidth: false,
    adaptiveHeight: true,
    lazyLoad: 'progressive',
  };

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.urls = this.modalsService.params.urls;
    this.fileType = this.modalsService.params.fileType as FileType;
  }

  close(): void {
    this.modalsService.closeModal();
  }
}
