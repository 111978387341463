import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityStatus } from 'src/app/_core/constants/CompanyOrFirm';
import { ButtonType } from '../../../_core/constants/ButtonType';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent implements OnInit, OnChanges {
  @Input() changesMade: boolean = false;
  @Input() entityStatus: EntityStatus = null;
  @Output() buttonClicked: EventEmitter<ButtonType> = new EventEmitter<ButtonType>();
  buttonTypes = ButtonType;
  entityStatuses = EntityStatus;
  hidePreview: boolean;
  disableSubmit: boolean;
  hideSave: boolean;
  saveButtonText: string;

  constructor() {}

  ngOnInit(): void {
    this.handleConditions();
    this.saveButtonText = this.entityStatus === EntityStatus.DRAFT || !this.entityStatus ? 'SAVE_DRAFT' : 'SAVE_CHANGES';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityStatus && changes.entityStatus.currentValue !== changes.entityStatus.previousValue) {
      this.handleConditions();
    }
  }

  handleConditions(): void {
    const nonSubmitable = [EntityStatus.ARCHIVED, EntityStatus.DECLINED];
    const saveableStatuses = [EntityStatus.ARCHIVED, EntityStatus.DRAFT, EntityStatus.EXPIRED];
    this.disableSubmit = !this.entityStatus || nonSubmitable.includes(this.entityStatus);
    this.hidePreview = this.entityStatus !== EntityStatus.DRAFT;
    this.hideSave = this.entityStatus && !saveableStatuses.includes(this.entityStatus);
  }
}
