import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'website',
})
export class WebsitePipe implements PipeTransform {
  transform(value: string): string {
    const website = value ? value.replace(/https?:\/\//, '') : null;
    if (website) {
      const websiteArray = website.split('.');
      return websiteArray[0].toLowerCase() === 'www' ? website : 'www.' + website;
    }
    return null;
  }
}
