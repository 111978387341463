<div class="champion-card">
  <div class="d-flex justify-content-center champion-card-title-container">
    <span class="font-sm-12 line-height-18 uppercase" *ngIf="champion.status">{{ champion.status }}</span>
  </div>
  <div class="champion-card-wrapper">
    <div class="champion-card-content">
      <div class="d-flex flex-column justify-content-between content-details">
        <span class="font-sm-12 line-height-18 truncated-2-lines" *ngIf="champion.championName"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.CHAMPION_NAME' | translate }}</span>
          {{ champion.championName }}</span
        >
        <span class="font-sm-12 line-height-18 truncated-2-lines" *ngIf="champion.organizationName"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.ORGANIZATION_NAME' | translate }}</span>
          {{ champion.organizationName }}</span
        >
        <span class="font-sm-12 line-height-18" *ngIf="champion.dateSubmitted"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.DATE_SUBMITTED' | translate }}</span>
          {{ champion.dateSubmitted | date: dateFormat }}</span
        >
        <span class="font-sm-12 line-height-18" *ngIf="champion.yearsOfEngagement"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.ENGAGEMENT_YEARS' | translate }}</span>
          {{ champion.yearsOfEngagement }}</span
        >
        <span
          class="font-sm-12 truncated-2-lines line-height-18"
          [matTooltip]="champion.testimonial.length > 50 ? champion.testimonial : ''"
          *ngIf="champion.testimonial"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.TESTIMONIAL' | translate }}</span>
          {{ champion.testimonial }}</span
        >
        <span
          class="font-sm-12 truncated-2-lines line-height-18"
          [matTooltip]="champion.declineReason.length > 50 ? champion.declineReason : ''"
          *ngIf="champion.declineReason"
          ><span class="font-weight-bold">{{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.LABELS.DECLINING_REASON' | translate }}</span>
          {{ champion.declineReason }}</span
        >
      </div>
      <div class="d-flex justify-content-end mt-3 action-button-container">
        <button
          (click)="onViewLive()"
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showViewLiveButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.VIEW_LIVE' | translate }}
        </button>
        <button
          (click)="onViewForm()"
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showViewFormButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.VIEW_FORM' | translate }}
        </button>
        <button
          (click)="onRestore()"
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showRestoreButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.RESTORE' | translate }}
        </button>
        <button
          (click)="onEdit()"
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showEditButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.EDIT' | translate }}
        </button>
        <button
          (click)="onArchive()"
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showArchiveButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.ARCHIVE' | translate }}
        </button>
        <button
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          (click)="onApprove()"
          *ngIf="champion.showApproveButton"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.APPROVE' | translate }}
        </button>
        <button
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showDeclineButton"
          (click)="onDecline()"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.DECLINE' | translate }}
        </button>
        <button
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showResubmitButton"
          (click)="onResubmit()"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.RESUBMIT' | translate }}
        </button>
        <button
          mat-button
          [disableRipple]="true"
          class="px-2 flex-all-center button-height-28 mat-main-button action-button"
          *ngIf="champion.showDeleteButton"
          (click)="onDelete()"
        >
          {{ 'ADMIN.CHAMPION_LIST.CHAMPION_CARD.DELETE' | translate }}
        </button>
      </div>
    </div>

    <div class="mr-2 d-flex flex-column align-items-end" *ngIf="champion.approvedBy || champion.dateOfApproval">
      <small class="text-gray" *ngIf="champion.approvedBy">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.APPROVER_NAME' | translate) + champion.approvedBy }}
      </small>
      <small class="text-gray" *ngIf="champion.dateOfApproval">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.DATE_PUBLISHED' | translate) + (champion.dateOfApproval | date: dateFormat) }}
      </small>
    </div>

    <div class="mr-2 d-flex flex-column align-items-end" *ngIf="champion.declinedBy || champion.dateOfDecline">
      <small class="text-gray" *ngIf="champion.declinedBy">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.DECLINED_BY' | translate) + champion.declinedBy }}
      </small>
      <small class="text-gray" *ngIf="champion.dateOfDecline">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.DECLINED_DATE' | translate) + (champion.dateOfDecline | date: dateFormat) }}
      </small>
    </div>

    <div class="mr-2 d-flex flex-column align-items-end" *ngIf="champion.archivedBy || champion.archivedDate">
      <small class="text-gray" *ngIf="champion.archivedBy">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.ARCHIVED_BY' | translate) + champion.archivedBy }}
      </small>
      <small class="text-gray" *ngIf="champion.archivedDate">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.ARCHIVED_DATE' | translate) + (champion.archivedDate | date: dateFormat) }}
      </small>
    </div>

    <div class="mr-2 d-flex flex-column align-items-end" *ngIf="champion.expiredDate">
      <small class="text-gray">
        {{ ('ADMIN.CHAMPION_LIST.CHAMPION_CARD.DATE_EXPIRED' | translate) + (champion.expiredDate | date: dateFormat) }}
      </small>
    </div>
  </div>
</div>
