import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonType } from '../../../_core/constants/ButtonType';

@Component({
  selector: 'app-next-prev-buttons',
  templateUrl: './next-prev-buttons.component.html',
  styleUrls: ['./next-prev-buttons.component.scss'],
})
export class NextPrevButtonsComponent implements OnInit, OnChanges {
  @Input() selectedIndex: number;
  @Input() indexToHide = null;
  @Output() buttonClicked: EventEmitter<ButtonType> = new EventEmitter<ButtonType>();

  buttonTypes = ButtonType;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedIndex = changes.selectedIndex.currentValue;
  }
}
