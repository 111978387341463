import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { ModalEvent } from 'src/app/_core/models/Modal';
import { MODAL_ACTIONS } from '../../../_core/constants/Modals';

@Component({
  selector: 'app-media',
  templateUrl: './cancel-changes.component.html',
  styleUrls: ['./cancel-changes.component.scss'],
})
export class CancelChangesComponent implements OnInit {
  // prettier-ignore
  constructor(
    private modalsService: ModalsService
  ) {
  }

  ngOnInit(): void {}

  closeAll(): void {
    this.modalsService.closeAll();
  }

  emitFalse(): ModalEvent {
    return {
      confirmed: false,
      action: MODAL_ACTIONS.CANCEL_CHANGES,
      payload: null,
    };
  }
}
