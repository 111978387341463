import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Urls from 'src/app/_core/constants/Urls';
import { GlobalConfigService } from '../../../_core/services/global-config.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  email: string;
  showPage: boolean;

  // prettier ignore
  constructor(private route: ActivatedRoute, private router: Router, private globalConfigService: GlobalConfigService) {
    this.route.queryParams.subscribe((params) => {
      if (!params.intercepted) {
        this.router.navigate([Urls.BASE]);
      } else {
        this.showPage = true;
        this.router.navigate([], { queryParams: {} });
      }
    });
  }

  ngOnInit(): void {
    this.email = this.globalConfigService.globalConfig?.adminEmail;
  }
}
