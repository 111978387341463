import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FILTER_NAME } from '../../../_core/constants/Filters/FilterTypes';
import Urls from '../../../_core/constants/Urls';
import Utils from '../../../_core/helpers/Utils';
import { CarouselDeal, FilterSelected } from '../../../_core/models/filters/Dashboard';

@Component({
  selector: 'app-deal-carousel',
  templateUrl: './deal-carousel.component.html',
  styleUrls: ['./deal-carousel.component.scss'],
})
export class DealCarouselComponent implements OnInit, OnDestroy {
  @Input() deals: CarouselDeal[];
  @Input() seeAll = true;
  @Input() filterTitle: FILTER_NAME = null;
  @Input() link: string;
  @Input() filter: string = null;
  @Input() search: boolean;
  @Input() customButton: boolean = false;
  @Input() detailsLink: RouterLink;
  @Input() pagination: boolean = false;
  @Input() showEntityCount: boolean = false;
  @Output() categorySelected: EventEmitter<FilterSelected> = new EventEmitter<FilterSelected>();
  @Output() loadMoreItems: EventEmitter<any> = new EventEmitter<any>();

  filterName: string;

  filterType = FILTER_NAME;
  restricted = false;
  showCauses: boolean;
  mobileBreakpointSubscription: Subscription;
  tabletBreakpointSubscription: Subscription;
  desktopBreapointSubscription: Subscription;

  isCategory: boolean = false;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    mobileFirst: true,
    nextArrow: '<i class="fa fa-2x fa-chevron-circle-right"></i>',
    prevArrow: '<i class="fa fa-2x fa-chevron-circle-left"></i>',
    variableWidth: true,
    variableHeight: false,
    infinite: false,
  };

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.filterName = this.splitAndExtractLastItemWithoutLastChar(this.filter, 'name=');
    this.showCauses = environment.hasCause;
    if (
      this.filterTitle === FILTER_NAME.PORTFOLIO_CATEGORY ||
      this.filterTitle === FILTER_NAME.PROJECTS_CATEGORY ||
      this.filterTitle === FILTER_NAME.CHARITIES_CATEGORY
    ) {
      this.isCategory = true;
      this.processCategories();
      this.translateService.onLangChange.subscribe(() => this.processCategories());
    }
  }

  processCategories(): void {
    if (!this.translateService.instant('GENERAL.DROPDOWNS.CATEGORIES.' + this.filterName)) {
      this.deals = [];
    }
  }

  observe(): void {
    this.mobileBreakpointSubscription = this.breakpointObserver
      .observe(['(max-width: 965px)'])
      .subscribe((result) =>
        result.matches
          ? this.breakpointObserver.isMatched(['(min-width: 850px)'])
            ? (this.slideConfig.slidesToShow = 2)
            : (this.slideConfig.slidesToShow = 1)
          : null
      );
    this.tabletBreakpointSubscription = this.breakpointObserver
      .observe(['(min-width: 966px) and (max-width: 1166px)'])
      .subscribe((result) => (result.matches ? (this.slideConfig.slidesToShow = 2) : null));
    this.desktopBreapointSubscription = this.breakpointObserver
      .observe(['(min-width: 1167px)'])
      .subscribe((result) =>
        result.matches ? (this.customButton ? (this.slideConfig.slidesToShow = 2) : (this.slideConfig.slidesToShow = 3)) : null
      );
  }

  ngOnDestroy(): void {
    this.mobileBreakpointSubscription?.unsubscribe();
    this.tabletBreakpointSubscription?.unsubscribe();
    this.desktopBreapointSubscription?.unsubscribe();
  }

  slideInit(event): void {
    this.observe();
    event.slick.$prevArrow.hide();
    if (event.slick.slideCount <= this.slideConfig.slidesToShow) {
      event.slick.$nextArrow.hide();
    } else {
      event.slick.$nextArrow.show();
    }
  }

  beforeChange(event): void {
    if (event.nextSlide >= this.deals.length - this.slideConfig.slidesToShow) {
      event.slick.$nextArrow.hide();
    } else {
      event.slick.$nextArrow.show();
    }

    if (event.nextSlide) {
      event.slick.$prevArrow.show();
    } else {
      event.slick.$prevArrow.hide();
    }
  }

  afterChange(event): void {
    if (this.pagination && this.deals?.length && this.deals.length - event.currentSlide < 3) {
      this.loadMoreItems.emit();
    }
  }

  calculatePercent(deal: CarouselDeal): number {
    return Utils.calculatePercent(deal.principalAmount, deal.amountRemainingToBeRaised);
  }

  getLink(uuid: string, languageCode: string): string {
    let detailsLink: string;
    switch (this.filterTitle) {
      case FILTER_NAME.CHARITIES_CATEGORY:
      case FILTER_NAME.CHARITIES_REGION:
      case FILTER_NAME.NEWLY_ADDED_ORGANIZATION:
      case FILTER_NAME.CHARITIES_CHAMPIONED:
      case FILTER_NAME.ORGANISATIONS_DISCUSSED:
      case FILTER_NAME.ORGANISATIONS_INCLUDED:
      case FILTER_NAME.FEATURED_ORGANISATIONS:
        detailsLink = Urls.ORGANIZATION;
        break;
      case FILTER_NAME.CLOSE_TO_CLOSING_PROJECT:
      case FILTER_NAME.PROJECTS_REGION:
      case FILTER_NAME.PROJECTS_CATEGORY:
      case FILTER_NAME.PROJECTS_CHAMPIONED:
      case FILTER_NAME.FEATURED_PROJECTS:
      case FILTER_NAME.NEWLY_ADDED_PROJECT:
      case FILTER_NAME.PROJECTS_DISCUSSED:
      case FILTER_NAME.PROJECTS_INCLUDED:
        detailsLink = Urls.PROJECT;
        break;
      case FILTER_NAME.NEWLY_ADDED_PORTFOLIO:
      case FILTER_NAME.FEATURED_PORTFOLIOS:
      case FILTER_NAME.PORTFOLIOS_DISCUSSED:
      case FILTER_NAME.PORTFOLIO_CATEGORY:
      case FILTER_NAME.PORTFOLIO_REGION:
      case FILTER_NAME.PORTFOLIOS_CHAMPIONED:
        languageCode = null;
        detailsLink = Urls.PORTFOLIO;
        break;
      default:
        languageCode = null;
        detailsLink = this.detailsLink.toString();
        break;
    }
    return languageCode
      ? `/${Urls.APP}/${Urls.DETAILS}/${detailsLink}/${uuid}/${languageCode}`
      : `/${Urls.APP}/${Urls.DETAILS}/${detailsLink}/${uuid}`;
  }

  emitEvent(searchText: string = null) {
    if (searchText !== null) {
      if (searchText) {
        this.categorySelected.emit(new FilterSelected(FILTER_NAME.SEARCH, searchText, this.link));
      }
    } else {
      if (this.filterName) {
        this.categorySelected.emit(new FilterSelected(this.filterTitle, this.filterName, this.link));
      } else {
        switch (this.filterTitle) {
          case FILTER_NAME.NEWLY_ADDED_PROJECT:
            this.categorySelected.emit(new FilterSelected(this.filterTitle, 'DESC', `${Urls.GIVING}/${Urls.PROJECTS}`));
            break;
          case FILTER_NAME.NEWLY_ADDED_ORGANIZATION:
            this.categorySelected.emit(new FilterSelected(this.filterTitle, 'DESC', `${Urls.GIVING}/${Urls.ORGANIZATIONS}`));
            break;
          case FILTER_NAME.NEWLY_ADDED_PORTFOLIO:
            this.categorySelected.emit(new FilterSelected(this.filterTitle, 'DESC', `${Urls.GIVING}/${Urls.PORTFOLIOS}`));
            break;
          default:
            this.categorySelected.emit(new FilterSelected(this.filterTitle, 'all', this.link));
        }
      }
    }
  }

  splitAndExtractLastItemWithoutLastChar(text: string, separator: string): string {
    const lastItem = text?.split(separator).pop();
    return lastItem?.substring(0, lastItem.length - 1);
  }
}
