<mat-card class="mb-2 gray-border round-corners" [ngClass]="updateList ? (isTablet ? 'w-100' : 'w-75') : ''">
  <div class="d-flex justify-content-between w-100 flex-sm-row">
    <div class="d-flex w-100 align-items-center">
      <div>
        <img
          class="user-image"
          [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
          [lazyLoad]="groupUpdate.createdByProfileImageUrl"
        />
      </div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-lg-row flex-column justify-content-start ml-3">
          <p class="m-0 font-weight-bold font-21 font-md-15 font-sm-12 truncated-single-line" [ngClass]="isTablet ? 'w-100' : 'w-auto'">
            {{ groupUpdate.createdByName }}
            <span *ngIf="!isTablet"> &nbsp; </span>
          </p>
          <span *ngIf="!isTablet" class="font-weight-bold font-21 font-md-15 font-sm-12">|</span>
          <p class="m-0 ternary-text font-21 font-md-15 font-sm-12 truncated-single-line" [ngClass]="isTablet ? 'w-100' : 'w-50'">
            <span *ngIf="!isTablet"> &nbsp; </span>
            {{ groupUpdate.createdByTitle }}
          </p>
        </div>
        <div>
          <p class="ml-3 font-15 font-md-12 font-sm-9">
            {{ 'GROUPS.GROUP_UPDATES.LABELS.UPDATE_POST_TIME' | translate: { dateTime: groupUpdate.uploadTimestamp | date: dateFormat } }}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="updateList && isAdmin">
      <button
        class="members-options mt-2 p-0 d-flex align-items-center justify-content-center"
        mat-flat-button
        disableRipple
        [ngClass]="{ active: trigger.menuOpen }"
        [matMenuTriggerFor]="groupProjectOptions"
        #trigger="matMenuTrigger"
      >
        <img [src]="trigger.menuOpen ? 'assets/icons/dots-white.svg' : 'assets/icons/dots-black.svg'" />
      </button>
      <mat-menu #groupProjectOptions="matMenu" xPosition="before">
        <button mat-menu-item (click)="editUpdateEmit.emit(groupUpdate.uuid)">
          <i class="fas fa-edit mb-1 mr-2"></i>
          {{ 'GROUPS.GROUP_UPDATES.BUTTONS.EDIT_ANNOUNCEMENT' | translate }}
        </button>
        <button mat-menu-item (click)="deleteUpdateEmit.emit(groupUpdate.uuid)">
          <i class="far fa-trash-alt mb-1 mr-2"></i>
          {{ 'GROUPS.GROUP_UPDATES.BUTTONS.DELETE_ANNOUNCEMENT' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-card-content class="mt-4">
    <p class="font-weight-bold font-24 font-md-18 font-sm-15">{{ groupUpdate.title }}</p>
    <p #content [ngClass]="!showMoreContent ? 'truncated-3-lines' : 'all-text'" [innerHTML]="groupUpdate.announcement"></p>
    <a *ngIf="isMoreContent" class="pointer font-weight-bold link-color" (click)="readMoreOrLess()"
      >{{ (showMoreContent ? 'GENERAL.GENERIC_BUTTONS.READ_LESS' : 'GENERAL.GENERIC_BUTTONS.READ_MORE') | translate }}...</a
    >
    <p *ngIf="groupUpdate.documents.length" class="font-weight-bold font-16">
      <br />{{ 'GROUPS.GROUP_UPDATES.LABELS.DOCUMENTS_OF_THIS_ANNOUNCEMENT' | translate }}
    </p>

    <div class="d-flex flex-column mb-3">
      <ng-container *ngFor="let document of groupUpdate.documents">
        <div class="d-flex w-100 details-doc mx-2 align-items-end">
          <img class="mr-2 mb-1 pointer" src="assets/icons/Download_Icon.svg" (click)="previewDocument(document)" />
          <p class="pointer font-weight-bold pointer text-blue truncated-single-line m-0" (click)="previewDocument(document)">
            {{ document.filename }}
          </p>
        </div>
      </ng-container>
    </div>

    <img
      *ngIf="groupUpdate.coverImage.url"
      class="w-100"
      [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/image-placeholder.png'"
      [lazyLoad]="groupUpdate.coverImage.url"
    />
  </mat-card-content>
</mat-card>
