import { style, animate, AnimationMetadata, AnimationPlayer, AnimationBuilder } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, skip } from 'rxjs';
import { throttleTime, map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';

@Directive({
  selector: '[appStickyElement]',
})
export class StickyHeaderDirective implements OnInit {
  private player: AnimationPlayer;

  // prettier-ignore
  constructor(
    private el: ElementRef,
    private builder: AnimationBuilder,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => (event instanceof NavigationEnd ? this.show(true, false) : null));

    const scroll$ = fromEvent(this.document.defaultView, 'scroll').pipe(
      throttleTime(10),
      map(() => this.document.defaultView.scrollY),
      pairwise(),
      map(([y1, y2]): Direction => (this.isScrolledOutsideViewport() ? (y2 < y1 ? Direction.Up : Direction.Down) : Direction.Up)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      skip(1),
      filter((direction) => direction === Direction.Up)
    );

    const scrollDown$ = scroll$.pipe(filter((direction) => direction === Direction.Down));

    scrollUp$.subscribe(() => this.show(true));
    scrollDown$.subscribe(() => this.show(false));
  }

  private show(show: boolean, animation: boolean = true) {
    if (this.player) {
      this.player.destroy();
    }

    const metadata = show ? this.slideIn(animation) : this.slideOut(animation);

    const factory = this.builder.build(metadata);
    const player = factory.create(this.el.nativeElement);

    player.play();
  }

  private slideIn(animation: boolean): AnimationMetadata[] {
    return animation
      ? [style({ opacity: 0, transform: 'translateY(-100%)' }), animate('300ms ease-in', style({ opacity: 1, transform: 'translateY(0)' }))]
      : [style({ opacity: 1, transform: 'translateY(0)' })];
  }

  private slideOut(animation: boolean): AnimationMetadata[] {
    return animation
      ? [style({ opacity: 1, transform: 'translateY(0)' }), animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-100%)' }))]
      : [style({ opacity: 0, transform: 'translateY(-100%)' })];
  }

  private isScrolledOutsideViewport() {
    return this.document.documentElement.scrollTop >= (this.document.defaultView.innerHeight || this.document.documentElement.clientHeight);
  }
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
}
