<div class="action-btns flex-all-center">
  <button
    id="previewButton"
    type="submit"
    mat-flat-button
    [disableRipple]="true"
    class="mat-main-button blue-background white-text mr-2 lh-30"
    [hidden]="hidePreview"
    (click)="buttonClicked.emit(buttonTypes.PREVIEW)"
  >
    {{ 'GENERAL.GENERIC_BUTTONS.PREVIEW' | translate }}
  </button>
  <button
    id="saveChangesButton"
    type="submit"
    [hidden]="hideSave"
    class="mat-main-button mr-2 lh-30"
    mat-flat-button
    color="accent"
    [disableRipple]="true"
    (click)="buttonClicked.emit(buttonTypes.SAVE)"
  >
    {{ 'GENERAL.GENERIC_BUTTONS.' + saveButtonText | translate }}
  </button>
  <button
    id="submitButton"
    type="submit"
    class="mat-main-button mr-2 lh-30"
    mat-flat-button
    color="primary"
    [disableRipple]="true"
    [disabled]="disableSubmit"
    (click)="buttonClicked.emit(buttonTypes.SUBMIT)"
  >
    {{
      entityStatus && entityStatus !== entityStatuses.DRAFT
        ? ('GENERAL.GENERIC_BUTTONS.RESUBMIT' | translate)
        : ('GENERAL.GENERIC_BUTTONS.SUBMIT' | translate)
    }}
  </button>
  <button
    id="cancelButton"
    type="submit"
    class="mat-outline-button gray-border lh-30"
    mat-stroked-button
    color="ternary"
    [disableRipple]="true"
    (click)="buttonClicked.emit(buttonTypes.CANCEL)"
  >
    {{ (changesMade ? 'GENERAL.GENERIC_BUTTONS.CANCEL' : 'GENERAL.GENERIC_BUTTONS.BACK') | translate }}
  </button>
</div>
