<div class="admin-input d-flex flex-column mb-2">
  <label *ngIf="label" class="label">{{ label | translate }} <span class="primary-color" *ngIf="required">*</span></label>
  <mat-form-field appearance="outline" class="pr-0">
    <input matInput [formControl]="control" (focus)="searchResults = []" [matAutocomplete]="auto" [required]="required" />
    <mat-icon *ngIf="suffixIcon" matSuffix class="icon ml-2" [ngClass]="canSearch ? 'pointer' : 'text-secondary'" (click)="emitSearch($event)">
      {{ suffixIcon }}
    </mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="emitOptionSelected($event)" (closed)="searchResults = []">
      <mat-option *ngFor="let item of searchResults" [value]="item">{{ item.name }}</mat-option>
    </mat-autocomplete>
    <mat-error *ngFor="let err of errors">
      <mat-error class="error" *ngIf="control.hasError(err.type)">
        {{ err.text | translate }}
      </mat-error>
    </mat-error>
  </mat-form-field>
</div>
