<share-buttons
  theme="circles-dark"
  [include]="includedSocialMedia"
  [showIcon]="true"
  [showText]="false"
  [url]="shareLink"
  [title]="linkTitle"
  [description]="linkTitle"
>
</share-buttons>
