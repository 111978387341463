<div
  #searchBar
  class="wrapper d-flex flex-column bg-white h-100"
  [class.closed]="!opened"
  [class.inactive]="opened && !active"
  [class.global]="globalSearch"
  (mouseover)="toggleActive(true)"
  (mouseleave)="toggleActive(false)"
>
  <div class="bar d-flex align-items-center my-6px w-100" [ngClass]="opened ? 'justify-content-between' : 'justify-content-end'">
    <input
      #searchInput
      *ngIf="opened"
      type="text"
      matInput
      (keydown.enter)="onSearchButtonClicked()"
      (ngModelChange)="onInput.emit($event)"
      [(ngModel)]="inputValue"
      (focus)="onFocus()"
      (focusout)="onBlur()"
      [placeholder]="'SHARED.COMPONENTS.SEARCH_BAR.' + placeholder | translate"
    />
    <div class="d-flex">
      <i #clearButton [hidden]="!(opened && inputValue)" class="fas fa-times icon" (click)="clear()"></i>
      <i *ngIf="opened && inputValue" class="fas fa-slash"></i>
      <i class="fas fa-search icon" (click)="onSearchButtonClicked()"></i>
    </div>
  </div>
  <div class="suggestions" [ngClass]="{ 'pb-3': inputValue }" *ngIf="globalSearch && inputFocused">
    <hr class="mt-0 mb-1" *ngIf="inputValue" />
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="globalSearch && inputFocused" class="backdrop"></div>
