import { FormControl, FormControlOptions, FormControlState, ValidatorFn } from '@angular/forms';
import { ControlError, GSControl, Media } from '../FormControls';
import { EntityProfile } from '../EntityProfile';

function processControlValue(gsControl: GSControl, prefillValue): any {
  let value;
  if (gsControl.value instanceof Media) {
    value = gsControl.value.clone();
    (value as Media).prefillFields(prefillValue);
  } else {
    value = prefillValue !== undefined ? prefillValue : prefillValue || gsControl.value;
  }
  return { value, disabled: gsControl.disabled };
}

export class GSFormControl<T = any> extends FormControl<T> {
  label: string;
  placeholder: string;
  max: number;
  min: number;
  controlErrors: ControlError[];
  key: string;
  defaultValidators: ValidatorFn[];
  optionalText: string;
  profile: EntityProfile;

  constructor(gsControl: GSControl, prefillValue: any) {
    const formControlOptions: FormControlOptions = {
      initialValueIsDefault: true,
      validators: gsControl.validators,
      asyncValidators: gsControl.asyncValidators,
    };
    super(processControlValue(gsControl, prefillValue) as T, formControlOptions);
    this.key = gsControl.key;
    this.label = gsControl.label;
    this.placeholder = gsControl.placeholder;
    this.max = gsControl.max;
    this.min = gsControl.min;
    this.controlErrors = gsControl.errors;
    this.defaultValidators = gsControl.validators;
    this.optionalText = gsControl.optionalText;
    this.profile = gsControl.profile;
  }

  override reset(formState?: T | FormControlState<T>, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    if (this.value instanceof Media) {
      super.patchValue(this.value.clone() as any);
      super.markAsDirty();
      return;
    }
    super.reset(formState, options);
  }

  override patchValue(
    value: T,
    options?: { onlySelf?: boolean; emitEvent?: boolean; emitModelToViewChange?: boolean; emitViewToModelChange?: boolean }
  ): void {
    if (this.value instanceof Media) {
      super.patchValue((this.value as unknown as Media).clone() as any);
      this.value.setFieldsAfterUpload(value as unknown as Media);
      super.updateValueAndValidity();
      super.markAsDirty();
      return;
    }
    super.patchValue(value, options);
  }

  override clearValidators(updateValueAndValidity: boolean = false, opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.clearValidators();
    if (updateValueAndValidity) {
      super.updateValueAndValidity(opts);
    }
  }

  removeError(errorKey: string): void {
    if (this.errors && this.errors.hasOwnProperty(errorKey)) {
      delete this.errors.errorKey;
      super.updateValueAndValidity();
    }
  }

  restoreValidators(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.clearValidators();
    super.addValidators(this.defaultValidators);
    super.updateValueAndValidity(options);
  }
}
