export enum AddressComponentTypes {
  COUNTRY = 'country',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  SUBLOCALITY_LEVEL_1 = 'sublocality_level_1',
  LOCALITY = 'locality',
  POSTAL_TOWN = 'postal_town',
  ROUTE = 'route',
  STREET_NUMBER = 'street_number',
  POSTAL_CODE = 'postal_code',
}
