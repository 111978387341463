import { ENTITY_TYPE, EntityStatus } from '../../constants/CompanyOrFirm';
import Utils from '../../helpers/Utils';
import { ToggleLive } from '../CompaniesOrFirms';
import { CustomAddress } from '../CustomAddress';
import { EntityChampionDto } from '../Details';
import { EntityProfile } from '../EntityProfile';
import { DocumentMedia, LanguageResponseFields, Media } from '../FormControls';
import { CatalogEntityBadge, EntityDocument, EntityImage, EntityMainInfo, NavigationData, UUIDName } from '../GenericObject';
import { Language } from '../global-settings/Language';
import { OrganizationTeamMember } from '../organisation/Organization';
import { AdminProjectCardDto, ProjectProfileFieldsDto } from './ProjectDto';
import { Champion } from '../../../internal-app/details/champion/models/Champion';
import Urls from '../../constants/Urls';

export class ProjectStatusInfo extends EntityMainInfo {
  timeToBePublished: Date;
  visible: boolean;

  constructor(projectData: any) {
    super(projectData.projectUuid, projectData.projectName, projectData.projectStatus, projectData.changeStatusReason);
    this.timeToBePublished = Utils.UTCtoLocalNoOffset(projectData.scheduledToBePublished);
    this.visible = projectData.visible;
  }

  setProjectStatus(newStatusData: ToggleLive): void {
    super.setEntityStatus(newStatusData.entityStatus);
    this.timeToBePublished = Utils.UTCtoLocalNoOffset(newStatusData.time) || null;
    this.visible = newStatusData.entityStatus === EntityStatus.LIVE;
    this.uuid = newStatusData.uuid;
  }
}

export class AdminProjectCard extends EntityMainInfo {
  projectsListPageLink: NavigationData;
  coverImageUrl: string | null;
  changeStatusReason: string;
  entityRouteLink: NavigationData;
  previewLink: NavigationData;
  organisationDetailsLink: NavigationData;
  previewToolTip: string;
  deleteToolTip: string;
  entityType: ENTITY_TYPE;
  scheduledToBePublished: number;
  organisationName: string;
  availableNewLanguages: Language[];
  languages: Language[];
  organizationLanguages: Language[];
  organisationUuid: string;
  updateProject: NavigationData;

  constructor(adminProjectCardDto: AdminProjectCardDto) {
    super(adminProjectCardDto.uuid, adminProjectCardDto.name, adminProjectCardDto.entityStatus);
    this.coverImageUrl = adminProjectCardDto.entityImageUrl;
    this.previewToolTip = 'ADMIN.PROJECTS_LIST.TABS.PREVIEW_PROJECT';
    this.deleteToolTip = 'ADMIN.PROJECTS_LIST.TABS.DELETE_PROJECT';
    this.entityType = ENTITY_TYPE.PROJECT;
    this.scheduledToBePublished = 1;
    this.organisationName = adminProjectCardDto.organisationName;
    this.organisationUuid = adminProjectCardDto.organisationUuid;
    this.entityRouteLink = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${Urls.PROJECT_ADMIN}/${Urls.UPDATE_PROJECT}/${adminProjectCardDto.uuid}`,
      {
        language: adminProjectCardDto.language.code,
        primary: true,
      }
    );
    this.previewLink = new NavigationData(`/${Urls.APP}/${Urls.DETAILS}/${Urls.PROJECT}/` + adminProjectCardDto.uuid + '/summary', {
      preview: true,
    });
    this.organisationDetailsLink = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.ORGANIZATIONS}/` + adminProjectCardDto.organisationUuid);
    this.languages = Utils.sortArray(adminProjectCardDto.projLanguages, 'primaryLanguage', true, 'name');
    this.organizationLanguages = Utils.sortArray(adminProjectCardDto.orgLanguages, 'primaryLanguage', true, 'name');
    this.setSelectedLanguage(adminProjectCardDto.language);
  }

  setAvailableLanguages(allLanguages: Language[]): void {
    this.availableNewLanguages = allLanguages.filter((language) => !this.languages.find((usedLanguage) => usedLanguage.uuid === language.uuid));
  }

  setUpdateProject(language: Language, projectUuid: string): void {
    this.updateProject = new NavigationData(
      `/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${this.organisationUuid}/${Urls.UPDATE_PROJECT}/${projectUuid}`,
      {
        language: language.code,
        primary: language.primaryLanguage,
      }
    );
  }
}

export class AdminOrganizationProjectCard extends ProjectStatusInfo {
  amountRaisedToDate: number;
  amountRaisedCurrency: string;
  funded: boolean;
  totalBudget: number;
  totalBudgetCurrency: string;
  lastModified: number;
  creationDate: number;
  createdBy: string;
  updatedBy: string;
  number: string;
  score: number;
  manager: ProjectManager;
  startDate: number;
  scheduledToBePublished: number;
  languages: Language[];
  availableNewLanguages: Language[];

  constructor(projectCardInfo: any) {
    super(projectCardInfo);
    this.amountRaisedToDate = projectCardInfo.amountRaisedToDate;
    this.amountRaisedCurrency = projectCardInfo.amountRaisedToDateCurrency;
    this.funded = projectCardInfo.funded;
    this.totalBudget = projectCardInfo.totalProjectBudget;
    this.totalBudgetCurrency = projectCardInfo.totalProjectBudgetCurrency;
    this.lastModified = projectCardInfo.lastModified;
    this.creationDate = projectCardInfo.creationDate;
    this.createdBy = projectCardInfo.createdBy;
    this.updatedBy = projectCardInfo.updatedBy;
    this.number = projectCardInfo.projectNumber;
    this.score = projectCardInfo.projectScore;
    this.manager = this.setProjectManager(projectCardInfo);
    this.startDate = projectCardInfo.startDate;
    this.scheduledToBePublished = projectCardInfo.scheduledToBePublished;
    this.languages = Utils.sortArray(projectCardInfo.languages, 'primaryLanguage', true, 'name');
    this.setSelectedLanguage(this.languages.find((lang) => lang.primaryLanguage));
  }

  setProjectManager(projectCardInfo): ProjectManager {
    if (projectCardInfo.projectManagers?.length) {
      return {
        fullName: `${projectCardInfo.projectManagers[0].firstName} ${projectCardInfo.projectManagers[0].lastName}`,
        email: projectCardInfo.projectManagers[0].email,
      };
    } else if (projectCardInfo.projectManager) {
      return {
        fullName: projectCardInfo.projectManager.name,
        uuid: projectCardInfo.projectManager.uuid,
      };
    }
    return null;
  }

  setAvailableLanguages(allLanguages: Language[]): void {
    this.availableNewLanguages = allLanguages.filter((language) => !this.languages.find((usedLanguage) => usedLanguage.uuid === language.uuid));
  }
}

export class Project extends AdminOrganizationProjectCard {
  mandatoryProfile: UUIDName;
  mandatoryOrganization: UUIDName;
  organisationUuid: string;
  organisationName: string;
  activities: string;
  categoriesList: string[];
  champions: Champion[];
  countriesList: string[];
  cause: UUIDName;
  goalsList: ProjectGoal[];
  problem: string;
  solution: string;
  immediateResults: string;
  longTermImpact: string;
  coverImage: Media;
  description: string;
  translationsData: LanguageResponseFields;
  financialSustainability: string;
  totalProjectBudget: number;
  otherSocialMediaLink: string;
  givingLink: string;
  potentialForGrowth: string;
  imagesList: EntityImage[];
  partnerOrganisationsList: ProjectPartner[];
  video: Media;
  resultsMeasurement: string;
  shortTermOutcomes: string;
  socialMediaFacebook: string;
  socialMediaInstagram: string;
  socialMediaLinkedin: string;
  socialMediaTwitter: string;
  website: string;
  endDate: number;
  targetPopulation: string;
  teamMembersList: ProjectTeamMember[];
  teamSummary: string;
  teamMediaVideo: Media;
  teamMediaVideoThumbnail: Media;
  teamMediaPicture: Media;
  documents: EntityDocument[];

  constructor(projectInfo: any) {
    super(projectInfo);
    this.mandatoryProfile = projectInfo.mandatoryProfile;
    this.mandatoryOrganization = new UUIDName(projectInfo.organisationUuid, projectInfo.organisationName);
    this.organisationUuid = projectInfo.organisationUuid;
    this.organisationName = projectInfo.organisationName;
    this.activities = projectInfo.activities;
    this.countriesList = projectInfo.countriesList;
    this.categoriesList = projectInfo.categories;
    this.cause = projectInfo.cause;
    this.champions = projectInfo.champions.map((champion) => new Champion(champion));
    this.goalsList = projectInfo.goalsList;
    this.longTermImpact = projectInfo.longTermImpact;
    this.immediateResults = projectInfo.immediateResults;
    this.solution = projectInfo.solution;
    this.problem = projectInfo.problem;
    this.coverImage = projectInfo.coverImage;
    this.description = projectInfo.description;
    this.translationsData = projectInfo.languages;
    this.financialSustainability = projectInfo.financialSustainability;
    this.potentialForGrowth = projectInfo.potentialForGrowth;
    this.givingLink = projectInfo.givingLink;
    this.partnerOrganisationsList = projectInfo.partnerOrganisationsList;
    this.resultsMeasurement = projectInfo.resultsMeasurement;
    this.shortTermOutcomes = projectInfo.shortTermOutcomes;
    this.video = projectInfo.projectVideo;
    this.imagesList = projectInfo.projectImages;
    this.socialMediaFacebook = projectInfo.socialMediaFacebook;
    this.socialMediaInstagram = projectInfo.socialMediaInstagram;
    this.socialMediaLinkedin = projectInfo.socialMediaLinkedin;
    this.socialMediaTwitter = projectInfo.socialMediaTwitter;
    this.otherSocialMediaLink = projectInfo.otherSocialMediaLink;
    this.website = projectInfo.website;
    this.startDate = projectInfo.startDate;
    this.endDate = projectInfo.endDate;
    this.targetPopulation = projectInfo.targetPopulation;
    this.teamMembersList = projectInfo.teamMembersList;
    this.teamSummary = projectInfo.teamSummary;
    this.teamMediaVideo = projectInfo.projectTeamMediaVideo;
    this.teamMediaVideoThumbnail = projectInfo.projectTeamMediaThumbnailVideo;
    this.teamMediaPicture = projectInfo.projectTeamMediaPicture;
    this.documents = projectInfo.projectDocuments;
  }
}

export class ProjectProfileFields {
  name: EntityProfile;
  number: EntityProfile;
  cause: EntityProfile;
  categoriesList: EntityProfile;
  countriesList: EntityProfile;
  website: EntityProfile;
  socialMediaFacebook: EntityProfile;
  socialMediaInstagram: EntityProfile;
  socialMediaLinkedin: EntityProfile;
  socialMediaTwitter: EntityProfile;
  otherSocialMediaLink: EntityProfile;
  targetPopulation: EntityProfile;
  startDate: EntityProfile;
  endDate: EntityProfile;
  totalBudgetCurrency: EntityProfile;
  totalBudget: EntityProfile;
  amountRaisedToDate: EntityProfile;
  amountRaisedCurrency: EntityProfile;
  givingLink: EntityProfile;
  description: EntityProfile;
  problem: EntityProfile;
  solution: EntityProfile;
  video: EntityProfile;
  imagesList: EntityProfile;
  activities: EntityProfile;
  teamSummary: EntityProfile;
  manager: EntityProfile;
  teamMediaVideo: EntityProfile;
  teamMediaVideoThumbnail: EntityProfile;
  teamMediaPicture: EntityProfile;
  teamMembersList: EntityProfile;
  partnerOrganisationsList: EntityProfile;
  immediateResults: EntityProfile;
  shortTermOutcomes: EntityProfile;
  longTermImpact: EntityProfile;
  goalsList: EntityProfile;
  resultsMeasurement: EntityProfile;
  financialSustainability: EntityProfile;
  potentialForGrowth: EntityProfile;
  documents: EntityProfile;

  constructor(fieldsInfo: ProjectProfileFieldsDto) {
    this.name = fieldsInfo.projectName;
    this.number = fieldsInfo.projectNumber;
    this.activities = fieldsInfo.activities;
    this.countriesList = fieldsInfo.countriesList;
    this.categoriesList = fieldsInfo.categories;
    this.cause = fieldsInfo.cause;
    this.goalsList = fieldsInfo.goalsList;
    this.longTermImpact = fieldsInfo.longTermImpact;
    this.immediateResults = fieldsInfo.immediateResults;
    this.solution = fieldsInfo.solution;
    this.problem = fieldsInfo.problem;
    this.description = fieldsInfo.description;
    this.financialSustainability = fieldsInfo.financialSustainability;
    this.potentialForGrowth = fieldsInfo.potentialForGrowth;
    this.givingLink = fieldsInfo.givingLink;
    this.partnerOrganisationsList = fieldsInfo.partnerOrganisationsList;
    this.resultsMeasurement = fieldsInfo.resultsMeasurement;
    this.shortTermOutcomes = fieldsInfo.shortTermOutcomes;
    this.video = fieldsInfo.projectVideo;
    this.imagesList = fieldsInfo.projectImages;
    this.socialMediaFacebook = fieldsInfo.socialMediaFacebook;
    this.socialMediaInstagram = fieldsInfo.socialMediaInstagram;
    this.socialMediaLinkedin = fieldsInfo.socialMediaLinkedin;
    this.socialMediaTwitter = fieldsInfo.socialMediaTwitter;
    this.otherSocialMediaLink = fieldsInfo.otherSocialMediaLink;
    this.website = fieldsInfo.website;
    this.startDate = fieldsInfo.startDate;
    this.endDate = fieldsInfo.endDate;
    this.totalBudgetCurrency = fieldsInfo.totalProjectBudgetCurrency;
    this.totalBudget = fieldsInfo.totalProjectBudget;
    this.amountRaisedToDate = fieldsInfo.amountRaisedToDate;
    this.amountRaisedCurrency = fieldsInfo.amountRaisedToDateCurrency;
    this.targetPopulation = fieldsInfo.targetPopulation;
    this.teamMembersList = fieldsInfo.teamMembersList;
    this.teamSummary = fieldsInfo.teamSummary;
    this.teamMediaVideo = fieldsInfo.projectTeamMediaVideo;
    this.teamMediaVideoThumbnail = fieldsInfo.projectTeamMediaThumbnailVideo;
    this.teamMediaPicture = fieldsInfo.projectTeamMediaPicture;
    this.manager = fieldsInfo.projectManager;
    this.documents = fieldsInfo.projectDocuments;
  }
}

export interface ProjectDetails {
  activities: string;
  addedToWatchlist: boolean;
  address: CustomAddress;
  amountRaisedToDate: number;
  amountRaisedToDateCurrencySymbol: string;
  boardMembers: OrganizationTeamMember[];
  projectCoverImageDto: Media;
  description: string;
  endDate: number;
  executiveSummary: string;
  executiveTeams: OrganizationTeamMember[];
  financialSustainability: string;
  history: string;
  immediateResults: string;
  longTermImpact: string;
  missionStatement: string;
  organisationCauseName: string;
  organisationLeader: OrganizationTeamMember;
  organisationName: string;
  organisationLogoDto: Media;
  organisationUuid: string;
  organisationWebsite: string;
  partnerOrganisation: ProjectPartner[];
  potentialForGrowth: string;
  problem: string;
  programAndActivities: string;
  projectCategoriesNames: string[];
  projectCauseName: string;
  projectCountriesNames: string[];
  projectDiligenceReport: Media;
  projectDocuments: DocumentMedia[];
  projectGivingLink: string;
  projectGoals: ProjectGoal[];
  projectImagesDto: Media[];
  projectLanguages: Language[];
  projectManagers: OrganizationTeamMember[];
  projectName: string;
  projectNumber: string;
  projectOtherSocialMediaLink: string;
  projectPartnerOrganisation: ProjectPartner[];
  projectSocialMediaFacebook: string;
  projectSocialMediaInstagram: string;
  projectSocialMediaLinkedin: string;
  projectSocialMediaTwitter: string;
  projectTargetPopulation: string;
  projectTeamMediaPicture: Media;
  projectTeamMediaVideo: Media;
  projectTeamMediaThumbnailVideo: Media;
  projectThumbnailChampionVideo: Media;
  projectUuid: string;
  projectVideoDto: Media;
  resultsMeasurement: string;
  shortTermOutcomes: string;
  solution: string;
  startDate: number;
  teamMembers: OrganizationTeamMember[];
  teamSummary: string;
  totalProjectBudget: number;
  totalProjectBudgetCurrencySymbol: string;
  totalOperatingBudget: number;
  totalOperatingBudgetSymbol: string;
  visionStatement: string;
  website: string;
  yearFounded: number;
  champions: EntityChampionDto[];
  badges: CatalogEntityBadge[];
}

export interface ProjectGoal {
  name: string;
  number: number;
  objective: string;
  uuid: string;
}

export interface ProjectPartner extends UUIDName {
  relationshipDescription: string;
}

export interface ProjectTeamMember {
  bio: string;
  fullName: string;
  linkedinProfile: string;
  position: number;
  creationDate: number;
  profilePicture: Media;
  uuid: string;
}

export interface ProjectManager {
  email?: string;
  fullName: string;
  uuid?: string;
}

export class ProjectAdminRoutes {
  newProject: NavigationData;
  projectId: string;

  constructor(language: Language) {
    this.newProject = new NavigationData(`/${Urls.APP}/${Urls.SETTINGS}/${Urls.SETUP}/${Urls.PROJECT}/${Urls.PROJECT_ADMIN}/${Urls.NEW_PROJECT}`, {
      language: language.code,
      primary: true,
    });
  }
}
