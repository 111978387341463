<div class="modal-container">
  <div class="my-modal-header">{{ message | translate }}</div>
  <div *ngIf="messageInfo" class="messageInfo mt-2">{{ messageInfo | translate }}</div>
  <div class="d-flex justify-content-between button-container w-100">
    <button
      mat-stroked-button
      id="cancel-modal"
      (click)="emitFalse()"
      [color]="cancelButtonColor"
      class="mat-outline-button"
      [ngClass]="cancelButtonColor === 'warn' ? 'warn-border' : 'gray-border'"
    >
      {{ 'GENERAL.GENERIC_BUTTONS.' + (cancelActionButton ? cancelActionButton : 'CANCEL') | translate }}
    </button>
    <button
      mat-stroked-button
      id="confirm-modal"
      (click)="emitTrue()"
      [color]="confirmButtonColor"
      class="mat-outline-button"
      [ngClass]="confirmButtonColor === 'warn' ? 'warn-border' : 'accent-border'"
    >
      {{ 'GENERAL.GENERIC_BUTTONS.' + (confirmActionButton ? confirmActionButton : 'DELETE') | translate }}
    </button>
  </div>
</div>
