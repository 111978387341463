<div class="d-flex align-items-center h-100">
  <button class="font-weight-normal no-outline" mat-button [matMenuTriggerFor]="sortBy" [disableRipple]="true">
    <img src="assets/icons/icon_SortBy.svg" /> <span *ngIf="showText">{{ 'SHARED.COMPONENTS.SORT_BY.SORT_BY' | translate }} </span>
  </button>
</div>
<mat-menu class="custom_menu" #sortBy="matMenu" xPosition="before">
  <mat-option (click)="selectOption(sort, true); $event.stopPropagation()" class="sortby-option" *ngFor="let sort of sortByOptions">
    <div class="d-flex justify-content-center align-items-center">
      <label>{{ 'GENERAL.SORT_BY.' + sort.field | translate }}</label>
      <span class="ml-5 float-right">
        <button class="sortby-option-btn">
          <span class="arrows material-icons" [class.active-arrow]="sort.checked">
            {{ sort.order === 'DESC' ? 'arrow_upward' : 'arrow_downward' }}</span
          >
        </button>
      </span>
    </div>
  </mat-option>
</mat-menu>
