import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../../_core/services/loader.service';
import { Subscription } from 'rxjs';
import { LoaderData } from 'src/app/_core/models/Loader';
import { LOADER_TYPE } from 'src/app/_core/constants/Global/Loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() isAuth: boolean = false;

  loading: boolean;
  loadingSub: Subscription;

  // prettier-ignore
  constructor(
    private loaderService: LoaderService,
  ) {
  }

  ngOnInit() {
    this.loadingSub = this.loaderService.loading$.subscribe((loaderData: LoaderData) => {
      if (loaderData.type === LOADER_TYPE.GLOBAL) {
        this.loading = loaderData.visible;
      }
    });
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
