import Urls from '../Urls';

export const EntityDetailsRoutes = [`/${Urls.DETAILS}/${Urls.ORGANISATION}`, `/${Urls.DETAILS}/${Urls.PROJECT}`];

export const EntityDetailsTabRoutes = [
  // `/${Urls.SUMMARY_TAB}`, We excluded summary tab as this is the default tab in the details page
  `/${Urls.TEAM_TAB}`,
  `/${Urls.FINANCIALS_TAB}`,
  `/${Urls.PROJECTS_TAB}`,
  `/${Urls.CHAMPION_TAB}`,
  `/${Urls.DOCUMENTS_TAB}`,
  `/${Urls.GOALS_TAB}`,
  `/${Urls.ORGANIZATION_TAB}`,
  `/${Urls.UPDATE_TAB}`,
  `/${Urls.QUESTIONS_TAB}`,
];
