import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import StorageHelper from '../helpers/Storage.helper';
import { GlobalLoaderRequests, LOADER_TYPE, NoLoaderRequests } from '../constants/Global/Loader';
import { LoaderService } from '../services/loader.service';
import { KeycloakService } from '../api/keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakTokenInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage, private keycloakService: KeycloakService, private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const noLoaderRequest = !!NoLoaderRequests.find((reqName) => req.url.indexOf(reqName) >= 0);
    const globalLoaderRequest = !!GlobalLoaderRequests.find((reqName) => req.url.indexOf(reqName) >= 0);
    let loader: LOADER_TYPE;
    if (!noLoaderRequest) {
      loader = globalLoaderRequest ? LOADER_TYPE.GLOBAL : LOADER_TYPE.LOCAL;
      this.loaderService.show(loader);
    }

    const token = this.authStorage.getItem('access_token');
    const isKeycloak = req.url.indexOf(environment.keycloakRealm) >= 0;

    let headers: HttpHeaders = req.headers;
    if (!isKeycloak) {
      headers = req.headers
        .set('Access-Control-Allow-Origin', '*')
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('tenantId', environment.tenantId)
        .set('language', StorageHelper.getSelectedLanguage()?.code || 'eng');
    }

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
      if (!isKeycloak) {
        headers = headers.set('Content-Type', 'application/json');
      }
    }

    req = req.clone({ headers });

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide(loader);
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const throwErr = (): Observable<any> => {
            const err = error.error.message || error.error.error;
            return throwError(err);
          };
          this.loaderService.hide(loader);
          switch (error.status) {
            case 401:
              StorageHelper.clearAll();
              this.keycloakService.login();
              return of(null);
            default:
              return throwErr();
          }
        } else {
          return of(null);
        }
      })
    );
  }
}
