import { FileItem } from 'ng2-file-upload';
import { FileType } from '../constants/FileType';

export interface UploadResponse {
  upload: Upload;
}

export interface Upload {
  uploadUuid?: string;
  uploadUrl?: string;
  teamMemberUuid?: string;
  entrepreneurCEOUuid?: string;
  uuid?: string;
  objectUuid?: string;
  url?: string;
  type?: string;
}

export class UploadPromiseResponse {
  constructor(public fileItem: FileItem, public response: any) {}
}

export interface BannerInputs {
  aspectRatio: number;
  fileType: FileType;
  target: string;
}
