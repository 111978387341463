<div class="admin-input">
  <div class="label">
    {{ label | translate }}
    <span class="optional">({{ allowedFileTypes }})</span>
  </div>
  <div class="small-text text-gray" *ngIf="placeholder">{{ placeholder | translate }}</div>
  <button
    class="mt-2"
    [id]="buttonId"
    (click)="cropFormat ? openCropModal() : uploadInput.click()"
    type="button"
    mat-stroked-button
    [hidden]="hideButton"
  >
    <i class="fas fa-images"></i>
    {{ 'GENERAL.GENERIC_BUTTONS.ADD_FILE' | translate }}
  </button>

  <input [id]="buttonId + 'Input'" #uploadInput ng2FileSelect hidden [uploader]="uploader" type="file" (change)="imageOrVideoSelected()" />
</div>

<!-- Video -->
<ng-container *ngIf="videoControl">
  <app-upload-progress-bar [pending]="videoControl.value.pendingUpload" [progress]="videoControl.value.progress"></app-upload-progress-bar>
  <app-media-info *ngIf="videoControl.value.fileName" [media]="videoControl.value" (deleteFile)="deleteFile(videoControl); uploadInput.value = ''">
  </app-media-info>
  <ng-container *ngIf="videoControl.invalid && (videoControl.dirty || videoControl.touched)">
    <mat-error *ngFor="let err of videoControl.controlErrors">
      <mat-error class="mt-1" *ngIf="videoControl.hasError(err.type)">
        {{ err.text | translate }}
      </mat-error>
    </mat-error>
  </ng-container>
</ng-container>

<!-- Image -->
<ng-container *ngIf="imageControl">
  <app-upload-progress-bar [pending]="imageControl.value.pendingUpload" [progress]="imageControl.value.progress"></app-upload-progress-bar>
  <app-media-info *ngIf="imageControl.value.fileName" [media]="imageControl.value" (deleteFile)="deleteFile(imageControl); uploadInput.value = ''">
  </app-media-info>
  <ng-container *ngIf="imageControl.invalid && (imageControl.dirty || imageControl.touched)">
    <mat-error *ngFor="let err of imageControl.controlErrors">
      <mat-error class="mt-1" *ngIf="imageControl.hasError(err.type)">
        {{ err.text | translate }}
      </mat-error>
    </mat-error>
  </ng-container>
</ng-container>
