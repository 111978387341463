<div class="d-flex justify-content-end progress-indicators">
  <app-currency [amount]="totalAmount" [currency]="currency"></app-currency>
</div>

<div class="position-relative">
  <div class="progress">
    <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    <span class="progress-label" [style.left]="progress < 80 ? progress + '%' : progress - 19 + '%'">
      <app-currency [amount]="amountRaised" [currency]="currency"></app-currency>
    </span>
  </div>
</div>
