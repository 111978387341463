import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../_core/helpers/Utils';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() totalAmount: number;
  @Input() amountNeeded: number;
  @Input() currency: string;

  amountRaised: number;
  progress: number;
  start = '0';

  constructor() {}

  ngOnInit(): void {
    this.amountRaised = this.totalAmount - this.amountNeeded;
    this.setProgress();
  }

  setProgress() {
    this.progress = Utils.calculateFinancedPercent(this.amountRaised, this.totalAmount);
    if (this.progress > 100) {
      this.progress = 100;
      return;
    }
    if (this.progress < 0) {
      this.progress = 0;
    }
  }
}
