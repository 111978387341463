export enum ButtonType {
  NEXT,
  PREV,
  CANCEL,
  SAVE,
  SUBMIT,
  PREVIEW,
  TOGGLE,
  STATUS,
}
