import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import Quill from 'quill';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent {
  @Input() updateControl: FormControl;
  @Input() uuid: string;
  @Input() placeholder: string;
  @Input() maxLength: number = 1000;

  @Input() formats = [
    'background',
    'bold',
    'color',
    'font',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'image',
    'video',
  ];

  @Input() modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['link'],
      ['clean'],
    ],
  };

  // prettier-ignore
  constructor() {}

  editorCreated(quill: Quill): void {
    quill.clipboard.addMatcher('IMG', () => {
      const Delta = Quill.import('delta');
      return new Delta().insert('');
    });
    quill.clipboard.addMatcher('PICTURE', () => {
      const Delta = Quill.import('delta');
      return new Delta().insert('');
    });
    quill.clipboard.addMatcher('VIDEO', () => {
      const Delta = Quill.import('delta');
      return new Delta().insert('');
    });
  }
}
