import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { ControlError, GSControl, GSTypedArray } from '../FormControls';
import { generateGSFormElement } from './Model';
import { GSFormGroup } from './GSFormGroup';
import { EntityProfile } from '../EntityProfile';

export class GSFormArray<T = any> extends FormArray<GSTypedArray<T>> {
  label: string;
  placeholder: string;
  max: number;
  controlErrors: ControlError[];
  key: string;
  defaultValidators: ValidatorFn[];

  arrayItemGSControl: GSControl<T>;
  readonly defaultValue: T;
  onAddItem: (gsControl: GSFormGroup<T>) => void;
  profile: EntityProfile;

  constructor(gsControl: GSControl, formGroup: GSTypedArray<T>[] = []) {
    super(formGroup, gsControl.validators, gsControl.asyncValidators);
    this.key = gsControl.key;
    this.label = gsControl.label;
    this.placeholder = gsControl.placeholder;
    this.max = gsControl.max;
    this.controlErrors = gsControl.errors;
    this.defaultValidators = gsControl.validators;
    this.defaultValue = this.value as unknown as T;
    this.onAddItem = gsControl.onAddItem;
    this.profile = gsControl.profile;

    this.arrayItemGSControl = gsControl.controls.item;
  }

  override push(control, options?: { emitEvent: boolean }): void {
    super.push(control, options);
    if (this.onAddItem) {
      this.onAddItem(control as GSFormGroup<T>);
    }
  }

  addEmptyItem(emitEvent?: boolean): GSFormGroup<T> {
    const gsControl: GSFormGroup<T> = generateGSFormElement<T>(this.arrayItemGSControl) as GSFormGroup<T>;
    (gsControl.get('position') as AbstractControl<number>).patchValue(this.length + 1);
    this.push(gsControl, { emitEvent });
    super.markAsDirty();
    if (this.onAddItem) {
      this.onAddItem(gsControl);
    }
    return gsControl;
  }

  override removeAt(index: number, options?: { emitEvent?: boolean }): void {
    super.removeAt(index, options);
    this.controls.forEach((control: GSFormGroup, index) => {
      control.get('position').patchValue(index + 1);
    });
    super.markAsDirty();
  }

  removeError(errorKey: string): void {
    if (this.errors.hasOwnProperty(errorKey)) {
      delete this.errors.errorKey;
      if (Object.values(this.errors).length < 1) {
        this.setErrors(null);
      }
    }
  }
}
