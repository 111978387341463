import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import Urls from 'src/app/_core/constants/Urls';
import { GlobalConfigService } from '../../../_core/services/global-config.service';
import { Color } from '@angular-material-components/color-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  text: string;
  year: number;
  termsOfUse: string;
  privacyPolicy: string;
  isChampionPage: boolean;
  showSecondaryFooter: boolean;
  footerColor: Color;
  footerParentWebsiteLink: string;
  companyName: string;
  hasCodeOfConduct: boolean;
  contactLink: string;

  // prettier-ignore
  constructor(
    private globalConfigService: GlobalConfigService,
    private router: Router,
  ) {
    this.termsOfUse = this.globalConfigService.globalConfig.termsOfUse;
    this.privacyPolicy = this.globalConfigService.globalConfig.privacyPolicy;
    this.footerColor = this.globalConfigService.globalConfig.footerColor;
    this.contactLink = this.globalConfigService.globalConfig.contactLink;
    this.footerParentWebsiteLink = this.globalConfigService.globalConfig.footerParentWebsiteLink;
    this.companyName = this.globalConfigService.globalConfig.companyName;
    this.year = new Date().getFullYear();
    this.hasCodeOfConduct = environment.hasCodeOfConduct;

    this.router.events.pipe(filter(value => value instanceof NavigationEnd))
      .subscribe(() => {
        this.checkUrl();
      });
  }

  checkUrl(): void {
    const championRoutes = [Urls.CHAMPIONS, Urls.CHAMPION_ADMIN, Urls.CHAMPION_PROFILE, Urls.CHAMPION_TAB];
    this.isChampionPage = false;
    championRoutes.forEach((route) => {
      if (this.router.url.includes(route)) {
        this.isChampionPage = true;
        return;
      }
    });
    this.showSecondaryFooter = this.router.url.includes(Urls.LEARN_MORE);
  }
}
