import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import StorageHelper from '../helpers/Storage.helper';
import Urls from '../constants/Urls';
import { UserService } from '../services/user.service';
import { KeycloakService } from '../api/keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanLoad {
  // prettier-ignore
  constructor(
    private router: Router,
    private userService: UserService,
    private keycloakService: KeycloakService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasAccess(state);
  }

  canLoad(_route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this.hasAccess(segments);
  }

  hasAccess(urlData: RouterStateSnapshot | UrlSegment[]): Observable<boolean> | boolean {
    let url: string, lastParam: string;
    if (urlData instanceof RouterStateSnapshot) {
      url = urlData.url;
      lastParam = url.split('/').reverse()[0];
    } else if (urlData[0] instanceof UrlSegment) {
      url = urlData.join('/');
      lastParam = urlData[urlData.length - 1].path;
    }

    if (StorageHelper.getKeycloakToken()) {
      return this.userService.currentUser$.pipe(
        switchMap((user) => {
          if (Object.values(Urls).findIndex((url) => url === lastParam) < 0) {
            this.keycloakService.logout();
            return of(true);
          }
          if (user?.email) {
            this.router.navigate([Urls.APP]);
            return of(false);
          }
          return of(true);
        })
      );
    } else {
      return true;
    }
  }
}
