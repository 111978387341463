<mat-form-field appearance="outline" class="w-100">
  <input
    matInput
    id="addressInput"
    ngx-google-places-autocomplete
    #placesRef
    (onAddressChange)="handleAddressChange($event)"
    [formControl]="address"
  />
  <mat-error>Address couldn't provide enough data. Please try again.</mat-error>
</mat-form-field>
