import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserTerm } from '../../../_core/constants/User';

@Component({
  selector: 'app-story-modal',
  templateUrl: './story-modal.component.html',
  styleUrls: ['./story-modal.component.scss'],
})
export class StoryModalComponent implements OnInit {
  story: string;
  header: string;
  viewer: boolean;
  loaded: boolean;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService
  ) {
  }

  ngOnInit(): void {
    this.header = this.modalsService.params.header;
    this.story = this.modalsService.params.story;
    this.viewer = [UserTerm.CODE_OF_CONDUCT as string, UserTerm.TS_AND_PRIVACY as string].indexOf(this.header) > -1;
  }

  close(): void {
    this.modalsService.closeModal();
  }
}
