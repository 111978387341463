<div class="w-100 d-flex justify-content-center">
  <div class="w-75 no-projects-container" [class.p-0]="noPaddings">
    <div class="d-flex justify-content-center folder-icon-container">
      <img class="align-self-center" src="assets/icons/iconfinder_folder.svg" />
    </div>
    <ng-container *ngIf="catalog; else custom">
      <div class="text-center header mb-2">{{ 'SHARED.COMPONENTS.NO_ITEMS_FOUND.CTA' | translate }}</div>
      <div class="text-center copy" [innerHTML]="description"></div>
    </ng-container>
    <ng-template #custom>
      <ng-container *ngIf="title || subTitleText; else default">
        <div class="text-center header mb-2" *ngIf="title">{{ title | translate }}</div>
        <div class="text-center copy" *ngIf="subTitle && subTitleText">{{ subTitleText | translate }}</div>
      </ng-container>
    </ng-template>
    <ng-template #default>
      <ng-container *ngIf="item">
        <div class="text-center header mb-2">{{ 'SHARED.COMPONENTS.NO_ITEMS_FOUND.NO_ITEM_FOUND' | translate: { value: item } }}</div>
        <div class="text-center copy" *ngIf="subTitle">{{ 'SHARED.COMPONENTS.NO_ITEMS_FOUND.ADJUST_SEARCH_FILTER' | translate }}</div>
      </ng-container>
    </ng-template>
  </div>
</div>
