import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfigService } from '../../../_core/services/global-config.service';

@Component({
  selector: 'app-no-items-found',
  templateUrl: './no-items-found.component.html',
  styleUrls: ['./no-items-found.component.scss'],
})
export class NoItemsFoundComponent implements OnInit {
  @Input() item = null;
  @Input() subTitle = true;
  @Input() title: string = null;
  @Input() subTitleText: string = null;
  @Input() catalog = false;
  @Input() noPaddings = false;

  description: string;

  constructor(private translateService: TranslateService, private globalConfigService: GlobalConfigService) {}

  ngOnInit(): void {
    this.computeDescription();
  }

  computeDescription(): void {
    if (this.catalog) {
      this.translateService
        .get('SHARED.COMPONENTS.NO_ITEMS_FOUND.DESCRIPTION', { emailAddress: this.globalConfigService.globalConfig.adminEmail })
        .subscribe((res: string) => {
          this.description = res;
        });
    }
  }
}
