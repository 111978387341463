export enum MEMBER_STATE {
  JOINED = 'JOINED',
  NOT_JOINED = 'NOT_JOINED',
  DECLINED = 'DECLINED',
  PENDING_INVITATION = 'PENDING_INVITATION',
  REQUESTED_TO_JOIN = 'REQUESTED_TO_JOIN',
}

export enum GROUP_STATUS {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ARCHIVED = 'ARCHIVED',
}

export enum GROUP_ACTION {
  // performed on members
  MAKE_ADMIN = 'MAKE_ADMIN',
  REMOVE_ADMIN = 'REMOVE_ADMIN',
  REMOVE_FROM_GROUP = 'REMOVE_FROM_GROUP',
  APPROVE_MEMBER = 'APPROVE_MEMBER',
  DECLINE_MEMBER = 'DECLINE_MEMBER',
  CANCEL_INVITATION = 'CANCEL_INVITATION',
  REINVITE = 'REINVITE',

  // performed on group by admin
  APPROVE_GROUP = 'APPROVE_GROUP',
  DECLINE_GROUP = 'DECLINE_GROUP',
  ARCHIVE_GROUP = 'ARCHIVE_GROUP',
  EDIT_GROUP = 'EDIT_GROUP',

  // performed on group by members
  JOIN_GROUP = 'JOIN_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  DECLINE_INVITATION = 'DECLINE_INVITATION',
  REQUEST_TO_JOIN = 'REQUEST_TO_JOIN',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  ADD_MEMBER = 'ADD_MEMBER',
}

export enum INVITED_MEMBER_STATE {
  SUCCESS = 'Success',
  FAILED = 'Fail',
}

export const GROUP_SORT_OPTIONS: string[] = ['ALPHABETICAL', 'DATE_POSTED'];

export const GROUP_PROJECT_SORT_OPTIONS: string[] = ['PROJECT_ALPHABETICAL', 'PROJECT_DATE_POSTED'];

export const GROUP_PORTFOLIO_SORT_OPTIONS: string[] = ['PORTFOLIO_ALPHABETICAL', 'PORTFOLIO_DATE_POSTED'];

export const GROUP_ORGANISATION_SORT_OPTIONS: string[] = ['ORGANISATION_ALPHABETICAL', 'ORGANISATION_DATE_POSTED'];
