import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MODAL_ACTIONS, MODAL_RESPONSE } from '../../../_core/constants/Modals';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit, OnDestroy {
  confirmActionButton: string = '';
  cancelActionButton: string = '';
  message: string;
  confirmButtonColor: string;
  cancelButtonColor: string;
  messageInfo: string;
  payload: any;
  action: MODAL_ACTIONS;
  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.message = this.modalsService.params.message;
    this.messageInfo = this.modalsService.params.messageInfo;
    this.payload = this.modalsService.params.payload;
    this.action = this.modalsService.params.action;
    this.confirmActionButton = this.modalsService.params.confirmActionButton || '';
    this.cancelActionButton = this.modalsService.params.cancelActionButton || '';
    this.cancelButtonColor = this.modalsService.params.cancelButtonColor || 'primary';
    this.confirmButtonColor = this.modalsService.params.confirmButtonColor || 'warn';

    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 500px)']).subscribe((result) => {
      result.matches ? this.dialogRef.updateSize('100vw', 'auto') : this.dialogRef.updateSize('500px', 'auto');
    });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  emitTrue(): void {
    this.modalsService.emitResponse({
      confirmed: true,
      action: this.action,
      payload: this.payload,
      closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS,
    });
  }

  emitFalse(): void {
    this.modalsService.emitResponse({
      confirmed: false,
      action: null,
      payload: null,
    });
    this.modalsService.closeModal();
  }
}
