<ng-container *ngIf="isLink; else button">
  <a
    [id]="'HOME.LANDING.GIVE' | translate"
    class="uppercase px-4 px-sm-5 font-weight-bold give slide-button"
    mat-flat-button
    disableRipple
    [ngClass]="isPrimary ? 'text-color-white' : ''"
    (click)="$event.stopPropagation(); $event.preventDefault(); linkOut()"
    [color]="isPrimary ? 'primary' : ''"
    [disabled]="disabledLink"
    [style.pointer-events]="disabledLink ? 'none' : ''"
    *ngIf="url"
  >
    {{ 'HOME.LANDING.GIVE' | translate }}
  </a>

  <a
    [id]="'HOME.LANDING.GIVE' | translate"
    class="uppercase px-4 px-sm-5 font-weight-bold give slide-button"
    mat-flat-button
    disableRipple
    [matTooltip]="disabledLink ? '' : ('NOTIFICATIONS.GIVING.GIVING_LINK_MISSING_ERROR' | translate)"
    [color]="isPrimary ? 'primary' : ''"
    [ngClass]="isPrimary ? 'give-btn-primary-disabled' : 'give-btn-not-primary-disabled'"
    disabled
    *ngIf="!url"
  >
    {{ 'HOME.LANDING.GIVE' | translate }}
  </a>
</ng-container>

<ng-template #button>
  <button
    mat-flat-button
    [color]="isPrimary ? 'primary' : ''"
    class="uppercase px-4 px-sm-5 font-weight-bold give slide-button"
    (click)="clicked.emit()"
  >
    {{ 'HOME.LANDING.GIVE' | translate }}
  </button>
</ng-template>
