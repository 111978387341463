export enum FILTER_NAME {
  NEWLY_ADDED_PROJECT = 'NEWLY_ADDED_PROJECT',
  NEWLY_ADDED_ORGANIZATION = 'NEWLY_ADDED_ORGANIZATION',
  NEWLY_ADDED_PORTFOLIO = 'NEWLY_ADDED_PORTFOLIO',
  FEATURED_PROJECTS = 'FEATURED_PROJECTS',
  FEATURED_ORGANISATIONS = 'FEATURED_ORGANISATIONS',
  FEATURED_PORTFOLIOS = 'FEATURED_PORTFOLIOS',
  SEARCH = 'SEARCH',
  FUNDED = 'FUNDED',
  CLOSE_TO_CLOSING_PROJECT = 'CLOSE_TO_CLOSING_PROJECT',
  COUNTRY = 'COUNTRY',
  CAUSE = 'CAUSE',
  BADGES = 'BADGES',

  PROJECTS_CHAMPIONED = 'PROJECTS_CHAMPIONED',
  PROJECTS_CATEGORY = 'PROJECTS_CATEGORY',
  PROJECTS_REGION = 'PROJECTS_REGION',
  CHARITIES_CHAMPIONED = 'CHARITIES_CHAMPIONED',
  CHARITIES_CATEGORY = 'CHARITIES_CATEGORY',
  CHARITIES_REGION = 'CHARITIES_REGION',

  TYPE = 'TYPE',
  ROLE = 'ROLE',
  STATUS = 'STATUS',
  VETTED_STATUS = 'VETTED_STATUS',
  NEW_USERS = 'NEW_USERS',
  LAST_MODIFIED = 'LAST_MODIFIED',
  SEARCH_BY_ORGANISATION = 'SEARCH_BY_ORGANISATION',

  ARCHIVED = 'ARCHIVED',
  COMPLETED_REGISTRATION = 'COMPLETED_REGISTRATION',
  WAITING_ROOM = 'WAITING_ROOM',

  GROUPS_TYPE = 'GROUPS_TYPE',
  PROJECTS_DISCUSSED = 'PROJECTS_DISCUSSED',
  PROJECTS_STATUSES = 'PROJECTS_STATUSES',
  ORGANISATIONS_DISCUSSED = 'ORGANISATIONS_DISCUSSED',
  PORTFOLIOS_DISCUSSED = 'PORTFOLIOS_DISCUSSED',

  PROJECTS_INCLUDED = 'PROJECTS_INCLUDED',
  ORGANISATIONS_INCLUDED = 'ORGANISATIONS_INCLUDED',
  PORTFOLIO_TYPE = 'PORTFOLIO_TYPE',
  PORTFOLIO_CATEGORY = 'PORTFOLIO_CATEGORY',
  PORTFOLIO_REGION = 'PORTFOLIO_REGION',
  PORTFOLIOS_CHAMPIONED = 'PORTFOLIOS_CHAMPIONED',

  CHAMPION_STATUSES = 'CHAMPION_STATUSES',
}
