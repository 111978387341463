import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SortOptionFilter } from '../../../_core/models/filters/Filters';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent {
  @Input() sortByOptions: SortOptionFilter[];
  @Input() showText = true;
  @Output() triggerRequest = new EventEmitter<string>();

  constructor() {}

  selectOption(sort: SortOptionFilter, toggle = false) {
    this.deselectPrevious(sort);
    if (toggle) {
      sort.order = sort.order === 'ASC' ? 'DESC' : 'ASC';
    }
    sort.checked = true;
    this.triggerRequest.emit();
  }

  deselectPrevious(sort: SortOptionFilter) {
    this.sortByOptions.forEach((i) => {
      if (i !== sort) {
        i.checked = false;
        i.order = 'ASC';
      }
    });
  }
}
