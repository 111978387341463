import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/_core/api/general.service';
import { FileType } from 'src/app/_core/constants/FileType';
import { Modals, MODAL_ACTIONS } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import Utils from 'src/app/_core/helpers/Utils';
import { AllowedFileTypesMessage } from 'src/app/_core/models/FileInfo';
import { Media } from 'src/app/_core/models/FormControls';
import { GSFormControl } from 'src/app/_core/models/forms/GSFormControl';
import { ModalEvent } from 'src/app/_core/models/Modal';
import { UploadPromiseResponse } from 'src/app/_core/models/UploadResponse';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { ToastService } from 'src/app/_core/services/toast.service';
import { UploadService } from 'src/app/_core/services/upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Output() fileUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() imageControl: GSFormControl;
  @Input() videoControl: GSFormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() cropFormat: string;
  @Input() buttonId: string;
  uploader: FileUploader;
  modalSub: Subscription;
  uploaderSub: Subscription;
  allowedFileTypes: string;

  // prettier ignore
  constructor(
    private uploadService: UploadService,
    private toastService: ToastService,
    private modalsService: ModalsService,
    private generalService: GeneralService
  ) {
    this.uploader = this.uploadService.uploaderInstance;
    this.modalSub = this.modalsService.modalResponse$.subscribe((response: ModalEvent) => {
      this.handleModalResponse(response);
    });
    this.uploaderSub = this.uploadService.uploaderObservable.subscribe((data) =>
      data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)
    );
  }

  ngOnInit(): void {
    this.setAllowedFileTypes();
  }

  ngOnDestroy(): void {
    this.modalSub.unsubscribe();
    this.uploaderSub.unsubscribe();
  }

  setAllowedFileTypes(): void {
    if (this.imageControl && this.videoControl) this.allowedFileTypes = new AllowedFileTypesMessage().videoOrImageValidFormats;
    else if (this.imageControl) this.allowedFileTypes = new AllowedFileTypesMessage().imageValidFormats;
    else if (this.videoControl) this.allowedFileTypes = new AllowedFileTypesMessage().videoValidFormats;
  }

  handleModalResponse(response: ModalEvent): void {
    const { confirmed, action, payload } = response;
    if (!confirmed) return;
    const mediaControl = response.mediaControl || payload?.mediaControl;
    if (!mediaControl) return;
    const isImage = (mediaControl as any).label === this.imageControl?.label;
    const isVideo = (mediaControl as any).label === this.videoControl?.label;
    switch (action) {
      case MODAL_ACTIONS.CROP:
        if (!isImage) return;
        payload.forEach((b64string) => {
          this.uploadService.uploadFileV3(this.imageControl, null, null, b64string);
        });
        break;
      case MODAL_ACTIONS.DELETE_FILE:
        if (!isImage && !isVideo) return;
        this.deleteFileRequest(payload);
        break;
    }
  }

  setProgress(response: UploadPromiseResponse): void {
    const media = response.fileItem.formData.mediaControl.value as Media;
    media.setProgress(response.response);
  }

  setFileFields(uploadResponse: UploadPromiseResponse): void {
    const mediaControl: GSFormControl = uploadResponse.fileItem.formData.mediaControl;
    const isImage = mediaControl.label === this.imageControl?.label;
    const isVideo = mediaControl.label === this.videoControl?.label;
    if (!isImage && !isVideo) return;
    if (uploadResponse.response.hasOwnProperty('error') || uploadResponse.response.hasOwnProperty('message')) {
      mediaControl.reset();
      this.toastService.error(ToastrMessages.BASIC_ERROR);
      return;
    }
    mediaControl.patchValue({ ...uploadResponse.response.response, fileName: uploadResponse.fileItem.file.name });
    if (this.imageControl && this.videoControl) this.validateMultiUpload();
    this.fileUpdated.emit(mediaControl);
    this.toastService.success(ToastrMessages.FILE_UPLOADED);
  }

  validateMultiUpload(): void {
    this.imageControl.restoreValidators();
    this.videoControl.restoreValidators();
    if (this.imageControl.value?.uuid) this.videoControl.clearValidators(true);
    else if (this.videoControl.value?.uuid) this.imageControl.clearValidators(true);
  }

  deleteFileRequest(payload): void {
    const mediaControl: GSFormControl<Media> = payload.mediaControl;
    this.generalService.deleteFiles('', mediaControl.value.fileType, mediaControl.value.uuid).subscribe({
      error: () => {
        if (
          mediaControl.value.fileType === FileType.GENERAL_IMAGE ||
          mediaControl.value.fileType === FileType.GENERAL_VIDEO ||
          mediaControl.value.fileType === FileType.APPLICATION_LOGO
        ) {
          mediaControl.reset();
        }
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
      complete: () => {
        mediaControl.reset();
        if (this.imageControl && this.videoControl) this.validateMultiUpload();
        this.fileUpdated.emit(mediaControl);
        this.toastService.success(ToastrMessages.REMOVE_FILE);
      },
    });
  }

  imageOrVideoSelected(): void {
    const file = this.uploader.queue[this.uploader.queue.length - 1];
    if (!file) return;
    if (Utils.checkFileType(file.file.name, file.file.type, FileType.VIDEO) && this.videoControl) {
      this.videoControl.value.allowedFileType = FileType.VIDEO;
      this.uploadService.uploadFileV3(this.videoControl);
    } else if (Utils.checkFileType(file.file.name, file.file.type, FileType.IMG) && this.imageControl) {
      this.imageControl.value.allowedFileType = FileType.IMG;
      this.uploadService.uploadFileV3(this.imageControl);
    } else {
      this.toastService.error(ToastrMessages.INVALID_FORMAT);
    }
  }

  openCropModal(): void {
    this.modalsService.openModal(Modals.CROP_IMAGE, { mediaControl: this.imageControl, format: this.cropFormat });
  }

  deleteFile(mediaControl: GSFormControl<Media>): void {
    const payload = { mediaControl };
    this.modalsService.openModal(Modals.DELETE_CONFIRMATION, { message: 'SHARED.MODALS.DELETE.FILE', payload, action: MODAL_ACTIONS.DELETE_FILE });
  }

  get hideButton(): boolean {
    if (this.videoControl && (this.videoControl.value.fileName || this.videoControl.value.pendingUpload)) return true;
    if (this.imageControl && (this.imageControl.value.fileName || this.imageControl.value.pendingUpload)) return true;
    return false;
  }
}
