<div class="d-flex position-relative justify-content-center">
  <a><img (click)="close()" class="close-icon link" src="../../../../../../assets/icons/clear-icon.svg" /></a>
  <p class="font-weight-bold mb-4 font-18">{{ 'ADMIN.ENTITIES_OPTIONS.DECLINE.TITLE' | translate }}</p>
</div>
<div class="d-flex flex-column">
  <div class="admin-textarea">
    <div>
      <span class="label">{{ reason.label | translate }} *</span>
      <span class="optional ml-1">({{ reason.optionalText | translate: { maxChars: reason.max } }})</span>
    </div>
    <mat-form-field appearance="outline" class="w-100">
      <textarea cdkTextareaAutosize cdkAutosizeMaxRows="10" rows="2" matInput [formControl]="reason" [maxlength]="reason.max" required></textarea>
      <mat-error *ngFor="let err of reason.controlErrors">
        <mat-error *ngIf="reason.hasError(err.type)">
          {{ err.text | translate }}
        </mat-error>
      </mat-error>
    </mat-form-field>
  </div>
  <button class="mat-main-button mt-3 w-100" mat-raised-button color="primary" (click)="emitTrue()">
    <div>{{ 'GENERAL.GENERIC_BUTTONS.SUBMIT' | translate }}</div>
  </button>
</div>
