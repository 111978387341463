<div class="text-right">
  <a><img [mat-dialog-close]="emitFalse()" class="close-icon pointer" src="assets/icons/clear-icon.svg" /></a>
</div>

<div class="main">
  <img class="d-block m-auto" src="assets/icons/iconfinder_error.svg" />
  <div class="text-center font-weight-bold copy">{{ 'SHARED.MODALS.CANCEL_CHANGES.CTA_TEXT' | translate }}</div>
  <div class="button-row d-flex justify-content-between">
    <button mat-stroked-button [mat-dialog-close]="emitFalse()" color="primary" class="mat-outline-button gray-border">
      {{ 'GENERAL.GENERIC_BUTTONS.CLOSE' | translate }}
    </button>
    <button mat-flat-button (click)="closeAll()" color="accent" class="mat-main-button">{{ 'GENERAL.GENERIC_BUTTONS.YES' | translate }}</button>
  </div>
</div>
