import { KeyValue } from '../models/GenericObject';

export enum InfluencedCountriesDelimiterKeys {
  COMMA = 'COMMA',
  BOX = 'BOX',
}

export const InfluencedCountriesDelimiters: KeyValue<string>[] = [
  {
    key: InfluencedCountriesDelimiterKeys.COMMA,
    value: ',',
  },
  {
    key: InfluencedCountriesDelimiterKeys.BOX,
    value: 'box',
  },
];
