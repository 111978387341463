import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appBoldedTitle]',
})
export class BoldedTitleDirective implements OnInit {
  @Input() text: string;
  @Input() lastOfArray: boolean;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.setCategories();
  }

  setCategories(): void {
    let boldedTitleText: string = this.text;
    if (this.text.indexOf(':') >= 0) {
      boldedTitleText = `<b>${this.text.split(':')[0]}:</b> ${this.text.split(':')[1]}`;
    }
    let separator = this.lastOfArray ? '' : ',&nbsp;&nbsp;';
    boldedTitleText += separator;
    this.el.nativeElement.innerHTML = boldedTitleText;
  }
}
