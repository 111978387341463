<div class="d-flex justify-content-end mt-3">
  <button
    id="goToPrevStep"
    class="stepper-button separator-right"
    [disabled]="selectedIndex === 0"
    [disableRipple]="true"
    type="button"
    (click)="buttonClicked.emit(buttonTypes.PREV)"
    mat-button
  >
    {{ 'GENERAL.GENERIC_BUTTONS.PREV' | translate }}
  </button>
  <button
    id="goToNextStep"
    class="stepper-button"
    [disabled]="selectedIndex === indexToHide"
    [disableRipple]="true"
    type="button"
    (click)="buttonClicked.emit(buttonTypes.NEXT)"
    mat-button
  >
    {{ 'GENERAL.GENERIC_BUTTONS.NEXT' | translate }}
  </button>
</div>
