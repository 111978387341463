import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { EntityType } from '../constants/CompanyOrFirm';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly resourceUrl: string = '/v1/admin';

  // prettier-ignore
  constructor(
    private apiService: ApiService
  ) {
  }

  getUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/get-users`, body);
  }

  exportUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/download-user-file`, body);
  }

  getPermissionItems() {
    return this.apiService.get(`${this.resourceUrl}/get-permission-items`);
  }

  changeUserPermission(body: any) {
    return this.apiService.post(`${this.resourceUrl}/change-permission`, body);
  }

  updateInvitationVetted(body) {
    return this.apiService.post(`${this.resourceUrl}/update-token`, body);
  }

  changeVetted(invited: boolean, body: any) {
    return invited ? this.updateInvitationVetted(body) : this.changeUserPermission(body);
  }

  restoreUser(uuid: string) {
    return this.apiService.put(`${this.resourceUrl}/unarchive-user/${uuid}`);
  }

  archiveUser(uuid: string) {
    return this.apiService.put(`${this.resourceUrl}/archive-user/${uuid}`);
  }

  deleteUser(uuid: string) {
    return this.apiService.delete(`${this.resourceUrl}/delete-user/${uuid}`);
  }

  getUploadImageUrl() {
    return `${environment.server}${this.resourceUrl}/upload-files-for-user`;
  }

  inviteUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/invite-user`, body);
  }

  getEntities(entityOptions: EntityType, searchInput: string) {
    return this.apiService.get(`${this.resourceUrl}/entities/${entityOptions}`, { searchInput });
  }

  checkName(email: string) {
    return this.apiService.get(`${this.resourceUrl}/check-name`, { email });
  }

  searchLanguage(name: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/search-language/${name}`);
  }

  createUpdateLangauge(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update-language`, body);
  }
}
