import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfigService } from '../../../_core/services/global-config.service';

@Component({
  selector: 'app-restricted-area',
  templateUrl: './restricted-area.component.html',
  styleUrls: ['./restricted-area.component.scss'],
})
export class RestrictedAreaComponent implements OnInit {
  text: string;

  // prettier-ignore
  constructor(
    private translateService: TranslateService,
    private globalConfigService: GlobalConfigService
  ) {
  }

  ngOnInit(): void {
    this.computeText();
  }

  computeText(): void {
    this.translateService
      .get('SHARED.COMPONENTS.RESTRICTED_AREA.MESSAGE', { email: this.globalConfigService.globalConfig.adminEmail })
      .subscribe((res: string) => {
        this.text = res;
      });
  }
}
