<div class="loader w-100" [hidden]="!loading">
  <div class="content flex-all-center flex-column" [class.small]="small">
    <div class="d-flex logo-animation mb-3">
      <span style="--i: 1">G</span>
      <span style="--i: 2">I</span>
      <span style="--i: 3">V</span>
      <span style="--i: 4">I</span>
      <span style="--i: 5">N</span>
      <span style="--i: 6">G</span>
      <span style="--i: 7">S</span>
      <span style="--i: 8">P</span>
      <span style="--i: 9">A</span>
      <span style="--i: 10">C</span>
      <span style="--i: 11">E</span>
    </div>
    <p>{{ 'GENERAL.GENERIC_LABELS.LOADING' | translate }}</p>
  </div>
</div>
