import { Language } from '../models/global-settings/Language';

export default class StorageHelper {
  private static readonly tokenKey: string = 'jwtToken';
  private static readonly keyCloakTokenKey: string = 'access_token';
  private static readonly refreshTokenKey: string = 'refreshJwtToken';
  private static readonly username: string = 'username';
  private static readonly currency: string = 'currency';
  private static readonly language: string = 'language';
  private static readonly languages: string = 'languages';
  private static readonly appConfig: string = 'appConfig';

  static getAppConfig() {
    return window.localStorage[this.appConfig];
  }

  static saveAppConfig(appConfig: string) {
    window.localStorage[this.appConfig] = appConfig;
  }

  static getToken() {
    return window.localStorage[this.tokenKey];
  }

  static getKeycloakToken() {
    return window.localStorage[this.keyCloakTokenKey];
  }

  static getRefreshToken() {
    return window.localStorage[this.refreshTokenKey];
  }

  static getUsername() {
    return window.localStorage[this.username];
  }

  static saveTokensAndUsername(token: string, refreshToken?: string, username?: string) {
    this.saveToken(token);
    window.localStorage[this.refreshTokenKey] = refreshToken;
    window.localStorage[this.username] = username;
  }

  static saveToken(token: string): void {
    window.localStorage[this.tokenKey] = token;
  }

  static clearToken(): void {
    window.localStorage.removeItem(this.tokenKey);
  }

  static killSession() {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.username);
  }

  static saveCurrency(currency: string): void {
    window.localStorage[this.currency] = currency;
  }

  static getCurrency(): string {
    return window.localStorage[this.currency];
  }

  static saveLanguage(language: string): void {
    window.localStorage[this.language] = language;
  }

  static getSelectedLanguage(): Language {
    const language = window.localStorage[this.language];
    return language && language !== 'undefined' && language !== 'null' ? JSON.parse(language) : null;
  }

  static saveStoredLanguages(list: string): void {
    window.localStorage[this.languages] = list;
  }

  static getStoredTranslationsList(): object {
    const languages = window.localStorage[this.languages];
    return languages ? JSON.parse(languages) : {};
  }

  static setLanguageJSON(code: string, json: string): void {
    window.localStorage[code] = json;
  }

  static getLanguageJSON(code: string): object {
    const json = window.localStorage[code];
    return json && json !== 'undefined' && json !== 'null' ? JSON.parse(json) : null;
  }

  static clearAll(): void {
    window.localStorage.clear();
  }

  static clearLanguages(): void {
    const languagesList = this.getStoredTranslationsList();
    Object.values(languagesList).forEach((language) => {
      window.localStorage.removeItem(language.code);
    });
  }

  static getDateFormat(): string {
    const json = window.localStorage[this.appConfig];
    return json && json !== 'undefined' && json !== 'null' ? JSON.parse(json).dateFormat : 'M/d/yyyy';
  }
}
