import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupUpdates } from 'src/app/_core/models/Group';
import Utils from 'src/app/_core/helpers/Utils';
import { FileType } from 'src/app/_core/constants/FileType';
import StorageHelper from 'src/app/_core/helpers/Storage.helper';

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
})
export class UpdateCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('content') paragraphContent: ElementRef<HTMLParagraphElement>;

  canGoBack: boolean;
  isTablet: boolean;
  breakpointSubscription: Subscription;
  contentLength: number = 0;
  showMoreContent: boolean = false;
  isMoreContent: boolean = false;
  dateFormat: string;
  @Input() groupUpdate: GroupUpdates;
  @Input() updateList: boolean = false;
  @Input() isAdmin: boolean = false;

  @Output() deleteUpdateEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() editUpdateEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private breakpointObserver: BreakpointObserver, private ref: ChangeDetectorRef) {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((result) => {
      this.isTablet = result.matches;
    });
  }

  ngOnInit(): void {
    this.dateFormat = StorageHelper.getDateFormat();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.contentLength = this.paragraphContent.nativeElement.innerText.length;
    this.isMoreContent = this.contentLength > 405;
    this.ref.detectChanges();
  }

  readMoreOrLess() {
    this.showMoreContent = !this.showMoreContent;
  }

  previewDocument(document): void {
    if (Utils.checkFileType(document.fileName, document.type, FileType.DOC)) {
      Utils.navigate(document.url, '_blank');
    }
  }
}
