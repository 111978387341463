import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GeneralService } from '../api/general.service';
import { ResponseObject } from '../models/GenericObject';
import { GlobalDropdowns } from '../models/global-settings/Dropdowns';

@Injectable({
  providedIn: 'root',
})
export class DropdownsService {
  private dropdowns = new ReplaySubject<GlobalDropdowns>(1);
  public dropdowns$ = this.dropdowns.asObservable();

  // prettier-ignore
  constructor(
    private generalService: GeneralService
  ) {
  }

  getDropdowns(): void {
    this.generalService.getDropdowns().subscribe({
      next: (response: ResponseObject<GlobalDropdowns>) => {
        this.dropdowns.next(response.response);
      },
      error: () => {
        this.dropdowns.next({
          languages: [],
          currencies: [],
        });
      },
    });
  }
}
