import { CONTROL_TYPES, ControlError, GSControl } from 'src/app/_core/models/FormControls';
import { Validators } from '@angular/forms';

export enum HomepageEntityType {
  PROJECTS = 'PROJECTS',
  ORGANISATIONS = 'ORGANISATIONS',
  PORTFOLIOS = 'PORTFOLIOS',
}

export enum HomepageFilterType {
  FEATURED = 'FEATURED',
  NEWLY_ADDED = 'NEWLY_ADDED',
  CHAMPIONED = 'CHAMPIONED',
}

export interface HomepageConfigDto {
  enableMainCarousel: boolean;
  timer: number;
  type: HomepageEntityType;
  filter: HomepageFilterType;
  carouselRows: CarouselRow[];
}

export class HomepageConfig {
  enableMainCarousel: boolean;
  mainCarouselTimer: number;
  mainCarouselEntityType: HomepageEntityType;
  mainCarouselFilterType: HomepageFilterType;
  homepageCarouselRows: CarouselRow[];

  constructor(data?: HomepageConfigDto) {
    this.enableMainCarousel = data?.enableMainCarousel || false;
    this.mainCarouselTimer = data?.timer || 3;
    this.mainCarouselEntityType = data?.type || HomepageEntityType.PROJECTS;
    this.mainCarouselFilterType = data?.filter || HomepageFilterType.FEATURED;
    this.homepageCarouselRows = data?.carouselRows || [
      {
        entityType: HomepageEntityType.PROJECTS,
        filterType: HomepageFilterType.FEATURED,
        position: 1,
      },
    ];
  }
}

export interface CarouselRow {
  position: number;
  entityType: HomepageEntityType | string;
  filterType: HomepageFilterType | string;
}

export enum HomepageConfigControlKeys {
  ENABLE_MAIN_CAROUSEL = 'enableMainCarousel',
  MAIN_CAROUSEL_TIMER = 'mainCarouselTimer',
  MAIN_CAROUSEL_ENTITY_TYPE = 'mainCarouselEntityType',
  MAIN_CAROUSEL_FILTER_TYPE = 'mainCarouselFilterType',
  HOMEPAGE_CAROUSEL_ROWS = 'homepageCarouselRows',
  ENTITY_TYPE = 'entityType',
  FILTER_TYPE = 'filterType',
  POSITION = 'position',
}

export const HOMEPAGECONFIG_FORM: GSControl = new GSControl({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [HomepageConfigControlKeys.ENABLE_MAIN_CAROUSEL]: new GSControl<boolean>({
      label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.ENABLE_MAIN_CAROUSEL',
      value: false,
      validators: [Validators.required],
      errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.ENABLE_MAIN_CAROUSEL_REQUIRED')],
    }),
    [HomepageConfigControlKeys.MAIN_CAROUSEL_TIMER]: new GSControl<number>({
      label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.MAIN_CAROUSEL_TIMER',
      placeholder: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.PLACEHOLDERS.MAIN_CAROUSEL_TIMER',
      validators: [Validators.required],
      min: 3,
      max: 99,
      value: 3,
      errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.MAIN_CAROUSEL_TIMER_REQUIRED')],
    }),
    [HomepageConfigControlKeys.MAIN_CAROUSEL_ENTITY_TYPE]: new GSControl<HomepageEntityType>({
      label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.MAIN_CAROUSEL_ENTITY_TYPE',
      placeholder: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.PLACEHOLDERS.MAIN_CAROUSEL_ENTITY_TYPE',
      validators: [Validators.required],
      value: HomepageEntityType.PROJECTS,
      errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.MAIN_CAROUSEL_ENTITY_TYPE_REQUIRED')],
    }),
    [HomepageConfigControlKeys.MAIN_CAROUSEL_FILTER_TYPE]: new GSControl<HomepageFilterType>({
      label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.MAIN_CAROUSEL_FILTER_TYPE',
      placeholder: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.PLACEHOLDERS.MAIN_CAROUSEL_FILTER_TYPE',
      value: HomepageFilterType.FEATURED,
      validators: [Validators.required],
      errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.MAIN_CAROUSEL_FILTER_TYPE_REQUIRED')],
    }),
    [HomepageConfigControlKeys.HOMEPAGE_CAROUSEL_ROWS]: new GSControl<CarouselRow>({
      controlType: CONTROL_TYPES.FORM_ARRAY,
      label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.HOMEPAGE_CAROUSEL_ROWS',
      min: 1,
      max: 3,
      controls: {
        item: new GSControl<CarouselRow>({
          controlType: CONTROL_TYPES.FORM_GROUP,
          controls: {
            [HomepageConfigControlKeys.POSITION]: new GSControl<number>({
              value: 1,
            }),
            [HomepageConfigControlKeys.ENTITY_TYPE]: new GSControl({
              label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.ENTITY_TYPE',
              placeholder: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.PLACEHOLDERS.ENTITY_TYPE',
              validators: [Validators.required],
              errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.ENTITY_TYPE_REQUIRED')],
            }),
            [HomepageConfigControlKeys.FILTER_TYPE]: new GSControl({
              label: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.LABELS.FILTER_TYPE',
              placeholder: 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.PLACEHOLDERS.FILTER_TYPE',
              validators: [Validators.required],
              errors: [new ControlError('required', 'ADMIN.GLOBAL_SETTINGS.HOMEPAGE_CONFIG.ERRORS.FILTER_TYPE_REQUIRED')],
            }),
          },
        }),
      },
    }),
  },
});
