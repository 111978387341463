import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { EntityType } from '../../../_core/constants/CompanyOrFirm';
import { of } from 'rxjs';
import { UUIDName } from '../../../_core/models/GenericObject';
import { AdminService } from '../../../_core/api/admin.service';
import { ControlError } from 'src/app/_core/models/FormControls';
import { UserService } from 'src/app/_core/services/user.service';
import { Role } from 'src/app/_core/constants/User';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-search-entity',
  templateUrl: './search-entity.component.html',
  styleUrls: ['./search-entity.component.scss'],
})
export class SearchEntityComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() entityType: EntityType = EntityType.ORGANIZATION;
  @Input() control: FormControl;
  @Input() suffixIcon: string;
  @Input() required = true;
  @Input() errors: ControlError[] = [];
  @Input() entityValue: UUIDName;

  @Output() searchRequest = new EventEmitter<any>();
  @Output() optionSelected = new EventEmitter<any>();

  searchResults: UUIDName[] = [];
  isSuperAdmin: boolean;
  canSearch: boolean;

  // prettier-ignore
  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (this.userService.currentUser.role === Role.SUPER_ADMIN || this.userService.currentUser.role === Role.FOUNDATION_ADMIN) {
      this.isSuperAdmin = true;
      if (this.entityValue) {
        this.prefillInput(this.entityValue);
      } else {
        this.handleEntityChange();
      }
    } else {
      this.prefillInput(this.userService.currentUser.entityDto);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label?.currentValue) {
      this.label = changes.label.currentValue;
    }

    if (changes.entityType?.currentValue) {
      this.entityType = changes.entityType.currentValue;
    }

    if (changes.required?.currentValue || changes.required?.currentValue === false) {
      this.required = changes.required.currentValue;
    }
  }

  handleEntityChange(): void {
    this.control.valueChanges
      .pipe(
        filter((value) => {
          if (typeof value === 'object' && value) {
            this.canSearch = true;
            return false;
          }
          this.canSearch = false;
          return typeof value === 'string' && value.trim().length > 0;
        }),
        debounceTime(500),
        tap(() => (this.searchResults = [])),
        switchMap((value: string) => this.adminService.getEntities(this.entityType, value).pipe(catchError((err) => of(err))))
      )
      .subscribe((res) => {
        this.searchResults = res?.response || [];
        this.ref.detectChanges();
      });
  }

  prefillInput(value: UUIDName): void {
    this.control.setValue(value);
    this.control.disable();
  }

  displayFn(item?: UUIDName): string | undefined {
    return item?.name;
  }

  emitSearch(event: any) {
    if (this.canSearch) {
      this.searchRequest.emit(event);
    }
  }

  emitOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.searchResults = [];
    this.optionSelected.emit(event.option.value);
  }
}
