import { CodeValue } from '../models/GenericObject';

export enum Role {
  UNVERIFIED = 'UNVERIFIED',
  USER = 'USER',
  CHAMPION = 'ADMIN_CHAMPION',
  SUPER_ADMIN = 'SUPERADMIN',
  FOUNDATION_ADMIN = 'FOUNDATION_ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  PROJECT_ADMIN = 'PROJECT_MANAGER',
}

export enum UserType {
  DONOR = 'DONOR',
  FOUNDATION = 'FOUNDATION',
  ORGANISATION = 'ORGANISATION',
}

export enum UserStatus {
  DRAFT = 'DRAFT',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export enum VettedStatus {
  VETTED = 'VETTED',
  NON_VETTED = 'NON_VETTED',
}

export enum UserTerm {
  CODE_OF_CONDUCT = 'CODE_OF_CONDUCT',
  TS_AND_PRIVACY = 'TS_AND_PRIVACY',
  CHAMPION_DISCLOSURE = 'CHAMPION_DISCLOSURE',
}

export const Interval: CodeValue[] = [
  new CodeValue('GENERAL.FILTERS.LAST_DAY', 1),
  new CodeValue('GENERAL.FILTERS.LAST_3_DAYS', 3),
  new CodeValue('GENERAL.FILTERS.LAST_7_DAYS', 7),
  new CodeValue('GENERAL.FILTERS.LAST_MONTH', 31),
];

export const UserSortOptions: string[] = ['FIRST_NAME', 'LAST_NAME', 'UPDATE_DATE'];

export const InvitedUserSortOptions: string[] = ['FIRST_NAME', 'LAST_NAME', 'ROLE', 'TYPE', 'EXPIRATION_DATE', 'ORGANISATION_NAME'];
