import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import Urls from '../../../_core/constants/Urls';
import { NavigationEnd, Router } from '@angular/router';
import { PageFilters } from 'src/app/_core/models/filters/Filters';
import { Subscription, filter } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() searchText: string = null;
  @Input() globalSearch: boolean = false;
  @Input() filterMenu: PageFilters<any>;
  @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInputFocus: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;

  subscriptions = new Subscription();
  opened: boolean;
  active: boolean;
  inputFocused: boolean;
  mouseInside: boolean;
  inputValue: string;
  placeholder: string;

  // prettier-ignore
  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit(): void {
    this.inputValue = this.filterMenu?.searchText ? this.filterMenu?.searchText : '';
    if (this.inputValue.length) {
      this.opened = true;
      this.active = true;
      this.inputFocused = true;
    }
    if (this.globalSearch) {
      this.opened = true;
    }
    this.setPlaceholder();
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setSubscriptions(): void {
    if (!this.globalSearch) {
      this.subscriptions.add(
        this.breakpointObserver.observe(['(max-width: 415px)']).subscribe((result) => {
          this.opened = result.matches;
        })
      );
    } else {
      this.subscriptions.add(
        this.router.events.pipe(filter((value) => value instanceof NavigationEnd)).subscribe((value: NavigationEnd) => {
          if (!value.url.includes(Urls.SEARCH_RESULTS)) this.inputValue = '';
        })
      );
    }
  }

  setPlaceholder(): void {
    const urlSegments = this.router.url.split('?')[0].split('/');
    const module = urlSegments[2];
    const page = urlSegments.reverse()[0];
    switch (module) {
      case Urls.SETTINGS:
        switch (page) {
          case Urls.ORGANIZATIONS:
            this.placeholder = 'SEARCH_ORGANISATION';
            break;
          case Urls.USERS_PERMISSIONS:
            this.placeholder = 'SEARCH_USER';
            break;
          default:
            this.placeholder = 'SEARCH';
            break;
        }
        break;
      case Urls.CATALOG:
        switch (page) {
          case Urls.ORGANIZATIONS:
            this.placeholder = 'SEARCH_ORGANISATION';
            break;
          case Urls.PROJECTS:
            this.placeholder = 'SEARCH_PROJECT';
            break;
          default:
            this.placeholder = 'SEARCH';
            break;
        }
        break;
      case Urls.GROUPS:
        switch (page) {
          case Urls.GROUPS:
            this.placeholder = 'SEARCH_GROUP';
            break;
          case Urls.ANNOUNCEMENTS_AND_UPDATES:
            this.placeholder = 'SEARCH_ANNOUNCEMENT';
            break;
          case Urls.PROJECTS_DISCUSSED:
            this.placeholder = 'SEARCH_PROJECT';
            break;
          case Urls.ORGANISATIONS_DISCUSSED:
            this.placeholder = 'SEARCH_ORGANISATION';
            break;
          case Urls.MEMBERS:
            this.placeholder = 'SEARCH_USER';
            break;
          case Urls.DOCUMENTS:
            this.placeholder = 'SEARCH_DOCUMENT';
            break;
          default:
            this.placeholder = 'SEARCH';
            break;
        }
        break;
      default:
        this.placeholder = 'SEARCH';
        break;
    }
  }

  onSearchButtonClicked(): void {
    if (!this.opened) {
      this.opened = true;
      this.ref.detectChanges();
      this.searchInput?.nativeElement.focus();
    } else {
      this.search();
    }
  }

  search(): void {
    if (typeof this.inputValue === 'string' && this.inputValue !== this.filterMenu?.searchText) {
      if (this.filterMenu) {
        this.filterMenu.searchText = this.inputValue;
      }
      this.searchEvent.emit(this.inputValue);
      this.searchInput.nativeElement.blur();
    }
  }

  onFocus(): void {
    this.inputFocused = true;
    this.active = true;
    this.onInputFocus.emit();
  }

  onBlur(): void {
    setTimeout(() => {
      if (this.opened && !this.inputValue && !this.globalSearch) {
        this.opened = false;
        this.active = false;
        this.inputFocused = false;
        this.inputValue = '';
        this.search();
      } else if (this.inputValue && this.mouseInside) {
        this.inputFocused = false;
      } else if (!this.mouseInside) {
        this.inputFocused = false;
        this.active = false;
      }
    }, 50);
  }

  toggleActive(hover: boolean): void {
    if (hover) {
      this.active = true;
      this.mouseInside = true;
    } else {
      if (!this.inputFocused) {
        this.active = false;
        this.mouseInside = false;
      } else {
        this.mouseInside = false;
      }
    }
  }

  clear(): void {
    this.inputValue = '';
    if (this.filterMenu?.searchText) {
      this.search();
    }
    this.searchInput.nativeElement.focus();
  }
}
