import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-buttons',
  templateUrl: './shared-buttons.component.html',
  styleUrls: ['./shared-buttons.component.scss'],
})
export class SharedButtonsComponent implements OnInit {
  @Input() shareLink: string;
  @Input() linkTitle: string = '';

  includedSocialMedia: string[] = ['facebook', 'linkedin', 'twitter', 'copy'];

  constructor() {}

  ngOnInit(): void {}
}
