import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Control } from 'src/app/_core/models/FormControls';

@Component({
  selector: 'app-form-control-validation',
  templateUrl: './form-control-validation.component.html',
  styleUrls: ['./form-control-validation.component.scss'],
})
export class FormControlValidationComponent implements OnInit {
  @Input() control: AbstractControl = null;
  @Input() basicControl: Control<any> = null;

  ngOnInit(): void {}
}
