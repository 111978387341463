<div class="w-100 h-100 crop-modal-container" [ngClass]="{ 'banner-input': bannerInputParams }">
  <div
    [hidden]="editingFile"
    class="file-drop h-100 drop-normal align-items-center justify-content-center"
    [ngClass]="{ 'drop-hovered': hasBaseDropZoneOver }"
    data-name="images"
    ng2FileDrop
    (onFileDrop)="fileChangeEvent($event)"
    (fileOver)="changeFileOver($event)"
    [uploader]="uploader"
  >
    <div>
      <img class="d-block m-auto" src="assets/icons/iconfinder_add_images.svg" />
      <div class="main-copy">
        {{ 'SHARED.MODALS.CROP_MODAL.CTA_TEXT' | translate }}
        <label class="browse pointer"
          >{{ 'SHARED.MODALS.CROP_MODAL.CTA_BUTTON' | translate }}
          <input
            id="browseFiles"
            class="d-none"
            type="file"
            ng2FileSelect
            placeholder="Upload file"
            [uploader]="uploader"
            (onFileSelected)="fileChangeEvent($event)"
          />
        </label>
      </div>
    </div>
  </div>

  <div *ngIf="editingFile" class="height">
    <div class="image-cropper-container">
      <image-cropper
        #cropper
        class="crop-zone"
        [imageFile]="image"
        [maintainAspectRatio]="!!aspectRatio"
        [aspectRatio]="aspectRatio"
        [transform]="transform"
        [format]="format"
        [backgroundColor]="format === 'jpeg' ? 'white' : null"
        [imageQuality]="70"
        [onlyScaleDown]="true"
        [resizeToWidth]="1920"
        [autoCrop]="true"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <div class="action-btns d-flex justify-content-center my-2">
        <button class="mat-outline-button gray-border mr-2" mat-stroked-button color="ternary" (click)="zoomIn()">
          {{ 'SHARED.MODALS.CROP_MODAL.ZOOM_IN' | translate }}
        </button>
        <button class="mat-outline-button gray-border" mat-stroked-button color="ternary" (click)="zoomOut()">
          {{ 'SHARED.MODALS.CROP_MODAL.ZOOM_OUT' | translate }}
        </button>
      </div>
    </div>

    <div class="preview-crop-container">
      <img
        class="preview-crop"
        [src]="croppedImages[0]"
        [style.width]="aspectRatio == 1 ? '150px' : '266px'"
        [style.border]="croppedImages ? '1px solid black' : 'none'"
      />
      <div class="text-center font-weight-bold mt-2">{{ 'SHARED.MODALS.CROP_MODAL.RESULT' | translate }}</div>
    </div>
  </div>

  <div class="d-flex flex-column align-items-between buttons-container" *ngIf="image && editingFile">
    <div class="d-flex w-100 justify-content-end mt-3">
      <button
        id="uploadImageButton"
        *ngIf="!bannerInputParams"
        mat-flat-button
        class="mat-main-button mr-2"
        color="accent"
        (click)="emitEvent(cropAction)"
      >
        {{ 'GENERAL.GENERIC_BUTTONS.UPLOAD' | translate }}
      </button>
      <button *ngIf="bannerInputParams" mat-flat-button class="mat-main-button mr-2" color="accent" (click)="uploadImageBanner()">
        {{ 'GENERAL.GENERIC_BUTTONS.UPLOAD' | translate }}
      </button>
      <button mat-flat-button class="mat-main-button border" (click)="close()">{{ 'GENERAL.GENERIC_BUTTONS.CANCEL' | translate }}</button>
    </div>
  </div>
</div>
