import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldSubString',
})
export class BoldSubstringPipe implements PipeTransform {
  transform(value: string, subString: string): string {
    if (!subString) return value;
    const subStringStartPosition: number = value.toLowerCase().indexOf(subString.toLowerCase());
    const subStringEndPosition: number = subStringStartPosition + subString.length;
    if (subStringStartPosition < 0 || subStringEndPosition < 0) return value;
    const preBoldText: string = subStringStartPosition ? value.slice(0, subStringStartPosition) : '';
    const postBoldText: string = subStringEndPosition !== value.length ? value.slice(subStringEndPosition, value.length) : '';
    return `${preBoldText}<b>${value.slice(subStringStartPosition, subStringEndPosition)}</b>${postBoldText}`;
  }
}
