<mat-form-field appearance="outline" class="w-100 admin-input form-field-height-auto">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let selectedItem of selectedItems"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(selectedItem)"
      class="uppercase font-weight-bold ml-2"
    >
      {{ selectedItem?.name }}
      <i matChipRemove class="fas fa-times-circle mt-1"></i>
    </mat-chip>
    <input
      #searchInput
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let filteredItem of filteredList | async" [value]="filteredItem">
      {{ filteredItem?.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
