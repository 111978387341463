import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractArrayFromObject',
})
export class ExtractArrayFromObjectPipe implements PipeTransform {
  transform(value: any, fieldToExtract: string): unknown {
    return value.hasOwnProperty(fieldToExtract) ? value[fieldToExtract] : [];
  }
}
