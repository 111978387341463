import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import Urls from './_core/constants/Urls';
import { NoAuthGuard } from './_core/guards/no-auth.guard';
import { MaintenanceComponent } from './_shared/components/maintenance/maintenance.component';


const routes: Routes = [
  {path: '', redirectTo: Urls.APP, pathMatch: 'full'},
  {
    path: Urls.APP,
    loadChildren: () => import('./internal-app/internal-app.module').then(m => m.InternalAppModule)
  },
  {
    path: Urls.AUTH,
    canLoad: [NoAuthGuard],
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: Urls.MAINTENANCE,
    component: MaintenanceComponent
  },
  {
    path: '**',
    loadChildren: () => import('./internal-app/internal-app.module').then(m => m.InternalAppModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
