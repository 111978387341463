import { Pipe, PipeTransform } from '@angular/core';
import { DigitGroupingKeys } from 'src/app/_core/constants/NumberFormats';
import { GlobalConfigService } from 'src/app/_core/services/global-config.service';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  constructor(private globalConfigSvc: GlobalConfigService) {}

  transform(value: string | number, currencySymbol: string = ''): string | number {
    const decimalSymbol = this.globalConfigSvc.globalConfig.decimalSymbol;
    const digitGroupingSymbol = this.globalConfigSvc.globalConfig.digitGroupingSymbol;
    const digitGrouping = this.globalConfigSvc.globalConfig.digitGrouping;

    const cleanValue = typeof value === 'number' ? value.toFixed(2).toString() : (+value).toFixed(2).toString();
    const splitCleanValue = cleanValue.toString().split('.');
    const valueWholeNumber = splitCleanValue[0];

    let formattedValue = '';

    switch (digitGrouping.key) {
      case DigitGroupingKeys.DIGIT_GROUPING_0:
        formattedValue = valueWholeNumber;
        break;
      case DigitGroupingKeys.DIGIT_GROUPING_1:
        {
          let valueWholeNumberLength = valueWholeNumber.length;

          if (valueWholeNumberLength <= 3) {
            formattedValue = valueWholeNumber;
          } else {
            let insertSymbolForEveryNChars = 3;
            let counter = 3;
            let startSubStringIndex = valueWholeNumberLength;
            let endIndex = valueWholeNumberLength;
            let valueChunks: string[] = [];

            while (endIndex > 0) {
              const valueChunk = valueWholeNumber.slice(startSubStringIndex - counter, endIndex);
              valueChunks.push(valueChunk);
              endIndex -= insertSymbolForEveryNChars;
              counter += endIndex < insertSymbolForEveryNChars ? endIndex : insertSymbolForEveryNChars;
            }
            formattedValue = valueChunks.reverse().join(digitGroupingSymbol.value);
          }
        }
        break;
      case DigitGroupingKeys.DIGIT_GROUPING_2:
        {
          const valueWholeNumberLength = valueWholeNumber.length;
          if (valueWholeNumberLength <= 3) {
            formattedValue = valueWholeNumber;
          } else {
            formattedValue =
              valueWholeNumber.slice(0, valueWholeNumberLength - 3) +
              digitGroupingSymbol.value +
              valueWholeNumber.slice(valueWholeNumberLength - 3, valueWholeNumberLength);
          }
        }
        break;
      case DigitGroupingKeys.DIGIT_GROUPING_3:
        {
          let valueWholeNumberLength = valueWholeNumber.length;

          if (valueWholeNumberLength <= 3) {
            formattedValue = valueWholeNumber;
          } else {
            let insertSymbolForEveryNChars = 3;
            let counter = 3;
            let startSubStringIndex = valueWholeNumberLength;
            let endIndex = valueWholeNumberLength;
            let valueChunks: string[] = [];

            while (endIndex > 0) {
              const valueChunk = valueWholeNumber.slice(startSubStringIndex - counter, endIndex);
              valueChunks.push(valueChunk);
              endIndex -= insertSymbolForEveryNChars;
              insertSymbolForEveryNChars = 2;
              counter += endIndex < insertSymbolForEveryNChars ? endIndex : insertSymbolForEveryNChars;
            }
            formattedValue = valueChunks.reverse().join(digitGroupingSymbol.value);
          }
        }
        break;
      default:
        formattedValue = formattedValue;
        break;
    }
    formattedValue = `${currencySymbol}${formattedValue}${decimalSymbol.value}${splitCleanValue[1]}`;

    return formattedValue;
  }
}
