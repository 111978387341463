export const LANGUAGE_STATUS = {
  LIVE: 'LIVE',
  DRAFT: 'DRAFT',
};

export enum LANGUAGE_ACTIONS {
  SAVE_DRAFT,
  SAVE_CHANGES,
  PUBLISH,
  UNPUBLISH,
  DELETE,
}
