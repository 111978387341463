<div class="modal-container">
  <div class="text-right mb-4">
    <a><img (click)="close()" class="close-icon pointer" src="../../../assets/icons/clear-icon.svg" /></a>
  </div>
  <div class="position-relative" *ngIf="viewer; else notViewer">
    <ng-container *ngIf="!loaded"> {{ 'GENERAL.GENERIC_LABELS.LOADING' | translate }}</ng-container>
    <div class="doc-viewer">
      <ngx-doc-viewer [url]="story" viewer="google" style="width: 100%; height: 50vh"></ngx-doc-viewer>
    </div>
  </div>
  <ng-template #notViewer>
    <div class="h-100 d-flex flex-column justify-content-start">
      <div class="story-header">{{ header }}</div>
      <p class="story-body break-word text-justify pb-4">{{ story }}</p>
    </div>
  </ng-template>
</div>
