import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  // prettier-ignore
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) { }

  success(messagePath: string[], params?: { [k: string]: string | number }): void {
    this.translateService
      .get(messagePath, params)
      .pipe(first())
      .subscribe((text: { [k: string]: string }) => this.toastrService.success(text[messagePath[0]], text[messagePath[1]]));
  }

  error(messagePath: string[], params?: { [k: string]: string | number }): void {
    this.translateService
      .get(messagePath, params)
      .pipe(first())
      .subscribe((text: { [k: string]: string }) => this.toastrService.error(text[messagePath[0]], text[messagePath[1]]));
  }

  info(messagePath: string[], params?: { [k: string]: string | number }): void {
    this.translateService
      .get(messagePath, params)
      .pipe(first())
      .subscribe((text: { [k: string]: string }) => this.toastrService.info(text[messagePath[0]], text[messagePath[1]]));
  }
}
