import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToString',
})
export class EnumToStringPipe implements PipeTransform {
  transform(value: string): string {
    return value && value.length ? value.split('_').join(' ') : '';
  }
}
