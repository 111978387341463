import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MODAL_ACTIONS, MODAL_RESPONSE } from 'src/app/_core/constants/Modals';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-share-buttons-modal',
  templateUrl: './share-buttons-modal.component.html',
  styleUrls: ['./share-buttons-modal.component.scss'],
})
export class ShareButtonsModalComponent implements OnInit, OnDestroy {
  shareLink: string;
  linkTitle: string = '';
  payload: any;
  action: MODAL_ACTIONS;
  breakpointSubscription: Subscription;

  constructor(private modalsService: ModalsService, private breakpointObserver: BreakpointObserver, private dialogRef: MatDialogRef<any>) {}

  ngOnInit(): void {
    this.shareLink = this.modalsService.params.shareLink;
    this.linkTitle = this.modalsService.params.linkTitle;
    this.payload = this.modalsService.params.payload;
    this.action = this.modalsService.params.action;

    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 500px)']).subscribe((result) => {
      result.matches ? this.dialogRef.updateSize('100vw', 'auto') : this.dialogRef.updateSize('500px', 'auto');
    });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  emitTrue(): void {
    this.modalsService.emitResponse({
      confirmed: true,
      action: this.action,
      payload: this.payload,
      closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS,
    });
  }

  emitFalse(): void {
    this.modalsService.emitResponse({
      confirmed: false,
      action: null,
      payload: null,
    });
    this.modalsService.closeModal();
  }
}
