import { CONTROL_TYPES, GSControl, GSTypedGroup, Media, VALUE_TYPES } from '../FormControls';
import { GSFormGroup } from './GSFormGroup';
import { GSFormControl } from './GSFormControl';
import { GSFormArray } from './GSFormArray';
import { UUIDName } from '../GenericObject';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../helpers/CustomValidators.helpers';
import { PORTFOLIO_CONTROLS } from '../../constants/PortfolioFormControls';

export type ArrayItem = { position: number; [k: string]: any };

export type AbstractGSControl<T = any> = GSFormControl<T> | GSFormArray<T> | GSFormGroup<T>;

const POSITION = 'position';

// prefillValue object must have the same interface as the Form's structure otherwise it won't know what values to prefill
export function generateGSFormElement<T>(gsControl: GSControl, prefillValue?: any): AbstractGSControl<T> {
  switch (gsControl.controlType) {
    case CONTROL_TYPES.FORM_GROUP:
      let group: GSTypedGroup<any> = {};
      Object.keys(gsControl.controls).forEach((key) => {
        group = { ...group, [key]: generateGSFormElement({ ...gsControl.controls[key], key }, prefillValue?.[key]) };
      });
      return new GSFormGroup<T>(gsControl, group);
    case CONTROL_TYPES.FORM_CONTROL:
      const validators = gsControl.profile?.mandatory
        ? [
            ...(gsControl.validators ?? []),
            gsControl.valueType === VALUE_TYPES.MEDIA
              ? CustomValidators.imageOrVideoRequired(PORTFOLIO_CONTROLS.IMAGE, PORTFOLIO_CONTROLS.VIDEO)
              : Validators.required,
          ]
        : gsControl.validators;
      gsControl.validators = validators;
      return new GSFormControl(gsControl, prefillValue);
    case CONTROL_TYPES.FORM_ARRAY:
      const arrayValidators = gsControl.profile?.mandatory ? [...(gsControl.validators ?? []), Validators.required] : gsControl.validators;
      gsControl.validators = arrayValidators;
      const gsFormArray: GSFormArray<T> = new GSFormArray<T>(gsControl);
      const gsFormArrayItem = { ...Object.values(gsControl.controls)[0], key: 'item' };
      if (Array.isArray(prefillValue) && prefillValue.length > 0) {
        prefillValue.forEach((item: ArrayItem) => {
          gsFormArray.push(generateGSFormElement(gsFormArrayItem, item));
        });
      } else if (gsControl.min > 0) {
        gsFormArray.push(generateGSFormElement(gsFormArrayItem));
      }
      return gsFormArray;
  }
}

export function getGSControlPath(gsControl: AbstractGSControl): string {
  let path = gsControl.key;
  while (gsControl.parent && ((gsControl.parent as AbstractGSControl).key || gsControl.parent.get(POSITION)?.value)) {
    gsControl = gsControl.parent as AbstractGSControl;
    let key = '';
    if (gsControl instanceof GSFormGroup && gsControl.get(POSITION)?.value) {
      key = `[${gsControl.get(POSITION).value}]`;
    } else {
      key = gsControl.key;
    }
    path = key + '.' + path;
  }
  return path;
}

export function extractModifiedValues(gsControl: AbstractGSControl): string[] {
  let modifiedFields = [];
  if (gsControl instanceof GSFormGroup) {
    Object.values(gsControl.controls).forEach((control: AbstractGSControl) => {
      modifiedFields = [...modifiedFields, ...extractModifiedValues(control)];
    });
  } else if (gsControl instanceof GSFormControl) {
    if (gsControl.defaultValue instanceof UUIDName || gsControl.value instanceof UUIDName) {
      const initialValue = gsControl.defaultValue?.name;
      const currentValue = gsControl.value?.name;
      if (initialValue !== currentValue) {
        modifiedFields = [`${getGSControlPath(gsControl)}: changed from "<b>${initialValue || 'EMPTY'}</b>" TO "<b>${currentValue}</b>"`];
      }
    } else if (gsControl.defaultValue instanceof Media || gsControl.value instanceof Media) {
      const initialValue = gsControl.defaultValue?.fileName;
      const currentValue = gsControl.value?.fileName;
      if (initialValue !== currentValue) {
        modifiedFields = [`${getGSControlPath(gsControl)}: changed from "<b>${initialValue || 'EMPTY'}</b>" TO "<b>${currentValue || 'EMPTY'}</b>"`];
      }
    } else if (Array.isArray(gsControl.defaultValue) || Array.isArray(gsControl.value)) {
      if (gsControl.defaultValue?.length || gsControl.value?.length) {
        if (gsControl.defaultValue?.[0] instanceof UUIDName || gsControl.value?.[0] instanceof UUIDName) {
          const initialValue = gsControl.defaultValue?.map((item) => item.name).join(', ');
          const currentValue = gsControl.value?.map((item) => item.name).join(', ');
          if (initialValue !== currentValue) {
            modifiedFields = [
              `${getGSControlPath(gsControl)}: changed from "<b>${initialValue || 'EMPTY'}</b>" to "<b>${currentValue || 'EMPTY'}</b>" `,
            ];
          }
        }
      }
    } else if (gsControl.value !== gsControl.defaultValue) {
      modifiedFields = [`${getGSControlPath(gsControl)}: changed from "<b>${gsControl.defaultValue || 'EMPTY'}</b>" to "<b>${gsControl.value}</b>"`];
    }
  } else if (gsControl instanceof GSFormArray) {
    if (gsControl.defaultValue?.length > gsControl.value?.length) {
      gsControl.defaultValue.forEach((_item, index) => {
        if (!gsControl.value?.[index]) {
          modifiedFields = [...modifiedFields, `${getGSControlPath(gsControl as AbstractGSControl)}.[${index + 1}]: REMOVED`];
        }
      });
    }
    Object.values(gsControl.controls).forEach((control: AbstractGSControl) => {
      modifiedFields = [...modifiedFields, ...extractModifiedValues(control)];
    });
  }
  return modifiedFields;
}

export function extractInvalidFields(gsControl: AbstractGSControl): string[] {
  if (gsControl instanceof GSFormControl) {
    if (gsControl.invalid) {
      return [gsControl.label];
    }
  } else if (gsControl instanceof GSFormGroup) {
    let invalidFields = [];
    if (gsControl.invalid) {
      if (gsControl.label) {
        invalidFields.push(gsControl.label);
      }
      Object.values(gsControl.controls).forEach((control: AbstractGSControl) => {
        invalidFields = [...invalidFields, ...extractInvalidFields(control)];
      });
    }
    return invalidFields;
  } else if (gsControl instanceof GSFormArray) {
    if (gsControl.invalid) {
      return [gsControl.label];
    }
  }
  return [];
}
