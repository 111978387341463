import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationData } from 'src/app/_core/models/GenericObject';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.scss'],
})
export class MainButtonComponent implements OnInit {
  @Input() navigationData?: NavigationData;
  @Input() isLink: boolean = false;
  @Input() addNewIcon: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() url?: string;
  @Input() text: string;
  @Input() order: number;

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  buttonId: string;

  constructor() {}

  ngOnInit(): void {
    this.buttonId = this.text.split('.').reverse()[0].toLowerCase() + (this.order ? `-${this.order}` : '');
  }
}
