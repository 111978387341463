import { FILTER_NAME } from '../../constants/Filters/FilterTypes';
import { CodeValue, Enum, UUIDName } from '../GenericObject';

export interface Filters {
  regions?: Option[];
  categories?: Option[];
  champions?: Option[];
  sortOptions?: string[];
  projects?: Option[];
  organisations?: Option[];
  countries?: Option[];
  causes?: Option[];
}

export type Option = {
  uuid: string;
  name: string;
};

/**
 *  `generateFilterOptions` is an Adapter function that is used to parse raw data,
 *  and covert it to an object of key-value pairs of type FilterOption.
 *
 *  The return value of this function is used by the `Filter` class to handle options.
 *
 * @param data can be of type Option[], UUIDName[], CodeValue[], CodeValue, Enum.
 */
export function generateFilterOptions(data): { [k: string]: FilterOption } {
  const result = {};
  if (data.length && data[0].hasOwnProperty('code') && data[0].hasOwnProperty('value')) {
    (data as CodeValue[]).forEach((item: CodeValue) => Object.assign(result, { [item.code]: new FilterOption(item.code, item.value) }));
  } else if (data.length && data[0].hasOwnProperty('uuid') && data[0].hasOwnProperty('name')) {
    (data as UUIDName[]).forEach((item: UUIDName) => {
      Object.assign(result, { [item.name]: new FilterOption(item.name, item.uuid) });
      if (item.hasOwnProperty('imageUrl')) {
        result[item.name].addImage(item['imageUrl']);
      }
    });
  } else if (data.hasOwnProperty('code') && data.hasOwnProperty('value')) {
    Object.assign(result, { [data.code]: new FilterOption(data.code, data.value) });
  } else {
    Object.keys(data).forEach((key) => Object.assign(result, { [key]: new FilterOption(key, data[key]) }));
  }
  return result;
}

export class Filter {
  name: FILTER_NAME;
  data: { [k: string]: FilterOption };
  unique: boolean;
  adminOnly: boolean;
  singleValue: boolean;
  filterAsInput: boolean;

  // prettier-ignore
  /**
   * Instantiates a `Filter` class that is responsible for a single filter on a page,
   * and is being only instantiated via the 'PageFilters' class.
   *
   * @param name declare a unique filter name for mapping the translated name of the filter.
   * @param data the raw data that needs to be made into options for a filter.
   * Can be of type UUIDName[], CodeValue[], CodeValue, Enum.
   * The items for this parameter are generated through {@link generateFilterOptions}
   * @param options extra configuration to handle the options of this filter differently.
   *
   * @param options.unique if true, only one selection can be marked as checked at a time.
   * @param options.adminOnly boolean that would mark this filter as being available only for admin.
   * @param options.singleValue if true, the filter will be hidden, and the option will be marked as checked,
   *  {@link extractValue} function will return the value of the option as a primitive. Usually used for single
   *  option filters.
   * @param options.input if true, the FilterOption will be treated as an input
   * @see {@link generateFilterOptions} to generate the items for the data parameter
   */
  constructor(
    name: FILTER_NAME,
    data: Option[] | UUIDName[] | CodeValue[] | CodeValue | Enum = {},
    options: {
      unique?: boolean,
      adminOnly?: boolean,
      singleValue?: boolean,
      filterAsInput?: boolean
    } = {}) {
    this.name = name;
    this.data = generateFilterOptions(data || {});
    this.unique = options.unique || false;
    this.adminOnly = options.adminOnly;
    this.singleValue = options.singleValue || false;
    this.filterAsInput = options.filterAsInput || false;
    if (this.singleValue) {
      this.options[0].markDefaultAsChecked();
    }
  }

  selectOption(option: FilterOption): void {
    if (this.unique) {
      this.options.forEach((i: FilterOption) => (i.code === option.code ? option.toggleCheck() : (i.checked = false)));
    } else {
      option.toggleCheck();
    }
  }

  resetFilter(): void {
    this.options.forEach((option: FilterOption) => {
      option.checked = option.defaultCheck;
      if (this.filterAsInput) {
        this.options[0] = null;
      }
    });
  }

  /**
   * Return an array with the values of the checked options of this filter.
   */
  extractValue(): any {
    const result = this.options.filter((item: FilterOption) => item.checked || this.singleValue).map((item: FilterOption) => item.value);
    return this.singleValue ? result[0] : result;
  }

  get options(): FilterOption[] {
    return Object.values(this.data) || [];
  }
}

export class FilterOption {
  code: string;
  value: string | number | boolean;
  checked: boolean;
  defaultCheck: boolean;
  readonly: boolean;
  imageUrl: string;

  constructor(code: string, value: string | number | boolean = null, checked: boolean = false) {
    this.code = code;
    this.value = value !== undefined ? value : code;
    this.checked = checked;
    this.readonly = false;
    this.defaultCheck = false;
  }

  addImage(imageUrl: string) {
    this.imageUrl = imageUrl;
  }

  toggleCheck(): void {
    this.checked = !this.checked;
  }

  markAsChecked(): void {
    this.checked = true;
  }

  /**
   * `markDefaultAsChecked` function would make the default state of this option to be checked.
   * For example, resetFilters function would leave this option as checked.
   */
  markDefaultAsChecked(): void {
    this.defaultCheck = true;
    this.checked = true;
  }
}

export class SortOptionFilter {
  field: string;
  order: string;
  checked: boolean;

  constructor(field: string, order: string = 'ASC', checked: boolean = false) {
    this.field = field;
    this.order = order;
    this.checked = checked;
  }
}

export class BaseFilters {
  sortOptions: SortOptionFilter[];
  page: number;
  size: number;
  searchText: string;
  defaultSort: string;

  constructor(sortOptions: string[], size = 10) {
    this.sortOptions = sortOptions.map((i) => new SortOptionFilter(i));
    this.page = 0;
    this.size = size;
    this.searchText = '';
    this.defaultSort = 'ASC';
  }

  resetFilters(): void {
    this.size = 30;
    this.page = 0;
    this.searchText = '';
    this.setDefaultSort();
  }

  sortSelected(): boolean {
    return this.sortOptions.some((el) => el.checked);
  }

  setDefaultSort(index: number = 0): void {
    this.sortOptions[index].checked = true;
    this.sortOptions[index].order = this.defaultSort;
  }

  setSort(value: string): SortOptionFilter {
    for (const sortOption of this.sortOptions) {
      if (sortOption.field === value) {
        if (value === 'DATE_POSTED') {
          sortOption.order = 'DESC';
        } else {
          sortOption.order = 'ASC';
        }
        sortOption.checked = true;
        return sortOption;
      } else {
        sortOption.checked = false;
      }
    }
    return null;
  }

  extractSortOptions(): any {
    const sortOption = this.sortOptions.find((i) => i.checked === true);
    return sortOption ? sortOption : {};
  }
}

export type IPageFilters<T = any> = { [k in keyof T]: Filter };

export class PageFilters<T> extends BaseFilters {
  filters: IPageFilters<T>;

  constructor(filters, TCreator: new (filters) => T, size = 10) {
    super(filters.sortOptions, size);
    this.filters = new TCreator(filters) as unknown as IPageFilters<T>;
    this.setDefaultSort();
  }

  override resetFilters(): void {
    super.resetFilters();
    for (const key in this.filters) {
      if (this.filters.hasOwnProperty(key)) {
        this.filters[key].resetFilter();
      }
    }
  }
}
