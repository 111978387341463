import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ActivityActionType } from '../constants/Activity';

// tslint:disable:max-line-length
export default class FormUtils {
  public static updateValueAndValidator(control: AbstractControl, validators: ValidatorFn[], value?: any): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
    if (value) {
      control.patchValue(value);
    }
  }

  public static clearValueAndValidator(control: AbstractControl | FormArray, array?: boolean) {
    array ? control.patchValue([]) : control.patchValue(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public static toggleAllSelection(control: AbstractControl, listOrObject: any, option: MatOption, object = false): void {
    if (option.selected) {
      if (object) {
        const uuidArray = [];
        for (const item of Object.keys(listOrObject)) {
          uuidArray.push(listOrObject[item].uuid);
        }
        control.patchValue([...uuidArray]);
      } else {
        control.patchValue([...listOrObject.map((item) => item.uuid || item.code || item.name || item)]);
      }
    } else {
      control.patchValue([]);
    }
  }

  public static allSelectedAfterOptionClick(control: AbstractControl, filters: ActivityActionType[] = []): void {
    if (control.value?.length < filters.length && control.value.indexOf('ALL')) {
      const controlValue = [...control.value];
      controlValue.splice(control.value.indexOf('ALL'), control.value.indexOf('ALL') + 1);
      control.patchValue(controlValue);
    } else if (control.value?.length && control.value?.length === filters.length - 1 && !control.value.indexOf('ALL')) {
      const controlValue = [...control.value];
      controlValue.push('ALL');
      control.patchValue(controlValue);
    }
  }

  public static errorDifferentThanRequired(controlErrors: any) {
    for (const error in controlErrors) {
      if (error !== 'required' && error !== 'minArrayLength' && error !== 'actualYearMissing') {
        return true;
      }
    }
    return false;
  }
}
