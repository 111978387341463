import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Urls from '../../../_core/constants/Urls';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  @Input() errorStatus = 404;

  errorTitle: string;
  errorMessage: string;
  errorPic: string;

  // prettier-ignore
  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.translateService.get('SHARED.COMPONENTS.ERROR_COMP').subscribe((res: { [k: string]: string }) => {
      switch (this.errorStatus) {
        case 404:
          this.errorTitle = res['404_TITLE'];
          this.errorMessage = res['404_MESSAGE'];
          this.errorPic = 'https://fdi-files.s3.amazonaws.com/public/404.svg';
          break;
        case 401:
          this.errorTitle = res['401_TITLE'];
          this.errorMessage = res['401_MESSAGE'];
          this.errorPic = 'https://fdi-files.s3.amazonaws.com/public/401.svg';
          break;
        case 403:
          this.errorTitle = res['403_TITLE'];
          this.errorMessage = res['403_MESSAGE'];
          this.errorPic = 'https://fdi-files.s3.amazonaws.com/public/403.svg';
          break;
        case 500:
          this.errorTitle = res['500_TITLE'];
          this.errorMessage = res['500_MESSAGE'];
          this.errorPic = 'https://fdi-files.s3.amazonaws.com/public/404.svg';
          break;
      }
    });
  }

  goHome() {
    this.router.navigate([Urls.BASE]);
  }
}
