export enum Modals {
  COMPLETE_REGISTRATION,
  MEDIA,
  DELETE_CONFIRMATION,
  STORY,
  EDIT_USER,
  CROP_IMAGE,
  INFO_MESSAGE,
  ADD_NEW,
  ADD_NEW_FUND,
  CANCEL_CHANGES,
  CONTACT_INVEST,
  HOW_CAN_WE_HELP,
  ACCREDITED_INVESTOR_TERMS,
  LEGAL,
  WELCOME_POPUP_IMAGE,
  MEET_THE_INVESTORS_RESTRICTED,
  MAKE_PROJECT_LIVE,
  INVITE_USERS,
  POST_INVITE_USERS,
  POST_COMPLETE_REGISTRATION,
  PROJECT_TYPE_MEDIA,
  COMMIT_TO_GIVE,
  SUBMIT_PROJECT_ORGANIZATION,
  ADD_GROUP_MEMBER,
  POST_ADD_GROUP_MEMBER,
  ADD_GROUP_PROJECT,
  SELECT_LANGUAGE,
  SEARCH_LANGUAGE,
  ADD_GROUP_PORTFOLIO,
  ORGANISATION_SETTINGS,
  REPORT_ABUSE,
  DECLINE_REASON,
  SHARE_BUTTON,
  INFO_MODAL,
  URL_REDIRECT,
  DECLINE_MODAL,
}

export const MODALS_STYLES = [
  {
    name: Modals.COMPLETE_REGISTRATION,
    style: {
      width: '500px',
      height: '500px',
      autoFocus: false,
    },
  },
  {
    name: Modals.MEDIA,
    style: {
      width: '1280px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.PROJECT_TYPE_MEDIA,
    style: {
      width: '1280px',
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
    },
  },
  {
    name: Modals.DELETE_CONFIRMATION,
    style: {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.STORY,
    style: {
      width: '980px',
      height: '557px',
      autoFocus: false,
    },
  },
  {
    name: Modals.EDIT_USER,
    style: {
      width: '630px',
      height: 'auto',
      maxHeight: '90vh',
      maxWidth: '90vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.CROP_IMAGE,
    style: {
      width: '750px',
      height: '460px',
      autoFocus: false,
    },
  },
  {
    name: Modals.INFO_MESSAGE,
    style: {
      width: '505px',
      height: '296px',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_NEW,
    style: {
      width: '410px',
      height: '340px',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_NEW_FUND,
    style: {
      width: '410px',
      height: '270px',
      autoFocus: false,
    },
  },
  {
    name: Modals.CANCEL_CHANGES,
    style: {
      width: '450px',
      height: '300px',
      autoFocus: false,
    },
  },
  {
    name: Modals.CONTACT_INVEST,
    style: {
      width: '600px',
      height: '720px',
      autoFocus: false,
    },
  },
  {
    name: Modals.HOW_CAN_WE_HELP,
    style: {
      width: '500px',
      height: '720px',
      autoFocus: false,
    },
  },
  {
    name: Modals.ACCREDITED_INVESTOR_TERMS,
    style: {
      width: '600px',
      height: '720px',
      autoFocus: false,
    },
  },
  {
    name: Modals.LEGAL,
    style: {
      width: '880px',
      maxHeight: '720px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.WELCOME_POPUP_IMAGE,
    style: {
      maxWidth: '100vw',
      maxHeight: '90vh',
      minWidth: '300px',
      width: 'auto',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.MEET_THE_INVESTORS_RESTRICTED,
    style: {
      width: '410px',
      height: '270px',
      autoFocus: false,
    },
  },
  {
    name: Modals.MAKE_PROJECT_LIVE,
    style: {
      width: '400px',
      height: '230px',
      autoFocus: false,
    },
  },
  {
    name: Modals.INVITE_USERS,
    style: {
      width: '650px',
      height: 'auto',
      maxHeight: '710px',
      autoFocus: false,
    },
  },
  {
    name: Modals.POST_INVITE_USERS,
    style: {
      width: '505px',
      height: '575px',
      autoFocus: false,
    },
  },
  {
    name: Modals.POST_COMPLETE_REGISTRATION,
    style: {
      width: '580px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.COMMIT_TO_GIVE,
    style: {
      width: '580px',
      height: 'auto',
      maxHeight: '90vh',
      maxWidth: '90vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.SUBMIT_PROJECT_ORGANIZATION,
    style: {
      width: '380px',
      height: 'auto',
      maxHeight: '96vh',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_GROUP_MEMBER,
    style: {
      width: '600px',
      height: 'auto',
      maxHeight: '710px',
      autoFocus: false,
    },
  },
  {
    name: Modals.POST_ADD_GROUP_MEMBER,
    style: {
      width: '505px',
      height: '575px',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_GROUP_PROJECT,
    style: {
      width: '700px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.SELECT_LANGUAGE,
    style: {
      width: '700px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.SEARCH_LANGUAGE,
    style: {
      width: '500px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_GROUP_PORTFOLIO,
    style: {
      width: '700px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.ORGANISATION_SETTINGS,
    style: {
      width: '550px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.REPORT_ABUSE,
    style: {
      width: '650px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.DECLINE_REASON,
    style: {
      width: '550px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.SHARE_BUTTON,
    style: {
      width: '550px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.INFO_MODAL,
    style: {
      width: '520px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.URL_REDIRECT,
    style: {
      width: '520px',
      height: 'auto',
      autoFocus: false,
    },
  },
  {
    name: Modals.DECLINE_MODAL,
    style: {
      width: '650px',
      height: 'auto',
      autoFocus: false,
    },
  },
];

export enum MODAL_ACTIONS {
  DELETE,
  CROP,
  ADD_DEAL,
  DELETE_COMPANY,
  DELETE_DEAL,
  DELETE_USER,
  ARCHIVE_USER,
  RESTORE_USER,
  DELETE_INVITATION,
  RESEND_INVITATION,
  INVITE_USER,
  DELETE_FILE,
  DELETE_SURVEY,
  DELETE_ACCOUNT,
  DELETE_QUESTION,
  CANCEL_CHANGES,
  CONTACT_COMPANY,
  HOW_CAN_WE_HELP,
  ACCREDITED_INVESTOR_OPTIONS,
  DELETE_FIRM,
  DELETE_ORGANIZATION,
  DELETE_FUND,
  MAKE_PROJECT_LIVE,
  WAITING_ROOM_CHANGED,
  DELETE_PROJECT,
  CLOSE,
  COMMIT_TO_GIVE,
  DELETE_GROUP,
  ARCHIVE_GROUP,
  SUBMIT_PROJECT_ORGANIZATION,
  ADD_GROUP_MEMBER,
  UPDATE_GROUP_PROJECTS,
  UPDATE_GROUP_ORGANISATIONS,
  GROUP_LEAVE_CONFIRM,
  CHANGE_DEFAULT_ENTITY_PROFILE,
  DELETE_ENTITY_PROFILE,
  SEARCH_LANGUAGE,
  TRANSLATION_ACTION,
  SELECT_PRIMARY_PROJECT_LANGUAGE,
  ADD_PROJECT_LANGUAGE,
  EDIT_PROJECT_LANGUAGE,
  ADD_ORGANISATION_LANGUAGE,
  EDIT_ORGANISATION_LANGUAGE,
  SELECT_PRIMARY_ORGANISATION_LANGUAGE,
  SYNC_MISSING_STRINGS,
  CONFIRM_EMAIL_UPDATE,
  CONFIRM_ENABLE_CONTROL,
  CONFIRM_REMOVE_CHAMPION,
  SELECT_PRIMARY_PORTFOLIO_LANGUAGE,
  DELETE_PORTFOLIO,
  UPDATE_GROUP_PORTFOLIOS,
  SAVE_GLOBAL_CONFIG_CHANGES,
  LANGUAGE_IMPORT_OVERWRITTEN,
  LANGUAGE_EXPORT_UNSAVED_CHANGES,
  SAVE_ORGANISATION_SETTINGS,
  CHANGE_STATUS,
  ARCHIVE_PROJECT,
  UNARCHIVE_PROJECT,
  DECLINE_PROJECT,
  ARCHIVE_ORGANISATION,
  UNARCHIVE_ORGANISATION,
  DECLINE_ORGANISATION,
  CANCEL_CHANGES_HOMEPAGE_FOCUS,
  DELETE_REGION,
  DELETE_CAUSE,
  URL_REDIRECT,
  DELETE_CATEGORY,
  DELETE_HELP_VIDEO,
  CONFIRM_DECLINE,
  DELETE_CHAMPION_FILE,
}

export enum MODAL_RESPONSE {
  CLOSE = 'close',
  CLOSE_ALL = 'closeAll',
  CLOSE_SUCCESS = 'closeSuccess',
}
