import { Component, OnInit } from '@angular/core';
import { MODAL_ACTIONS, MODAL_RESPONSE } from 'src/app/_core/constants/Modals';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import { GSControl } from 'src/app/_core/models/FormControls';
import { GSFormControl } from 'src/app/_core/models/forms/GSFormControl';
import { EntityMainInfo } from 'src/app/_core/models/GenericObject';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-decline-entity',
  templateUrl: './decline-entity.component.html',
  styleUrls: ['./decline-entity.component.scss'],
})
export class DeclineEntityComponent implements OnInit {
  reason: GSFormControl;
  action: MODAL_ACTIONS;
  entity: EntityMainInfo;

  // prettier ignore
  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.createControl();
    this.action = this.modalsService.params.action;
    this.entity = this.modalsService.params.entity;
  }

  createControl(): void {
    this.reason = new GSFormControl(
      new GSControl({
        validators: [CustomValidators.required],
        label: 'ADMIN.ENTITIES_OPTIONS.DECLINE.LABEL',
        optionalText: 'GROUPS.GROUP_DETAILS.REPORT_ABUSE.LABELS.MAX_CHARACTERS',
        max: 1000,
        errors: [{ type: 'required', text: 'ADMIN.ENTITIES_OPTIONS.DECLINE.REQUIRED_REASON_ERROR' }],
      }),
      null
    );
  }

  emitTrue(): void {
    this.reason.markAsTouched();
    if (this.reason.invalid) return;
    this.modalsService.emitResponse({
      action: this.action,
      payload: {
        entity: this.entity,
        reason: this.reason.value,
      },
      closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS,
    });
  }

  close(): void {
    this.modalsService.closeAll();
  }
}
