import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecaseName',
})
export class TitlecaseNamePipe implements PipeTransform {
  transform(value: string): string {
    let value1 = '';
    let arr: string[];

    arr = value.split(' ');

    arr.forEach((string) => (value1 = value1 + string.charAt(0).toUpperCase() + string.substring(1) + ' '));

    arr = value1.split('-');

    value = arr[0];
    arr.splice(0, 1);

    arr.forEach((string) => (value = value + '-' + string.charAt(0).toUpperCase() + string.substring(1)));

    return value;
  }
}
