export class CustomAddress {
  streetName: string;
  streetNumber: string;
  country: string;
  countryCode: string;
  stateProvince: string;
  city: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  fullAddress: string;
  uuid: string;
  zipCode: string;

  constructor(
    streetName: string,
    streetNumber: string,
    country: string,
    countryCode: string,
    stateProvince: string,
    city: string,
    latitude: number,
    longitude: number,
    postalCode: string,
    fullAddress: string,
    zipCode: string
  ) {
    this.streetName = streetName;
    this.streetNumber = streetNumber;
    this.country = country;
    this.countryCode = countryCode;
    this.stateProvince = stateProvince;
    this.city = city;
    this.latitude = latitude;
    this.longitude = longitude;
    this.postalCode = postalCode;
    this.fullAddress = fullAddress;
    this.zipCode = zipCode;
  }
}
