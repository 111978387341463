import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { catchError, Observable, of, tap } from 'rxjs';
import { LinkChampionPayload, LinkChampionPayload2 } from '../../internal-app/details/champion/models/Champion';
import { ToastService } from '../services/toast.service';
import { ToastrMessages } from '../constants/ToastrMessages';
import { ServerError } from '../constants/ServerError';

@Injectable({
  providedIn: 'root',
})
export class ChampionService {
  private readonly resourceUrl: string = '/v1/champion';
  private readonly championTestimonialResourceUrl: string = '/v1/admin/champion-testimonial';

  // prettier-ignore
  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
  ) {
  }

  removeChampionFromEntity(body): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/link-champion`, null, body);
  }

  linkChampionToEntity(body: LinkChampionPayload): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/link-champion`, body);
  }

  linkChampionToOrganizationAsDraft(payload: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/link-organisation-save`, payload);
  }

  linkChampionToOrganization(payload: LinkChampionPayload2): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/link-organisation-submit`, payload);
  }

  getMyChampioned(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/my-championed`, body, { languageCode: body.languageCode });
  }

  getChampionDetails(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/link-champion/entity/details`, body);
  }

  getChampionDraft(payload: { uuid: string; uuid2: string }): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-testimonial-draft`, payload).pipe(
      tap((res) => res),
      catchError((error) => {
        switch (error) {
          case ServerError.GIVINGSPACE_CHAMPION_ORGANISATION_LINKED_NOT_FOUND:
            return of(null);
          default:
            this.toastService.error(ToastrMessages.BASIC_ERROR);
            return of(null);
        }
      })
    );
  }

  getChampionTestimonials(payload: { statusList: string[]; size: number; page: number; searchText: string }): Observable<any> {
    return this.apiService.post(`${this.championTestimonialResourceUrl}s`, payload);
  }

  getChampionViewDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.championTestimonialResourceUrl}/${uuid}`);
  }

  declineChampionTestimonial(payload: { uuid: string; string1: string }): Observable<any> {
    return this.apiService.post(`${this.championTestimonialResourceUrl}-decline`, payload);
  }

  approveChampionTestimonial(uuid: string): Observable<any> {
    return this.apiService.post(`${this.championTestimonialResourceUrl}-approve`, { uuid });
  }

  archiveChampionTestimonial(uuid: string): Observable<any> {
    return this.apiService.post(`${this.championTestimonialResourceUrl}-archive/${uuid}`);
  }

  deleteChampionTestimonial(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.championTestimonialResourceUrl}-delete/${uuid}`);
  }

  restoreChampionTestimonial(uuid: string): Observable<any> {
    return this.apiService.post(`${this.championTestimonialResourceUrl}-restore/${uuid}`);
  }

  getChampionedCharities(payload: { statusList: string[]; size: number; page: number; searchText: string }): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/testimonials`, payload);
  }
}
