<ng-container *ngIf="deals?.length">
  <ng-container *ngIf="filterTitle">
    <div class="d-flex justify-content-between">
      <p class="category-title" *ngIf="!isCategory">
        {{ filterName || ('GENERAL.DASHBOARD_FILTERS.' + filterTitle | translate) }}
      </p>
      <p class="category-title" *ngIf="isCategory">
        {{ 'GENERAL.DROPDOWNS.CATEGORIES.' + filterName | translate }}
      </p>
      <a
        class="see-all pointer"
        *ngIf="seeAll; else searchDisplay"
        [routerLink]="[detailsLink ? detailsLink : null]"
        (click)="!detailsLink ? emitEvent() : null"
      >
        <ng-container *ngIf="!customButton; else isOnGroups">
          {{ 'GENERAL.GENERIC_LABELS.SEE_ALL' | translate }} <span *ngIf="showEntityCount"> ({{ deals.length }})</span>
        </ng-container>
        <ng-template #isOnGroups>
          <h3 class="mb-0 uppercase pointer text-blue">
            {{
              filterTitle === filterType.PROJECTS_DISCUSSED || filterTitle === filterType.PROJECTS_INCLUDED
                ? ('GROUPS.GROUP_DETAILS.GENERAL.SEE_ALL_PROJECTS' | translate)
                : filterTitle === filterType.ORGANISATIONS_DISCUSSED || filterTitle === filterType.ORGANISATIONS_INCLUDED
                ? ('GROUPS.GROUP_DETAILS.GENERAL.SEE_ALL_ORGANISATIONS' | translate)
                : ('GROUPS.GROUP_DETAILS.GENERAL.SEE_ALL_PORTFOLIOS' | translate)
            }}
          </h3>
        </ng-template>
      </a>
      <ng-template #searchDisplay>
        <div *ngIf="search">
          <app-search-bar (searchEvent)="emitEvent($event)"></app-search-bar>
        </div>
      </ng-template>
    </div>
    <div [ngClass]="{ 'mb-3': customButton }">
      <mat-divider></mat-divider>
    </div>
  </ng-container>

  <div class="carousel-container">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slideInit($event)"
      (beforeChange)="beforeChange($event)"
      (afterChange)="afterChange($event)"
    >
      <div ngxSlickItem *ngFor="let deal of deals" class="slide">
        <a class="uncoloured-link card pointer" [routerLink]="[getLink(deal.uuid, deal.languageCode)]">
          <div class="img">
            <img class="card-img-top" [src]="deal.imageUrl" alt="image" />
          </div>
          <div class="card-body px-3 py-2">
            <div class="visible mb-3">
              <h3 class="carousel-project-title font-weight-bold truncated-single-line m-0">{{ deal.name || deal.firmName }}</h3>
              <h5 *ngIf="deal.allocation" class="text-blue font-weight-bold">
                {{ 'CATALOG.DETAILS.PORTFOLIOS.GENERAL.ALLOCATION' | translate | uppercase }}: {{ deal.allocation }}%
              </h5>
            </div>
            <div class="d-flex justify-content-between" *ngIf="deal.country && deal.returnTarget; else cause">
              <div class="text-gray">{{ deal.country }}</div>
              <div class="text-gray">{{ deal.returnTarget }}</div>
            </div>
            <ng-template #cause>
              <div *ngIf="showCauses" class="text-gray truncated-single-line" [title]="deal.causeName">
                {{ 'GENERAL.DROPDOWNS.CAUSES.' + deal.causeName | translate }}
              </div>
            </ng-template>
            <div *ngIf="deal.typeOfFund || deal.tickerSymbol" class="d-flex justify-content-between">
              <div class="text-gray">{{ deal.tickerSymbol }}</div>
            </div>
            <div *ngIf="deal?.organisationInfluencedCountriesNames" class="d-flex justify-content-between">
              <div class="text-gray truncated-single-line">
                <ng-container *ngIf="deal.organisationInfluencedCountriesNames.length < 5; else multiple">
                  <ng-container *ngFor="let country of deal.organisationInfluencedCountriesNames; index as i">
                    <span>{{ 'GENERAL.DROPDOWNS.COUNTRIES.' + country | translate }}</span>
                    <span *ngIf="i < deal.organisationInfluencedCountriesNames.length - 1">, </span>
                  </ng-container>
                </ng-container>
                <ng-template #multiple>
                  {{ 'SHARED.COMPONENTS.DEAL_CAROUSEL.MULTIPLE_COUNTRIES' | translate }}
                </ng-template>
              </div>
            </div>
            <div *ngIf="deal?.countriesNames" class="d-flex justify-content-between">
              <div class="text-gray truncated-single-line">
                {{
                  deal?.countriesNames.length < 5
                    ? deal?.countriesNames.join(', ')
                    : ('SHARED.COMPONENTS.DEAL_CAROUSEL.MULTIPLE_COUNTRIES' | translate)
                }}
              </div>
            </div>
            <div *ngIf="!deal?.morningstarRating && deal?.principalAmount" class="d-flex justify-content-between">
              <app-currency [amount]="deal.principalAmount" [currency]="deal.principalAmountCurrencySymbol"> </app-currency>
              <div class="font-weight-bold">{{ 'SHARED.COMPONENTS.DEAL_CAROUSEL.FUNDED' | translate }} {{ calculatePercent(deal) }} %</div>
            </div>
            <div class="mt-1" *ngIf="deal.championedBy">
              {{ 'SHARED.COMPONENTS.DEAL_CAROUSEL.CHAMPIONED_BY' | translate }} {{ deal.championedBy }}
            </div>
          </div>
        </a>
      </div>
    </ngx-slick-carousel>
  </div>
</ng-container>
