export enum CATALOG_PROJECT_SORT_OPTIONS {
  ALPHABETICAL = 'ALPHABETICAL',
  DATE_POSTED = 'DATE_POSTED',
}

export enum CATALOG_ORGANISATIONS_SORT_OPTIONS {
  ORGANISATION_NAME = 'ORGANISATION_NAME',
  DATE_POSTED = 'DATE_POSTED',
}

export enum CATALOG_PORTFOLIOS_SORT_OPTIONS {
  ALPHABETICAL = 'ALPHABETICAL',
  DATE_POSTED = 'DATE_POSTED',
}

export const ADMIN_ORGANISATION_SORT_OPTIONS = ['ORGANISATION_NAME', 'DATE_POSTED'];

export const ADMIN_PROJECT_SORT_OPTIONS = ['PROJECT_NAME', 'DATE_POSTED'];
