import { EntityChampion, EntityChampionDto } from '../Details';
import { CarouselDeal } from '../filters/Dashboard';
import { PortfolioDetailsDto, PortfolioProfileFieldsDto } from './PortfolioDto';
import { CatalogEntityBadge } from '../GenericObject';
import { EntityStatus } from '../../constants/CompanyOrFirm';
import { EntityProfile } from '../EntityProfile';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class PortfolioDetails {
  badges: CatalogEntityBadge[];
  categories: string[];
  cause: string;
  countries: string[];
  description: SafeHtml;
  givingLink: string;
  name: string;
  numberOfOrganisations: number;
  numberOfProjects: number;
  type: string;
  coverImageUrl: string;
  videoThumbnailUrl: string;
  videoUrl: string;
  uuid: string;
  champions: EntityChampion[] = [];
  projects: CarouselDeal[] = [];
  organisations: CarouselDeal[] = [];
  carouselEntitiesLoaded: boolean;
  entityStatus: EntityStatus;
  organisationPositionFirst: boolean;

  constructor(portfolioData: PortfolioDetailsDto, sanitizer: DomSanitizer) {
    this.badges = portfolioData.badges;
    this.categories = portfolioData.categories;
    this.cause = portfolioData.cause;
    this.countries = portfolioData.countries;
    this.description = sanitizer.bypassSecurityTrustHtml(portfolioData.description);
    this.givingLink = portfolioData.givingLink;
    this.name = portfolioData.name;
    this.numberOfOrganisations = portfolioData.numberOfOrganisations;
    this.numberOfProjects = portfolioData.numberOfProjects;
    this.type = portfolioData.portfolioType;
    this.coverImageUrl = portfolioData.urlCoverImage;
    this.videoThumbnailUrl = portfolioData.urlThumbnail;
    this.videoUrl = portfolioData.urlVideo;
    this.uuid = portfolioData.uuid;
    this.entityStatus = portfolioData.entityStatus;
    this.organisationPositionFirst = portfolioData.organisationPositionFirst;
  }

  setCarouselProjects(projectsData): void {
    const mappedProjects = projectsData.map((project) => {
      project.causeName = project.cause;
      project.imageUrl = project.urlCoverImage;
      return project;
    });
    this.projects = [...this.projects, ...mappedProjects];
  }

  setCarouselOrganisations(organisationsData): void {
    const mappedOrganisations = organisationsData.map((organisation) => {
      organisation.causeName = organisation.cause;
      organisation.imageUrl = organisation.urlCoverImage;
      organisation.organisationInfluencedCountriesNames = organisation.countries;
      return organisation;
    });
    this.organisations = [...this.organisations, ...mappedOrganisations];
  }

  setChampions(championsData: EntityChampionDto[]): void {
    this.champions = championsData.map((champion) => new EntityChampion(champion));
  }
}

export class PortfolioProfileFields {
  projects: EntityProfile;
  givingPortfolioType: EntityProfile;
  influencedCountries: EntityProfile;
  portfolioChampions: EntityProfile;
  description: EntityProfile;
  cause: EntityProfile;
  givingLink: EntityProfile;
  organizations: EntityProfile;
  portfolioMedia: EntityProfile;
  categories: EntityProfile;
  portfolioName: EntityProfile;
  portfolioScore: EntityProfile;

  constructor(fieldsInfo: PortfolioProfileFieldsDto) {
    this.projects = fieldsInfo.projects;
    this.givingPortfolioType = fieldsInfo.givingPortfolioType;
    this.influencedCountries = fieldsInfo.influencedCountries;
    this.portfolioChampions = fieldsInfo.portfolioChampions;
    this.description = fieldsInfo.description;
    this.cause = fieldsInfo.cause;
    this.givingLink = fieldsInfo.givingLink;
    this.organizations = fieldsInfo.organizations;
    this.portfolioMedia = fieldsInfo.portfolioMedia;
    this.categories = fieldsInfo.categories;
    this.portfolioName = fieldsInfo.portfolioName;
    this.portfolioScore = fieldsInfo.portfolioScore;
  }
}
