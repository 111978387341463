import { DecliningReason } from 'src/app/internal-app/details/champion/models/Champion';
import { UUIDName } from '../models/GenericObject';

export const ENGAGEMENT_MONTHS: UUIDName[] = [
  {
    uuid: '1',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.JANUARY',
    disabled: false,
  },
  {
    uuid: '2',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.FEBRUARY',
    disabled: false,
  },
  {
    uuid: '3',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.MARCH',
    disabled: false,
  },
  {
    uuid: '4',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.APRIL',
    disabled: false,
  },
  {
    uuid: '5',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.MAY',
    disabled: false,
  },
  {
    uuid: '6',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.JUNE',
    disabled: false,
  },
  {
    uuid: '7',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.JULY',
    disabled: false,
  },
  {
    uuid: '8',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.AUGUST',
    disabled: false,
  },
  {
    uuid: '9',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.SEPTEMBER',
    disabled: false,
  },
  {
    uuid: '10',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.OCTOBER',
    disabled: false,
  },
  {
    uuid: '11',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.NOVEMBER',
    disabled: false,
  },
  {
    uuid: '12',
    name: 'CATALOG.CHAMPION.GENERAL.ENGAGEMENT_MONTHS.DECEMBER',
    disabled: false,
  },
];

export const TESTIMONIAL_EXPIRATION_OPTIONS: UUIDName[] = [
  {
    uuid: '3',
    name: 'CATALOG.CHAMPION.GENERAL.TESTIMONAL_EXPIRATION_OPTIONS.3_MONTHS',
    disabled: false,
  },
  {
    uuid: '6',
    name: 'CATALOG.CHAMPION.GENERAL.TESTIMONAL_EXPIRATION_OPTIONS.6_MONTHS',
    disabled: false,
  },
  {
    uuid: '9',
    name: 'CATALOG.CHAMPION.GENERAL.TESTIMONAL_EXPIRATION_OPTIONS.9_MONTHS',
    disabled: false,
  },
  {
    uuid: '12',
    name: 'CATALOG.CHAMPION.GENERAL.TESTIMONAL_EXPIRATION_OPTIONS.12_MONTHS',
    disabled: false,
  },
];

export enum DeclineReasons {
  TYPOGRAPHICAL_ERRORS,
  INCORRECT_MEDIA_UPLOAD,
  NO_HISTORY_OF_ENGAGEMENT,
  CONFIDENTIALITY_CONCERNS,
  MISALIGNED_CONTENT,
  OTHERS,
}

export const DECLINE_REASON: DecliningReason[] = [
  {
    reason: DeclineReasons.TYPOGRAPHICAL_ERRORS,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.TYPOGRAPHICAL_ERRORS',
  },
  {
    reason: DeclineReasons.INCORRECT_MEDIA_UPLOAD,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.INCORRECT_MEDIA_UPLOAD',
  },
  {
    reason: DeclineReasons.NO_HISTORY_OF_ENGAGEMENT,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.NO_HISTORY_OF_ENGAGEMENT',
  },
  {
    reason: DeclineReasons.CONFIDENTIALITY_CONCERNS,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.CONFIDENTIALITY_CONCERNS',
  },
  {
    reason: DeclineReasons.MISALIGNED_CONTENT,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.MISALIGNED_CONTENT',
  },
  {
    reason: DeclineReasons.OTHERS,
    description: 'CATALOG.CHAMPION.GENERAL.DECLINE_REASONS.OTHERS',
  },
];

export enum ChampionStatus {
  LIVE = 'LIVE',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  SUMMARY = 'SUMMARY',
}
