<div class="card file-card no-border" *ngIf="media?.fileName">
  <img (click)="preview()" class="image-cover" *ngIf="showPreview" [src]="media.url" alt="" />
  <div class="d-flex justify-content-between flex-wrap">
    <div class="vertical-center">
      <div class="file-name">{{ shortName || media.fileName }}</div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        [id]="'setCover' + media.position"
        *ngIf="displaySetCoverButton && !media.cover"
        class="btn btn-link text button-margin"
        type="button"
        [disabled]="shouldDisable"
        (click)="setAsCover()"
      >
        {{ 'SHARED.COMPONENTS.FILE_CARD.SET_COVER' | translate }}<i class="far fa-image icon-margin"></i>
      </button>
      <button [id]="'preview' + media.position" *ngIf="!video" class="btn btn-link button-margin text" type="button" (click)="previewDownload()">
        <span *ngIf="canPreview; else download"
          >{{ 'GENERAL.GENERIC_BUTTONS.PREVIEW' | translate }}<i class="fas fa-external-link-alt icon-margin"></i
        ></span>
        <ng-template #download>
          <span>{{ 'GENERAL.GENERIC_BUTTONS.DOWNLOAD' | translate }}<i class="fas fa-download icon-margin"></i></span>
        </ng-template>
      </button>
      <button [id]="'delete' + media.position" class="btn btn-link text" type="button" [hidden]="shouldDisable" (click)="delete()">
        {{ 'GENERAL.GENERIC_BUTTONS.DELETE' | translate }}<i class="far fa-trash-alt icon-margin"></i>
      </button>
    </div>
  </div>
</div>
