import { Component, OnInit } from '@angular/core';
import { GSFormGroup } from 'src/app/_core/models/forms/GSFormGroup';
import { generateGSFormElement } from 'src/app/_core/models/forms/Model';
import { ChampionDeclineReason } from 'src/app/internal-app/details/champion/models/Champion';
import { ChampionDeclineReasonForm } from './model/DeclineModelFormControls';
import { GSFormControl } from 'src/app/_core/models/forms/GSFormControl';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { MODAL_ACTIONS, MODAL_RESPONSE } from 'src/app/_core/constants/Modals';
import { DeclineReasons } from 'src/app/_core/constants/Champion';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.scss'],
})
export class DeclineModalComponent implements OnInit {
  championDeclineReasonForm: GSFormGroup<ChampionDeclineReason>;
  championDeclineReason: ChampionDeclineReason;
  declineReasons: any;
  reasonToViewReasonDetails: DeclineReasons;
  action: MODAL_ACTIONS;
  payload: any;
  viewReasonDetails: boolean = false;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.payload = this.modalsService.params.payload;
    this.action = this.modalsService.params.action;
    this.declineReasons = this.modalsService.params.declineReasons;
    this.reasonToViewReasonDetails = this.modalsService.params.reasonToViewReasonDetails;
    this.generateForm();
  }

  generateForm(): void {
    this.championDeclineReasonForm = generateGSFormElement(
      ChampionDeclineReasonForm,
      this.championDeclineReason
    ) as GSFormGroup<ChampionDeclineReason>;

    this.setupFormListeners();
  }

  emitTrue(): void {
    if (this.championDeclineReasonForm.valid) {
      this.modalsService.emitResponse({
        confirmed: true,
        action: this.action,
        payload: { ...this.payload, ...this.championDeclineReasonForm.getRawValue() },
        closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS,
      });
    }
  }

  emitFalse(): void {
    this.modalsService.emitResponse({
      confirmed: false,
      action: null,
      payload: null,
    });
    this.modalsService.closeModal();
  }

  setupFormListeners(): void {
    this.declineReason.valueChanges.subscribe((value) => {
      if (value.reason === this.reasonToViewReasonDetails) {
        this.viewReasonDetails = true;
        this.declineReasonDetails.addValidators([Validators.required]);
      } else {
        this.viewReasonDetails = false;
        this.declineReasonDetails.clearValidators();
      }
      this.declineReasonDetails.updateValueAndValidity();
    });
  }

  get declineReason(): GSFormControl {
    return this.championDeclineReasonForm.controls.declineReason;
  }

  get declineReasonDetails(): GSFormControl {
    return this.championDeclineReasonForm.controls.declineReasonDetails;
  }
}
