import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectUpdate } from 'src/app/_core/models/ProjectUpdate';
import StorageHelper from 'src/app/_core/helpers/Storage.helper';

@Component({
  selector: 'app-project-updates-carousel',
  templateUrl: './project-updates-carousel.component.html',
  styleUrls: ['./project-updates-carousel.component.scss'],
})
export class ProjectUpdatesCarouselComponent implements OnInit {
  @Input() updates: ProjectUpdate[];
  @Input() showDelete = true;
  @Input() currency: string;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMoreUpdates: EventEmitter<any> = new EventEmitter<any>();

  restricted = false; // TODO logic for restricted
  dateFormat: string;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: '<div class="carousel-button carousel-next-button">Next Update <i class="fa fa-chevron-right ml-1"></i></div>',
    prevArrow: '<div class="carousel-button carousel-prev-button"> <i class="fa fa-chevron-left mr-1"></i> Previous Update</div>',
    adaptiveHeight: false,
    dots: true,
    speed: 650,
    focusOnSelect: true,
    pauseOnFocus: false,
    autoplay: false,
    lazyLoad: 'ondemand',
  };

  // prettier-ignore
  constructor(
  ) {
  }

  ngOnInit(): void {
    this.dateFormat = StorageHelper.getDateFormat();
  }

  slideChange(e) {
    if (this.updates?.length - e.currentSlide < 3) {
      this.loadMoreUpdates.emit();
    }
  }

  deleteUpdate(projectUuid) {
    this.delete.emit(projectUuid);
  }
}
