import { CustomAddress } from '../CustomAddress';
import { ChampionCardDto, ChampionDetailsDto, ChampionTestimonialsDto } from './ChampionDto';
import { CatalogEntityBadge } from '../GenericObject';
import { ChampionStatus } from '../../constants/Champion';
import { Role } from '../../constants/User';

export class ChampionCard {
  name: string;
  profileImageUrl: string;
  uuid: string;
  order: number;
  badge: CatalogEntityBadge;

  constructor(data: ChampionCardDto) {
    this.name = data.entityName;
    this.profileImageUrl = data.urlCoverImage;
    this.uuid = data.uuid;
    this.badge = data?.badge;
  }
}

export class Champion extends ChampionCard {
  bio: string;
  description: string;
  types: string;
  website: string;
  dealsInvestedRange: string;
  experience: string;
  grantMakingOrDiligenceOrganisation: string;
  categories: string;
  causes: string;
  countries: string;
  location: string;
  organisations: any[];
  projects: any[];
  portfolios: any[];
  isCompany: boolean;
  companyName: string;
  hasChampionedEntities: boolean;

  constructor(data: ChampionDetailsDto) {
    super(data);
    this.bio = data.bio;
    this.profileImageUrl = data.coverImageUrl;
    this.description = data.championDescription;
    this.types = data.championTypes.join(', ');
    this.location = this.setLocation(data.location);
    this.website = data.website;
    this.dealsInvestedRange = data.dealsInvestedRange;
    this.experience = data.experience;
    this.grantMakingOrDiligenceOrganisation = data.grantMakingOrDiligenceOrganisation;
    this.categories = data.interestedCategories.join(', ');
    this.causes = data.interestedCauses.join(', ');
    this.countries = data.interestedCountries.join(', ');
    this.organisations = data.championedOrganisations;
    this.projects = data.championedProjects;
    this.portfolios = data.championedPortfolios;
    this.hasChampionedEntities = !!this.organisations.length || !!this.projects.length || !!this.portfolios.length;
  }

  setLocation(address: CustomAddress): string {
    const addressArray = [];
    if (address.city) addressArray.push(address.city);
    if (address.stateProvince) addressArray.push(address.stateProvince);
    if (address.country) addressArray.push(address.country);
    return addressArray.join(', ');
  }
}

export class ChampionTestimonials {
  uuid: string;
  championName: string;
  organizationUuid: string;
  organizationName: string;
  testimonial: string;
  status: ChampionStatus;
  dateSubmitted: number;
  yearsOfEngagement: string;
  enableRestore: boolean;
  enableEdit: boolean;
  enableResubmit: boolean;
  declineReason: string;
  dateOfApproval: number;
  approvedBy: string;
  declinedBy: string;
  dateOfDecline: number;
  archivedBy: string;
  archivedDate: number;
  expiredDate: number;

  showViewFormButton: boolean;
  showApproveButton: boolean;
  showDeclineButton: boolean;
  showViewLiveButton: boolean;
  showArchiveButton: boolean;
  showDeleteButton: boolean;
  showRestoreButton: boolean;
  showEditButton: boolean;
  showResubmitButton: boolean;

  constructor(championTestimonial: ChampionTestimonialsDto, selectedStatus?: ChampionStatus, role: Role = Role.SUPER_ADMIN) {
    this.uuid = championTestimonial.uuid;
    this.status = championTestimonial.status;
    this.yearsOfEngagement = championTestimonial.yearsOfEngagement;
    this.testimonial = championTestimonial.testimonial;
    this.organizationUuid = championTestimonial.organisationUuid;

    if (role === Role.CHAMPION) {
      this.organizationName = championTestimonial.organisationName;
    } else {
      this.championName = championTestimonial.championName;
    }

    if (selectedStatus && selectedStatus === ChampionStatus.SUMMARY) {
      this.showViewFormButton = true;
      if (this.status !== ChampionStatus.DRAFT) {
        this.dateSubmitted = championTestimonial.dateSubmitted;
      }
    } else {
      if (this.status === ChampionStatus.PENDING) {
        this.setPendingChampionCardDetails(championTestimonial, role === Role.SUPER_ADMIN);
      } else if (this.status === ChampionStatus.LIVE) {
        this.setLiveChampionCardDetails(championTestimonial, role === Role.SUPER_ADMIN);
      } else if (this.status === ChampionStatus.DECLINED) {
        this.setDeclineChampionCardDetails(championTestimonial, role === Role.SUPER_ADMIN);
      } else if (this.status === ChampionStatus.ARCHIVED) {
        this.setArchivedChampionCardDetails(championTestimonial, role === Role.SUPER_ADMIN);
      } else if (this.status === ChampionStatus.DRAFT) {
        this.setDraftChampionCardDetails(championTestimonial);
      } else {
        this.setExpiredChampionCardDetails(championTestimonial, role === Role.SUPER_ADMIN);
      }
    }
  }

  setDraftChampionCardDetails(championTestimonial: ChampionTestimonialsDto): void {
    this.showEditButton = championTestimonial.enableEdit;
    this.showDeleteButton = true;
  }

  setPendingChampionCardDetails(championTestimonial: ChampionTestimonialsDto, isAdmin: boolean = true): void {
    this.dateSubmitted = championTestimonial.dateSubmitted;
    if (isAdmin) {
      this.showViewFormButton = true;
      this.showApproveButton = true;
      this.showDeclineButton = true;
    } else {
      this.showEditButton = championTestimonial.enableEdit;
      this.showArchiveButton = true;
    }
  }

  setLiveChampionCardDetails(championTestimonial: ChampionTestimonialsDto, isAdmin: boolean = true): void {
    this.dateSubmitted = championTestimonial.dateSubmitted;
    this.dateOfApproval = championTestimonial.dateOfApproval;
    this.approvedBy = championTestimonial.approvedBy;
    this.showViewLiveButton = true;
    this.showArchiveButton = true;

    if (isAdmin) {
      this.showViewFormButton = true;
    } else {
      this.showEditButton = championTestimonial.enableEdit;
    }
  }

  setDeclineChampionCardDetails(championTestimonial: ChampionTestimonialsDto, isAdmin: boolean = true): void {
    this.dateSubmitted = championTestimonial.dateSubmitted;
    this.declineReason = championTestimonial.declineReason;
    this.dateOfDecline = championTestimonial.declineDate;
    this.declinedBy = championTestimonial.declineBy;
    this.showDeleteButton = true;

    if (!isAdmin) {
      this.showResubmitButton = championTestimonial.enableResubmit;
    }
  }

  setArchivedChampionCardDetails(championTestimonial: ChampionTestimonialsDto, isAdmin: boolean = true): void {
    this.dateSubmitted = championTestimonial.dateSubmitted;
    this.archivedBy = championTestimonial.archivedBy;
    this.archivedDate = championTestimonial.archivedDate;
    this.showDeleteButton = true;

    if (isAdmin) {
      this.showViewFormButton = true;
      this.showRestoreButton = championTestimonial.enableRestore;
    } else {
      this.showResubmitButton = championTestimonial.enableResubmit;
    }
  }

  setExpiredChampionCardDetails(championTestimonial: ChampionTestimonialsDto, isAdmin: boolean = true): void {
    this.dateSubmitted = championTestimonial.dateSubmitted;
    this.expiredDate = championTestimonial.expiredDate;
    this.showDeleteButton = true;

    if (isAdmin) {
      this.showViewFormButton = true;
    } else {
      this.showResubmitButton = championTestimonial.enableResubmit;
    }
  }
}
