import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {
  @Input() document;
  @Input() entityUuid;
  @Input() isAdmin: boolean = false;
  @Input() partOfForm: boolean = true;

  @Output() deleteEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  deleteDocument(mediaControl: FormControl | FormGroup, entityUuid: string): void {
    const payload = { mediaControl, entityUuid };
    this.deleteEmit.emit(payload);
  }

  previewDocument(document): void {
    this.previewEmit.emit(document);
  }
}
