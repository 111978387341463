import { FormGroup, ValidatorFn } from '@angular/forms';
import { ControlError, GSControl, GSTypedGroup } from '../FormControls';
import { extractInvalidFields } from './Model';
import { EntityProfile } from '../EntityProfile';

// GS comes from GivingSpace
export class GSFormGroup<T = any> extends FormGroup<GSTypedGroup<T>> {
  label: string;
  placeholder: string;
  max: number;
  controlErrors: ControlError[];
  key: string;
  defaultValidators: ValidatorFn[];
  invalidFields: string[];
  profile: EntityProfile;

  constructor(gsControl: GSControl, formGroup: GSTypedGroup<T>) {
    super(formGroup, gsControl.validators, gsControl.asyncValidators);
    this.key = gsControl.key;
    this.label = gsControl.label;
    this.placeholder = gsControl.placeholder;
    this.max = gsControl.max;
    this.controlErrors = gsControl.errors;
    this.defaultValidators = gsControl.validators;
    this.invalidFields = [];
    this.profile = gsControl.profile;
  }

  removeError(errorKey: string): void {
    if (super.errors.hasOwnProperty(errorKey)) {
      delete this.errors.errorKey;
      if (Object.values(this.errors).length < 1) {
        this.setErrors(null);
      }
    }
  }

  setInvalidFields(): void {
    this.invalidFields = extractInvalidFields(this);
  }
}
