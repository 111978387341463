<ngx-slick-carousel class="carousel" [config]="slideConfig" (afterChange)="slideChange($event)">
  <div ngxSlickItem class="slide" *ngFor="let update of updates">
    <div class="p-4 shadowed-card">
      <!-- Remaining Funds To Reach Target -->
      <h3 *ngIf="update.amountRaisedToDate !== null && !restricted" class="font-weight-bold">
        {{ 'SHARED.COMPONENTS.PROJECT_UPDATES_CAROUSEL.REMAINING_FUNDS_TO_REACH_TARGET' | translate }} <br />
        <span class="font-weight-normal">
          <app-currency [amount]="update.totalProjectBudget - update.amountRaisedToDate" [currency]="currency"></app-currency>
        </span>
      </h3>

      <!-- Headline-->
      <h3 class="font-weight-bold">{{ update.headline }}</h3>
      <!-- Description-->
      <div class="html-container">
        <quill-view-html [content]="update.update" theme="snow"></quill-view-html>
      </div>
      <!-- Media-->
      <div class="mb-3">
        <video *ngIf="update.updateVideo; else noVideo" controls [src]="update.updateVideo.url" [poster]="update.updateThumbnailVideo?.url"></video>
        <ng-template #noVideo>
          <img *ngIf="update.updatePicture" [lazyLoad]="update.updatePicture.url" alt="project-update-img" [style.width]="'100%'" />
        </ng-template>
      </div>
      <div class="d-flex">
        <div class="update-date mr-3 px-3">
          {{ 'SHARED.COMPONENTS.PROJECT_UPDATES_CAROUSEL.UPDATE_DATE' | translate }} {{ update.modificationDate | date: dateFormat }}
        </div>
        <button *ngIf="showDelete" mat-flat-button class="mat-main-button mt-2 mt-md-0" color="warn" (click)="deleteUpdate(update.projectUpdateUuid)">
          {{ 'SHARED.COMPONENTS.PROJECT_UPDATES_CAROUSEL.DELETE_UPDATE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ngx-slick-carousel>
<app-no-items-found *ngIf="!updates.length" [title]="'SHARED.COMPONENTS.PROJECT_UPDATES_CAROUSEL.NO_UPDATE_AVAILABLE'"> </app-no-items-found>
