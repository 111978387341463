import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap, Observable, of, ReplaySubject, skip, switchMap, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AccountService } from '../api/account.service';
import { LANGUAGE_STATUS } from '../constants/Global/Language';
import StorageHelper from '../helpers/Storage.helper';
import { ResponseObject, UUIDName } from '../models/GenericObject';
import { Language } from '../models/global-settings/Language';
import { UserDetails } from '../models/User';
import { DropdownsService } from './dropdowns.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _selectedLanguage: ReplaySubject<Language> = new ReplaySubject<Language>(1);
  public selectedLanguage$: Observable<Language> = this._selectedLanguage.asObservable();

  private _languages: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);
  public languages$: Observable<Language[]> = this._languages.asObservable();

  private _saveGlobalLanguage: ReplaySubject<Language> = new ReplaySubject<Language>(1);
  public saveGlobalLanguage$: Observable<Language> = this._saveGlobalLanguage.asObservable();

  private selectedLanguage: Language;
  private languages: Language[];
  private user: UserDetails;
  private isFallbackLanguageDownloaded: boolean = false;

  // prettier-ignore
  constructor(
    private dropdownService: DropdownsService,
    private userService: UserService,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private accountService: AccountService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
  }

  public initializeLocalTranslations(): Observable<any> {
    const languagesList = StorageHelper.getStoredTranslationsList();
    this.translateService.addLangs(Object.keys(languagesList));
    Object.values(languagesList).forEach((language) => {
      this.translateService.setTranslation(language.code, StorageHelper.getLanguageJSON(language.code));
    });
    return this.setLanguage(StorageHelper.getSelectedLanguage());
  }

  public refreshLanguages(): Observable<any> {
    this.dropdownService.getDropdowns();
    const refreshLanguages = (dropdowns): Observable<any> => {
      this.languages = dropdowns.languages;
      this._languages.next(this.languages);
      return this.setDefaultLanguage().pipe(mergeMap(() => this.handleUserSettings()));
    };
    this.dropdownService.dropdowns$.pipe(skip(1)).subscribe((dropdowns) => {
      refreshLanguages(dropdowns).pipe(first()).subscribe();
    });
    return this.dropdownService.dropdowns$.pipe(mergeMap(refreshLanguages));
  }

  private setDefaultLanguage(): Observable<any> {
    const englishLanguage = this.getLanguageByCode('eng');
    if (!!englishLanguage) {
      return this.getTranslation(false, englishLanguage).pipe(tap(() => this.translateService.setDefaultLang(englishLanguage.code)));
    } else {
      return this.getFallbackLanguage(false).pipe(tap(() => this.translateService.setDefaultLang(environment.defaultLanguage)));
    }
  }

  private handleUserSettings(): Observable<any> {
    const checkUser = (user: UserDetails): Observable<any> => {
      this.user = user;
      const loggedIn = !!this.user && !!StorageHelper.getKeycloakToken();
      const loggedOut = !this.user && !StorageHelper.getKeycloakToken();
      if (loggedIn || loggedOut) {
        return this.parseUser(loggedIn);
      } else {
        throw Error("some user logic doesn't work.");
      }
    };
    this.userService.currentUser$.pipe(skip(1)).subscribe(checkUser);
    return this.userService.currentUser$.pipe(mergeMap(checkUser));
  }

  private parseUser(loggedIn: boolean): Observable<any> {
    if (loggedIn) {
      if (this.user.languageCode && this.getLanguageByCode(this.user.languageCode)) {
        return this.setLanguage(this.getLanguageByCode(this.user.languageCode));
      }
    } else {
      if (StorageHelper.getSelectedLanguage() && this.getLanguageByCode(StorageHelper.getSelectedLanguage().code)) {
        return this.setLanguage(StorageHelper.getSelectedLanguage());
      }
    }
    return this.extractBrowserLanguage();
  }

  public setLanguage(language: Language): Observable<Language> {
    const shouldSwitchToFallbackLanguage = language?.code !== environment.defaultLanguage || this.isFallbackLanguageDownloaded;
    if (language && this.selectedLanguage?.code !== language?.code && shouldSwitchToFallbackLanguage) {
      if (this.isLanguageChangedOrNotDownloaded(language)) {
        return this.getTranslation(false, language).pipe(
          tap((language: Language) => {
            this.saveAndUseLanguage(language);
          })
        );
      } else {
        return this.saveAndUseLanguage(language);
      }
    } else if (!language || (language.code === environment.defaultLanguage && !this.isFallbackLanguageDownloaded)) {
      return this.getFallbackLanguage(true).pipe(switchMap((language: Language) => this.setLanguage(language)));
    } else {
      return of(language);
    }
  }

  private extractBrowserLanguage(): Observable<any> {
    const browserLanguages = this.documentRef.defaultView.navigator.languages;
    let browserLanguage;
    for (const browserLng of browserLanguages) {
      const found = this.languages.find(
        (language: Language) => language.state === LANGUAGE_STATUS.LIVE && browserLng.indexOf(language.code.substr(0, 2)) > -1
      );
      if (found) {
        browserLanguage = found;
        break;
      }
    }

    if (browserLanguage) {
      return this.setLanguage(browserLanguage);
    } else {
      const liveLanguage = this.languages.find((language: Language) => language.state === LANGUAGE_STATUS.LIVE);
      if (liveLanguage && !environment.isEnglishDefault) {
        return this.setLanguage(liveLanguage || { code: environment.defaultLanguage, name: environment.defaultLanguage, flag: '', lastModified: 1 });
      } else {
        const englishCase = this.languages.find((lng: Language) => lng.code === 'eng');
        return this.setLanguage(
          englishCase || this.languages[0] || { code: environment.defaultLanguage, name: environment.defaultLanguage, flag: '', lastModified: 1 }
        );
      }
    }
  }

  private saveAndUseLanguage(language: Language): Observable<Language> {
    this.translateService.use(language.code);
    this.saveLanguage(language);
    this._selectedLanguage.next({ ...language, code: language.code === environment.defaultLanguage ? 'eng' : language.code });
    this.selectedLanguage = language;
    this._saveGlobalLanguage.next(language);
    return of(language);
  }

  public getTranslation(force: boolean = false, language: Language = this.selectedLanguage): Observable<Language> {
    if (this.isLanguageChangedOrNotDownloaded(language) || force) {
      return this.downloadJson(language);
    } else {
      language.translations = StorageHelper.getLanguageJSON(language.code);
      return of(language);
    }
  }

  private downloadJson(language: Language): Observable<Language> {
    return this.accountService.getLanguageJson(language.code).pipe(
      map((response: ResponseObject<string>) => {
        language.translations = JSON.parse(response.response);
        return language;
      }),
      tap((language: Language) => {
        this.addLanguageToStorage(language);
      })
    );
  }

  public getFallbackLanguage(download: boolean = true): Observable<Language> {
    const language: Language = {
      code: environment.defaultLanguage,
      name: environment.defaultLanguage,
      flag: '',
      lastModified: 1,
    };
    if (download || this.isLanguageChangedOrNotDownloaded(language)) {
      return this.http.get(`/assets/i18n/${environment.defaultLanguage}.json`).pipe(
        map((response: object) => {
          language.translations = response;
          return language;
        }),
        tap((language: Language) => {
          this.isFallbackLanguageDownloaded = true;
          this.addLanguageToStorage(language);
        })
      );
    } else {
      language.translations = StorageHelper.getLanguageJSON(language.code);
      return of(language);
    }
  }

  private addLanguageToStorage(language: Language) {
    const languagesList = StorageHelper.getStoredTranslationsList();
    Object.assign(languagesList, { [language.code]: { code: language.code, lastModified: language?.lastModified || 1, translations: undefined } });
    StorageHelper.saveStoredLanguages(JSON.stringify(languagesList));
    StorageHelper.setLanguageJSON(language.code, JSON.stringify(language.translations));
    this.translateService.addLangs([language.code]);
    this.translateService.setTranslation(language.code, language.translations);
  }

  public setCustomDropdowns(
    dropdowns: { [k: string]: UUIDName[] } | any,
    objectKey: string = 'categories',
    languagePath: string = 'GENERAL.DROPDOWNS.CATEGORIES'
  ): void {
    const originalDropdowns = dropdowns[objectKey];
    const parseTranslations = () => {
      const translatedDropdowns = this.translateService.instant(languagePath);
      Object.keys(translatedDropdowns).forEach((key) => {
        const langKey = `${languagePath}.${key}`;
        const value = this.translateService.instant(langKey);
        translatedDropdowns[key] = value === langKey ? null : value;
      });
      dropdowns[objectKey] = originalDropdowns.filter((category: UUIDName) => !!translatedDropdowns[category.name]);
      dropdowns[objectKey] = dropdowns[objectKey].map((item: UUIDName) => new UUIDName(item.uuid, translatedDropdowns[item.name]));
      dropdowns[objectKey].sort((a: UUIDName, b: UUIDName) =>
        a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ? -1 : 0
      );
    };
    parseTranslations();
    this.translateService.onLangChange.subscribe(() => {
      parseTranslations();
    });
  }

  public filterMissingDropdowns(
    dropdowns: { [k: string]: UUIDName[] } | any,
    objectKey: string = 'categories',
    languagePath: string = 'GENERAL.DROPDOWNS.CATEGORIES'
  ): void {
    const translatedDropdowns = this.translateService.instant(languagePath);
    Object.keys(translatedDropdowns).forEach((key) => {
      const langKey = languagePath ? `${languagePath}.${key}` : key;
      const value = this.translateService.instant(langKey);
      translatedDropdowns[key] = value === langKey ? null : value;
    });
    dropdowns[objectKey] = dropdowns[objectKey].filter((category: UUIDName) => !!translatedDropdowns[category.name]);
  }

  private saveLanguage(language: Language) {
    StorageHelper.saveLanguage(JSON.stringify({ ...language, translations: undefined }));
  }

  private isLanguageChangedOrNotDownloaded(language: Language): boolean {
    const translations = StorageHelper.getStoredTranslationsList();
    const hasChanged: boolean =
      this.languages?.length &&
      translations[language.code] &&
      translations[language.code].lastModified !== this.getLanguageByCode(language.code)?.lastModified;
    const isJsonDownloaded: boolean = !!StorageHelper.getLanguageJSON(language.code);
    return hasChanged || !isJsonDownloaded;
  }

  getLanguageByCode(code: string): Language {
    return this.languages.find((language) => language.code === code);
  }

  getLanguageByCode$(code: string): Observable<Language> {
    return this.languages$.pipe(
      first(),
      map((languages) => languages.find((lng) => lng.code === code))
    );
  }

  getLanguageByUuid$(uuid: string): Observable<Language> {
    return this.languages$.pipe(
      first(),
      map((languages) => languages.find((item) => item.uuid === uuid))
    );
  }

  getLiveLanguages$(): Observable<Language[]> {
    return this.languages$.pipe(
      first(),
      map((languages) => languages.filter((language) => language.state === LANGUAGE_STATUS.LIVE))
    );
  }
}
