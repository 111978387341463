import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { Role } from '../constants/User';
import { EntityProfile } from '../models/EntityProfile';
import { Control, CONTROL_TYPES, VALUE_TYPES } from '../models/FormControls';
import { ArrayObjects } from '../models/GenericObject';
import { OrganisationProfileFields } from '../models/organisation/Organization';
import { ProjectProfileFields } from '../models/project/Project';
import { CustomValidators } from './CustomValidators.helpers';
import FormUtils from './FormUtils';
import { PortfolioProfileFields } from '../models/portfolio/Portfolio';

// tslint:disable: max-line-length
export default class FormFactoryUtils {
  public static getInvalidFieldsForEntity(rootControl: Control<any>, formcontrol: AbstractControl): ArrayObjects {
    const invalidFieldsObject: ArrayObjects = {};
    Object.values(rootControl.controls).forEach((groupData) => {
      let invalidFields = [];
      Object.values(groupData.controls).forEach(
        (controlData) =>
          (invalidFields = [
            ...invalidFields,
            ...this.extractInvalidLabelsFromControl(controlData, formcontrol.get(groupData.key).get(controlData.key)),
          ])
      );
      if (invalidFields.length > 0) {
        Object.assign(invalidFieldsObject, { [groupData.label]: invalidFields });
      }
    });
    return Object.values(invalidFieldsObject).length > 0 ? invalidFieldsObject : undefined;
  }

  public static extractInvalidLabelsFromControl(controlData: Control<any>, control: AbstractControl): Array<string> {
    switch (controlData.controlType) {
      case CONTROL_TYPES.FORM_CONTROL:
        if (control.invalid) {
          return [controlData.label];
        }
        return [];
      case CONTROL_TYPES.FORM_GROUP:
        if (control.invalid) {
          if (controlData.label) {
            return [controlData.label];
          } else {
            let invalidFields = [];
            Object.values(controlData.controls).forEach((ctrlData) => {
              invalidFields = [...invalidFields, ...this.extractInvalidLabelsFromControl(ctrlData, control.get(ctrlData.key))];
            });
            return invalidFields;
          }
        }
        return [];
      case CONTROL_TYPES.FORM_ARRAY:
        if (control.invalid) {
          return [controlData.label];
        }
        return [];
      default:
        return [];
    }
  }

  public static invalidBesidesRequired(rootControl: Control<any>, formGroup: FormGroup): boolean {
    for (const control of Object.values(rootControl.controls)) {
      if (this.checkForOtherThenRequiredError(control, formGroup.controls[control.key])) {
        return true;
      }
    }
    return false;
  }

  public static checkForOtherThenRequiredError(controlData: Control, control: AbstractControl): boolean {
    switch (controlData.controlType) {
      case CONTROL_TYPES.FORM_CONTROL:
        if (control.value && control.errors && FormUtils.errorDifferentThanRequired(control.errors)) {
          return true;
        }
        return false;
      case CONTROL_TYPES.FORM_GROUP:
        for (const ctrl of Object.values(controlData.controls)) {
          if (this.checkForOtherThenRequiredError(ctrl, (control as FormGroup).controls[ctrl.key])) {
            return true;
          }
        }
        return false;
      case CONTROL_TYPES.FORM_ARRAY:
        for (const ctrl of (control as FormArray).controls) {
          if (this.checkForOtherThenRequiredError(Object.values(controlData.controls)[0], ctrl)) {
            return true;
          }
        }
        return false;
    }
  }

  public static organizationFinancialsCustomParsing(control: FormArray, controlKeys): void {
    control.get(controlKeys.BUDGET_YEAR).updateValueAndValidity();
    control.get(controlKeys.FISCAL_YEAR_END).updateValueAndValidity();

    control.get(controlKeys.REVENUE).valueChanges.subscribe((val) => {
      let budget = 0;
      let actuals = 0;
      val.forEach((revenueItem) => {
        budget += revenueItem.revenueItemBudget;
        actuals += revenueItem.revenueItemActual;
      });
      control.get(controlKeys.TOTAL_REVENUE_ACTUALS).patchValue(actuals);
      control.get(controlKeys.TOTAL_REVENUE_BUDGET).patchValue(budget);
    });
    control.get(controlKeys.EXPENSE).valueChanges.subscribe((val) => {
      let budget = 0;
      let actuals = 0;
      val.forEach((expenseItem) => {
        budget += expenseItem.expenseItemBudget;
        actuals += expenseItem.expenseItemActual;
      });
      control.get(controlKeys.TOTAL_EXPENSE_ACTUALS).patchValue(actuals);
      control.get(controlKeys.TOTAL_OPERATING_BUDGET).patchValue(budget);
    });

    combineLatest([
      control.get(controlKeys.TOTAL_REVENUE_BUDGET).valueChanges,
      control.get(controlKeys.TOTAL_OPERATING_BUDGET).valueChanges,
    ]).subscribe((val: [number, number]) => {
      control.get(controlKeys.NET_GAIN_LOSS_BUDGET).patchValue(val[0] - val[1]);
    });

    combineLatest([
      control.get(controlKeys.TOTAL_REVENUE_ACTUALS).valueChanges,
      control.get(controlKeys.TOTAL_EXPENSE_ACTUALS).valueChanges,
    ]).subscribe((val: [number, number]) => {
      control.get(controlKeys.NET_GAIN_LOSS_ACTUALS).patchValue(val[0] - val[1]);
    });

    control.get(controlKeys.REVENUE).updateValueAndValidity();
    control.get(controlKeys.EXPENSE).updateValueAndValidity();

    control.markAsUntouched();
  }

  public static handleUserTypeChange(userTypeControl: AbstractControl): void {
    userTypeControl.valueChanges.subscribe((value) => {
      if (value) {
        userTypeControl.parent.get('roleName').patchValue(null);
      }
    });
  }

  public static handleRoleChange(roleControl: AbstractControl): void {
    roleControl.valueChanges.subscribe((value) => {
      if (roleControl.parent) {
        let entityControl = roleControl.parent.get('entity');
        if (value === Role.PROJECT_ADMIN) {
          if (!entityControl.validator) {
            entityControl.setValidators(CustomValidators.requiredObject);
            entityControl.updateValueAndValidity();
          }
        } else {
          if (entityControl.validator) {
            entityControl.clearValidators();
            entityControl.updateValueAndValidity();
          }
        }
      }
    });
  }

  public static getSecondUuidControl(rootControl: Control, abstractControl: AbstractControl): AbstractControl {
    return rootControl.parent
      ? abstractControl.parent.get(
          Object.values(rootControl.parent.controls).find((control) => control.valueType === VALUE_TYPES.SECOND_UUID)?.key
        ) || this.getSecondUuidControl(rootControl.parent, abstractControl.parent)
      : abstractControl.get(Object.values(rootControl.controls).find((control) => control.valueType === VALUE_TYPES.SECOND_UUID)?.key) || null;
  }

  public static setUpProfile(fields: ProjectProfileFields | OrganisationProfileFields | PortfolioProfileFields, profile: EntityProfile): void {
    Object.values(fields).forEach((value) => {
      if (Object.values(value.innerFields).length) {
        value.list = true;
      }
      if (value.list) {
        this.setUpProfile(value.innerFields, profile);
      }
      if (profile?.innerFields[value.uuid]) {
        value.hidden = profile?.innerFields[value.uuid].hidden;
        value.mandatory = value.hidden ? false : true;
      } else {
        value.hidden = false;
        value.mandatory = value.mandatory ? value.mandatory : false;
      }
    });
  }
}
