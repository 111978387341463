<div class="w-100 h-100" [ngClass]="fileType === fileTypes.VIDEO ? 'overflow-hidden' : null">
  <div class="text-right mb-4">
    <a><img (click)="close()" class="pointer" src="../../../assets/icons/clear-icon.svg" /></a>
  </div>
  <ng-container *ngIf="fileType == fileTypes.CAROUSEL">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let url of urls" class="slide">
        <img [attr.data-lazy]="url" class="img" />
      </div>
    </ngx-slick-carousel>
  </ng-container>

  <ng-container *ngIf="fileType == fileTypes.VIDEO">
    <video [src]="urls[0]" controls autoplay></video>
  </ng-container>

  <div *ngIf="fileType == fileTypes.IMAGE">
    <img class="img-fluid" [src]="urls[0]" alt="image" />
  </div>
</div>
