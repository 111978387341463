import { FILTER_NAME } from '../../constants/Filters/FilterTypes';
import { HomepageConfig, HomepageEntityType, HomepageFilterType } from 'src/app/_core/models/global-settings/HomepageConfig';
import Urls from 'src/app/_core/constants/Urls';
import { ProjectType } from 'src/app/_core/constants/CompanyOrFirm';

export interface DashboardDto {
  landingGivingSpaceDto: LandingPageDto;
}

export interface LandingPageDto {
  carouselFeaturedEntity: CarouselItemDto[];
  featuredRows: { [k: string]: CarouselItemDto[] };
}

export interface CarouselItemDto {
  causeName: string;
  description: string;
  giveLink: string;
  imageUrl: string;
  languageCode: string;
  name: string;
  organisationInfluencedCountriesNames: string[];
  uuid: string;
}

export class CarouselItem {
  causeName: string;
  description: string;
  giveLink: string;
  imageUrl: string;
  languageCode: string;
  name: string;
  organisationInfluencedCountriesNames: string[];
  uuid: string;
  readMoreLink: string;
  projectType: ProjectType;

  constructor(dto: CarouselItemDto, entityType: HomepageEntityType) {
    this.causeName = dto.causeName;
    this.description = dto.description;
    this.giveLink = dto.giveLink;
    if (!/^https?:\/\//i.test(this.giveLink) && !!this.giveLink) {
      this.giveLink = 'https://' + this.giveLink;
    }
    this.imageUrl = dto.imageUrl;
    this.name = dto.name;
    this.languageCode = dto.languageCode;
    this.organisationInfluencedCountriesNames = dto.organisationInfluencedCountriesNames;
    this.uuid = dto.uuid;
    if (entityType === HomepageEntityType.ORGANISATIONS) {
      this.projectType = ProjectType.ORGANISATION;
      this.readMoreLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.ORGANISATION}/${this.uuid}`;
    }
    if (entityType === HomepageEntityType.PROJECTS) {
      this.projectType = ProjectType.PROJECT;
      this.readMoreLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.PROJECT}/${this.uuid}`;
    }
    if (entityType === HomepageEntityType.PORTFOLIOS) {
      this.projectType = ProjectType.PORTFOLIO;
      this.readMoreLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.PORTFOLIO}/${this.uuid}`;
    }

    if (this.languageCode && entityType !== HomepageEntityType.PORTFOLIOS) {
      this.readMoreLink = this.readMoreLink + `/${this.languageCode}`;
    }
  }
}

export class HomeCarouselRow {
  position: number;
  filterType: HomepageFilterType;
  entityType: HomepageEntityType;
  items: CarouselItem[];
  headerLabelPath: string;

  constructor(key: string, items: CarouselItemDto[], displayPortfolioSection: boolean = true) {
    this.position = parseInt(key.charAt(0));
    if (key.indexOf('newly_added') > -1) {
      this.filterType = HomepageFilterType.NEWLY_ADDED;
    }
    if (key.indexOf('featured') > -1) {
      this.filterType = HomepageFilterType.FEATURED;
    }
    if (key.indexOf('championed') > -1) {
      this.filterType = HomepageFilterType.CHAMPIONED;
    }
    if (key.indexOf('organisations') > -1) {
      this.entityType = HomepageEntityType.ORGANISATIONS;
      if (this.filterType === HomepageFilterType.FEATURED) {
        this.headerLabelPath = 'HOME.LANDING.FEATURED_ORGANISATIONS';
      }
      if (this.filterType === HomepageFilterType.NEWLY_ADDED) {
        this.headerLabelPath = 'HOME.LANDING.NEWLY_ADDED_ORGANISATIONS';
      }
      if (this.filterType === HomepageFilterType.CHAMPIONED) {
        this.headerLabelPath = 'HOME.LANDING.CHAMPIONED_ORGANISATIONS';
      }
    }
    if (key.indexOf('projects') > -1) {
      this.entityType = HomepageEntityType.PROJECTS;
      if (this.filterType === HomepageFilterType.FEATURED) {
        this.headerLabelPath = 'HOME.LANDING.FEATURED_PROJECTS';
      }
      if (this.filterType === HomepageFilterType.NEWLY_ADDED) {
        this.headerLabelPath = 'HOME.LANDING.NEWLY_ADDED_PROJECTS';
      }
      if (this.filterType === HomepageFilterType.CHAMPIONED) {
        this.headerLabelPath = 'HOME.LANDING.CHAMPIONED_PROJECTS';
      }
    }
    if (key.indexOf('portfolios') > -1 && displayPortfolioSection) {
      this.entityType = HomepageEntityType.PORTFOLIOS;
      if (this.filterType === HomepageFilterType.FEATURED) {
        this.headerLabelPath = 'HOME.LANDING.FEATURED_PORTFOLIOS';
      }
      if (this.filterType === HomepageFilterType.NEWLY_ADDED) {
        this.headerLabelPath = 'HOME.LANDING.NEWLY_ADDED_PORTFOLIOS';
      }
      if (this.filterType === HomepageFilterType.CHAMPIONED) {
        this.headerLabelPath = 'HOME.LANDING.CHAMPIONED_PORTFOLIOS';
      }
    }
    if ((key.indexOf('portfolios') > -1 && displayPortfolioSection) || key.indexOf('portfolios') <= -1) {
      this.items = items.map((item) => new CarouselItem(item, this.entityType));
    }
  }
}

export class Dashboard {
  carouselFeaturedEntity: CarouselItem[] = [];
  rows: HomeCarouselRow[] = [];

  constructor(dashboardDto: DashboardDto, homepageConfig: HomepageConfig, displayPortfolioSection: boolean = true) {
    this.carouselFeaturedEntity = dashboardDto.landingGivingSpaceDto.carouselFeaturedEntity.map(
      (i) => new CarouselItem(i, homepageConfig.mainCarouselEntityType)
    );
    const rows = dashboardDto.landingGivingSpaceDto.featuredRows;
    const firstRowKey: string = Object.keys(rows).find((key) => key.charAt(0) === '1');
    if (!firstRowKey) {
      throw Error('At least one carousel row is required');
    }
    const carouselRow = new HomeCarouselRow(firstRowKey, rows[firstRowKey], displayPortfolioSection);
    if (carouselRow.items) {
      this.rows.push(carouselRow);
    }
    const secondRowKey: string = Object.keys(rows).find((key) => key.charAt(0) === '2');
    if (secondRowKey) {
      const carouselRow = new HomeCarouselRow(secondRowKey, rows[secondRowKey], displayPortfolioSection);
      if (carouselRow.items) {
        this.rows.push(carouselRow);
      }
    }
    const thirdRowKey: string = Object.keys(rows).find((key) => key.charAt(0) === '3');
    if (thirdRowKey) {
      const carouselRow = new HomeCarouselRow(thirdRowKey, rows[thirdRowKey], displayPortfolioSection);
      if (carouselRow.items) {
        this.rows.push(carouselRow);
      }
    }
    this.checkConfig(homepageConfig);
  }

  private checkConfig(config: HomepageConfig) {
    if (config.homepageCarouselRows.length !== this.rows.length) {
      throw Error('Bad configuration for homepage');
    }
    config.homepageCarouselRows.forEach((configRow) => {
      const row = this.rows.find((item) => item.position === configRow.position);
      if (!row || row.position !== configRow.position || row.entityType !== configRow.entityType || row.filterType !== configRow.filterType) {
        throw Error('Bad configuration for homepage');
      }
    });
  }
}

export class FilterSelected {
  title: FILTER_NAME;
  option: string;
  link: string;

  constructor(title?: FILTER_NAME, option?: string, link?: string) {
    this.setter(title, option, link);
  }

  setter(title: FILTER_NAME, option: string = '', link: string) {
    this.title = title;
    this.option = option;
    this.link = link;
  }
}

export interface CarouselDeal {
  uuid: string;
  imageUrl: string;
  name: string;
  country: string;
  languageCode: string;
  returnTarget: string;
  principalAmount: number;
  principalAmountCurrencySymbol: string;
  amountRemainingToBeRaised: number;
  causeName: string;
  countriesNames?: string[];
  organisationInfluencedCountriesNames?: string[];
  championedBy?: string;
  firmName?: string;
  morningstarRating?: 1 | 2 | 3 | 4 | 5;
  tickerSymbol?: string;
  typeOfFund?: string;
  allocation?: number;
}
