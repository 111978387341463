import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import StorageHelper from 'src/app/_core/helpers/Storage.helper';
import { ChampionTestimonials } from 'src/app/_core/models/champion/Champions';

@Component({
  selector: 'app-champion-card',
  templateUrl: './champion-card.component.html',
  styleUrls: ['./champion-card.component.scss'],
})
export class ChampionCardComponent implements OnInit, OnDestroy {
  @Input() champion: ChampionTestimonials;
  @Output() decline: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() viewForm: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() approve: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() viewLive: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() archive: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() delete: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() restore: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() edit: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();
  @Output() resubmit: EventEmitter<ChampionTestimonials> = new EventEmitter<ChampionTestimonials>();

  unsubscribes: Subscription[] = [];
  dateFormat: string;

  constructor() {}

  ngOnInit(): void {
    this.dateFormat = StorageHelper.getDateFormat();
  }

  ngOnDestroy(): void {
    this.unsubscribes.forEach((sub) => sub.unsubscribe());
  }

  onViewForm(): void {
    this.viewForm.emit(this.champion);
  }

  onDecline(): void {
    this.decline.emit(this.champion);
  }

  onApprove(): void {
    this.approve.emit(this.champion);
  }

  onViewLive(): void {
    this.viewLive.emit(this.champion);
  }

  onArchive(): void {
    this.archive.emit(this.champion);
  }

  onDelete(): void {
    this.delete.emit(this.champion);
  }

  onRestore(): void {
    this.restore.emit(this.champion);
  }

  onEdit(): void {
    this.edit.emit(this.champion);
  }

  onResubmit(): void {
    this.resubmit.emit(this.champion);
  }
}
