import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singleWord',
})
export class SingleWordPipe implements PipeTransform {
  transform(value: string, camelCase?: boolean): string {
    let newValue: string = value;

    if (camelCase) {
      const separators = [' ', '-', '_', '/'];
      separators.forEach((char) => (newValue = this.camelCase(newValue, char)));
    } else {
      newValue = newValue.split(' ').join('');
    }

    return newValue;
  }

  camelCase(value: string, separator: string): string {
    let arr = value.split(separator);

    arr = arr.map((string, index) => {
      if (index > 0) {
        return string.charAt(0).toUpperCase() + string.substring(1);
      } else {
        return string.charAt(0).toLowerCase() + string.substring(1);
      }
    });
    return arr.join('');
  }
}
