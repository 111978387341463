import { Component, Input, OnInit } from '@angular/core';
import { Modals } from '../../../_core/constants/Modals';
import { ModalsService } from '../../../_core/services/modals.service';

@Component({
  selector: 'app-read-more-button',
  templateUrl: './read-more-button.component.html',
  styleUrls: ['./read-more-button.component.scss'],
})
export class ReadMoreButtonComponent implements OnInit {
  @Input() field: string;
  @Input() header: string;
  @Input() length: number;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {}

  openModal(): void {
    this.modalsService.openModal(Modals.STORY, { story: this.field, header: this.header });
  }
}
