import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MODAL_ACTIONS, Modals, MODALS_STYLES, MODAL_RESPONSE } from 'src/app/_core/constants/Modals';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, OutputFormat } from 'ngx-image-cropper';
import { ToastrMessages } from '../../../_core/constants/ToastrMessages';
import Utils from '../../../_core/helpers/Utils';
import { FileType } from 'src/app/_core/constants/FileType';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Control } from '../../../_core/models/FormControls';
import { BannerInputs } from '../../../_core/models/UploadResponse';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/_core/services/toast.service';

@Component({
  selector: 'app-crop-modal',
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss'],
})
export class CropModalComponent implements OnInit, OnDestroy {
  @ViewChild('cropper') cropper: ImageCropperComponent;
  @Input() bannerInputParams: BannerInputs;
  @Output() sendImageInfo: EventEmitter<any> = new EventEmitter<any>();

  uploader: FileUploader;
  modalStyle;
  hasBaseDropZoneOver: boolean;
  editingFile: boolean;
  scale = 1;
  transform: ImageTransform = {};
  format: OutputFormat;
  control: Control;
  mediaControl: any;
  fileType: FileType;
  target: string;
  image: any = '';
  aspectRatio: number;
  maxWidth: number;
  croppedImages = []; // TODO make this string instead of string[]
  isMobile = false;
  fileIndex: number;
  breakpointSubscription: Subscription;
  fileEvent: any;
  cropAction = MODAL_ACTIONS.CROP;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private uploadService: UploadService,
    private dialogRef: MatDialogRef<any>,
    private toastService: ToastService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 500px)']).subscribe((result) => {
      result.matches
        ? this.isMobile = true
        : this.isMobile = false;
    });
  }

  ngOnInit(): void {
    this.uploader = this.uploadService.uploaderInstance;
    if (this.bannerInputParams) {
      this.aspectRatio = this.bannerInputParams.aspectRatio;
      this.fileType = this.bannerInputParams.fileType;
      this.target = this.bannerInputParams.target; // to check if needed
    } else {
      this.aspectRatio = this.modalsService.params.aspectRatio;
      this.modalStyle = MODALS_STYLES.find((modal) => modal.name === Modals.CROP_IMAGE).style;
      this.fileType = this.modalsService.params.fileType;
      this.mediaControl = this.modalsService.params.mediaControl;
      this.control = this.modalsService.params.control;
      this.fileIndex = this.modalsService.params.idx;
      this.target = this.modalsService.params?.target;
      this.fileEvent = this.modalsService.params?.fileEvent;
      this.maxWidth = this.modalsService.params?.maxWidth || 1920;
      this.format = this.modalsService.params?.format || 'jpeg';
      if (this.fileEvent) {
        this.fileChangeEvent(this.modalsService.params?.fileEvent);
      }
    }
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  fileChangeEvent(event: any): void {
    if (event) {
      if (!Utils.checkFileType(event[0].name, event[0].type, FileType.IMG)) {
        this.toastService.error(ToastrMessages.INVALID_FORMAT);
        this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length - 1]);
        return;
      }
      this.image = this.uploader.queue[this.uploader.queue.length - 1].file.rawFile;
      this.editingFile = true;
      if (this.isMobile) {
        this.dialogRef.updateSize(this.modalStyle.width, '570px');
      }
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImages.length ? (this.croppedImages[0] = event.base64) : this.croppedImages.push(event.base64);
  }

  uploadImageBanner() {
    this.sendImageInfo.emit(this.emitEvent(this.cropAction));
  }

  loadImageFailed(): void {
    this.toastService.error(ToastrMessages.LOAD_IMG_IN_CROPPER_ERROR);
  }

  changeFileOver(event: any): void {
    this.hasBaseDropZoneOver = event;
  }

  zoomOut() {
    this.scale -= 0.1;
    if (this.scale < 0.1) {
      this.scale = 0.1;
      return;
    }
    this.transform = { ...this.transform, scale: this.scale };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  close(): void {
    if (this.uploader.queue.length) {
      this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length - 1]);
    }

    if (this.bannerInputParams) {
      this.editingFile = false;
    } else {
      if (this.fileEvent) {
        this.emitEvent(MODAL_ACTIONS.CLOSE);
      }
      this.modalsService.closeModal();
    }
  }

  emitEvent(actionType: MODAL_ACTIONS): void {
    this.modalsService.emitResponse({
      confirmed: true,
      action: actionType,
      payload: MODAL_ACTIONS.CROP ? this.croppedImages : null,
      mediaControl: this.mediaControl,
      control: this.control,
      fileType: this.fileType,
      target: this.target,
      idx: this.fileIndex,
      closeStatus: MODAL_RESPONSE.CLOSE_SUCCESS,
    });
  }
}
