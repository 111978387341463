import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { PortfolioService } from '../api/portfolio.service';
import { ToastrMessages } from '../constants/ToastrMessages';
import { BaseFilters } from '../models/filters/Filters';
import { PortfolioDetails } from '../models/portfolio/Portfolio';
import { ToastService } from './toast.service';
import { ServerError } from '../constants/ServerError';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PortfolioDataService {
  private _portfolioDetails: PortfolioDetails;

  constructor(private portfolioService: PortfolioService, private toastService: ToastService, private sanitizer: DomSanitizer) {}

  getPortfolioDetails(uuid: string): Observable<any> {
    if (this._portfolioDetails && this._portfolioDetails.uuid === uuid) {
      return of(this._portfolioDetails);
    }

    return this.portfolioService.getDetails(uuid).pipe(
      map((res) => {
        this._portfolioDetails = new PortfolioDetails(res.response, this.sanitizer);
        return this._portfolioDetails;
      }),
      catchError((error) => {
        switch (error) {
          case ServerError.PORTFOLIO_NOT_FOUND:
            return of({ notFound: true });
          default:
            this.toastService.error(ToastrMessages.BASIC_ERROR);
            return of(null);
        }
      })
    );
  }

  getCarouselEntities(projectFilters: BaseFilters, orgFilters: BaseFilters, championFilters: BaseFilters): Observable<any> {
    return this.portfolioService
      .getEntities(this._portfolioDetails, this.computeFilters(projectFilters), this.computeFilters(orgFilters), this.computeFilters(championFilters))
      .pipe(
        tap((res) => {
          this._portfolioDetails.setCarouselProjects(res[0].response.projects);
          this._portfolioDetails.setCarouselOrganisations(res[1].response.organisations);
          this._portfolioDetails.setChampions(res[2].response.champions);
          this._portfolioDetails.carouselEntitiesLoaded = true;
        }),
        catchError(() => {
          this.toastService.error(ToastrMessages.BASIC_ERROR);
          return of(null);
        })
      );
  }

  getCarouselProjects(filters: BaseFilters): Observable<any> {
    return this.portfolioService.getCarouselProjects(this._portfolioDetails.uuid, this.computeFilters(filters)).pipe(
      tap((res) => this._portfolioDetails.setCarouselProjects(res.response.projects)),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        return of(null);
      })
    );
  }

  getCarouselOrganisations(filters: BaseFilters): Observable<any> {
    return this.portfolioService.getCarouselOrganisations(this._portfolioDetails.uuid, this.computeFilters(filters)).pipe(
      tap((res) => this._portfolioDetails.setCarouselOrganisations(res.response.organisations)),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        return of(null);
      })
    );
  }

  getCatalogProjects(filters: any): Observable<any> {
    return this.portfolioService.getProjects(this._portfolioDetails.uuid, filters).pipe(
      map((res) => res),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        return of(null);
      })
    );
  }

  getCatalogOrganisations(filters: BaseFilters): Observable<any> {
    return this.portfolioService.getOrganisations(this._portfolioDetails.uuid, filters).pipe(
      map((res) => res),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        return of(null);
      })
    );
  }

  getChampions(filters: BaseFilters): Observable<any> {
    return this.portfolioService.getChampions(this._portfolioDetails.uuid, this.computeFilters(filters)).pipe(
      map((res) => {
        return res.response.champions;
      }),
      catchError(() => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
        return of(null);
      })
    );
  }

  computeFilters(filters: BaseFilters) {
    return {
      order: filters.sortOptions[0].order,
      page: filters.page,
      size: filters.size,
      sortBy: filters.sortOptions[0].field,
    };
  }

  resetPortfolio(): void {
    this._portfolioDetails = null;
  }
}
