import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { Modals, MODALS_STYLES, MODAL_RESPONSE } from '../../../_core/constants/Modals';
import { ModalsService } from '../../../_core/services/modals.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediaComponent } from '../media/media.component';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { StoryModalComponent } from 'src/app/_shared/_modals/story-modal/story-modal.component';
import { CropModalComponent } from '../crop-modal/crop-modal.component';
import { CancelChangesComponent } from '../cancel-changes/cancel-changes.component';
import { LegalComponent } from '../../components/legal/legal.component';
import { DeclineEntityComponent } from 'src/app/internal-app/settings/admin/_helpers/decline-entity/decline-entity.component';
import { ShareButtonsModalComponent } from '../share-buttons-modal/share-buttons-modal.component';
import { IdleEntityModalComponent } from '../idle-entity/idle-entity-modal.component';

@Component({
  selector: 'app-modal-container',
  template: ``,
})
export class ModalContainerComponent implements OnDestroy {
  modalRef: MatDialogRef<any>;
  modalSubscription: Subscription;

  constructor(private dialog: MatDialog, private modalService: ModalsService) {
    this.modalSubscription = modalService.modal$.subscribe((response: any) => {
      switch (response.closeStatus) {
        case MODAL_RESPONSE.CLOSE:
        case MODAL_RESPONSE.CLOSE_SUCCESS:
          this.closeModal();
          response.source && this.emitCloseEvent(response);
          return;
        case MODAL_RESPONSE.CLOSE_ALL:
          this.dialog.closeAll();
          return;
        default:
          this.setModal(response.modalName, response.component);
      }
      this.modalRef.backdropClick().subscribe(() => {
        this.emitCloseEvent({ closeStatus: MODAL_RESPONSE.CLOSE, source: response.action });
      });
    });
  }

  emitCloseEvent(event): void {
    this.modalRef.afterClosed().subscribe(() => {
      this.modalService.emitResponse(event);
    });
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }

  setModal(response: any, component?): void {
    const style = MODALS_STYLES.find((modal) => modal.name === response).style;
    switch (response) {
      case Modals.COMPLETE_REGISTRATION:
        this.openModal(component, style, true);
        break;
      case Modals.MEDIA:
        this.openModal(MediaComponent, style, false, true);
        break;
      case Modals.PROJECT_TYPE_MEDIA:
        this.openModal(component, style, false, true);
        break;
      case Modals.DELETE_CONFIRMATION:
        this.openModal(DeleteConfirmationComponent, style, false, true);
        break;
      case Modals.STORY:
        this.openModal(StoryModalComponent, style, false, true);
        break;
      case Modals.EDIT_USER:
        this.openModal(component, style);
        break;
      case Modals.CROP_IMAGE:
        this.openModal(CropModalComponent, style);
        break;
      case Modals.INFO_MESSAGE:
        this.openModal(component, style);
        break;
      case Modals.ADD_NEW:
        this.openModal(component, style, true);
        break;
      case Modals.ADD_NEW_FUND:
        this.openModal(component, style, true);
        break;
      case Modals.CANCEL_CHANGES:
        this.openModal(CancelChangesComponent, style, false, true);
        break;
      case Modals.CONTACT_INVEST:
        this.openModal(component, style);
        break;
      case Modals.HOW_CAN_WE_HELP:
        this.openModal(component, style);
        break;
      case Modals.LEGAL:
        this.openModal(LegalComponent, style, false, true);
        break;
      case Modals.MEET_THE_INVESTORS_RESTRICTED:
        this.openModal(component, style);
        break;
      case Modals.INVITE_USERS:
        this.openModal(component, style);
        break;
      case Modals.POST_INVITE_USERS:
        this.openModal(component, style);
        break;
      case Modals.POST_COMPLETE_REGISTRATION:
        this.openModal(component, style);
        break;
      case Modals.MAKE_PROJECT_LIVE:
        this.openModal(component, style);
        break;
      case Modals.COMMIT_TO_GIVE:
        this.openModal(component, style);
        break;
      case Modals.SUBMIT_PROJECT_ORGANIZATION:
        this.openModal(component, style);
        break;
      case Modals.ADD_GROUP_MEMBER:
        this.openModal(component, style);
        break;
      case Modals.POST_ADD_GROUP_MEMBER:
        this.openModal(component, style);
        break;
      case Modals.ADD_GROUP_PROJECT:
        this.openModal(component, style);
        break;
      case Modals.SELECT_LANGUAGE:
        this.openModal(component, style);
        break;
      case Modals.SEARCH_LANGUAGE:
        this.openModal(component, style);
        break;
      case Modals.ADD_GROUP_PORTFOLIO:
        this.openModal(component, style);
        break;
      case Modals.ORGANISATION_SETTINGS:
        this.openModal(component, style);
        break;
      case Modals.REPORT_ABUSE:
        this.openModal(component, style);
        break;
      case Modals.DECLINE_REASON:
        this.openModal(DeclineEntityComponent, style);
        break;
      case Modals.SHARE_BUTTON:
        this.openModal(ShareButtonsModalComponent, style, false);
        break;
      case Modals.INFO_MODAL:
        this.openModal(IdleEntityModalComponent, style, true);
        break;
      case Modals.URL_REDIRECT:
        this.openModal(component, style);
        break;
      case Modals.DECLINE_MODAL:
        this.openModal(component, style);
        break;
      default:
        break;
    }
  }

  openModal(modalComponent, style, disableClose: boolean = false, cancel: boolean = false): void {
    if (this.dialog.openDialogs.length && !cancel) {
      this.dialog.closeAll();
    }
    this.modalRef = this.dialog.open(modalComponent, { ...style, disableClose });
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
