import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Urls from 'src/app/_core/constants/Urls';
import Utils from 'src/app/_core/helpers/Utils';
import { GlobalConfigService } from 'src/app/_core/services/global-config.service';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-idle-entity-modal',
  templateUrl: './idle-entity-modal.component.html',
  styleUrls: ['./idle-entity-modal.component.scss'],
})
export class IdleEntityModalComponent {
  contactUsLink: string;

  constructor(private globalConfigService: GlobalConfigService, private modalsService: ModalsService, private router: Router) {
    this.contactUsLink = this.globalConfigService.globalConfig.contactLink;
  }

  close(): void {
    this.modalsService.closeModalSuccess();
    this.router.navigate([`${Urls.APP}/${Urls.HOMEPAGE}`]);
  }

  contactUs(): void {
    Utils.navigate(this.contactUsLink);
  }
}
