import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { PortfolioDetails } from '../models/portfolio/Portfolio';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  private readonly resourceUrl: string = '/v1/portfolio';

  constructor(private apiService: ApiService) {}

  getCarouselProjects(uuid: string, filters: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/${uuid}/preview/carousel/projects`, filters);
  }

  getCarouselOrganisations(uuid: string, filters: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/${uuid}/preview/carousel/organisations`, filters);
  }

  getChampions(uuid: string, filters: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/${uuid}/preview/champions`, filters);
  }

  getDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}/preview`);
  }

  getEntities(portfolio: PortfolioDetails, projectFilters: any, orgFilters: any, championsFilters: any): Observable<any> {
    const projects = portfolio.numberOfProjects ? this.getCarouselProjects(portfolio.uuid, projectFilters) : of({ response: { projects: [] } });
    const organisations = portfolio.numberOfOrganisations
      ? this.getCarouselOrganisations(portfolio.uuid, orgFilters)
      : of({ response: { organisations: [] } });
    const champions = this.getChampions(portfolio.uuid, championsFilters);
    return forkJoin([projects, organisations, champions]);
  }

  getProjects(uuid: string, filters: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/${uuid}/preview/projects`, filters);
  }

  getOrganisations(uuid: string, filters: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/${uuid}/preview/organisations`, filters);
  }

  getSelectOptions(languageCode: string) {
    return this.apiService.get(`${this.resourceUrl}/dropdowns`, { languageCode });
  }

  createUpdatePortfolio(body) {
    return this.apiService.post(`${this.resourceUrl}/create-update`, body);
  }

  getPortfolioByUuid(uuid) {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  checkName(name: string, uuid: string) {
    return this.apiService.post(`${this.resourceUrl}/check-name`, { name, uuid });
  }

  getPortfolios(body: any) {
    return this.apiService.post(`${this.resourceUrl}/admin`, body);
  }

  togglePortfolioVisibility(body) {
    return this.apiService.post(`${this.resourceUrl}/toggle-visible`, body);
  }

  deletePortfolio(uuid: string) {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}/delete`);
  }
}
