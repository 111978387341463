import { KeyValue } from '../models/GenericObject';

export enum DigitGroupingKeys {
  DIGIT_GROUPING_0 = 'DIGIT_GROUPING_0',
  DIGIT_GROUPING_1 = 'DIGIT_GROUPING_1',
  DIGIT_GROUPING_2 = 'DIGIT_GROUPING_2',
  DIGIT_GROUPING_3 = 'DIGIT_GROUPING_3',
}

export enum NumberFormatKeys {
  DOT = 'DOT',
  COMMA = 'COMMA',
  SPACE = 'SPACE',
  APOSTROPHE = 'APOSTROPHE',
}

export const NumberFormats: KeyValue<string>[] = [
  {
    key: NumberFormatKeys.DOT,
    value: '.',
  },
  {
    key: NumberFormatKeys.COMMA,
    value: ',',
  },
  {
    key: NumberFormatKeys.SPACE,
    value: ' ',
  },
  {
    key: NumberFormatKeys.APOSTROPHE,
    value: "'",
  },
];

export const DigitGrouping: KeyValue<string>[] = [
  {
    key: DigitGroupingKeys.DIGIT_GROUPING_0,
    value: '123456789',
  },
  {
    key: DigitGroupingKeys.DIGIT_GROUPING_1,
    value: '123,456,789',
  },
  {
    key: DigitGroupingKeys.DIGIT_GROUPING_2,
    value: '123456,789',
  },
  {
    key: DigitGroupingKeys.DIGIT_GROUPING_3,
    value: '12,34,56,789',
  },
];
